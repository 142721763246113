export function getAge(dob) {
  const diff = Date.now() - new Date(dob).getTime();
  const ageDt = new Date(diff);

  return Math.abs(ageDt.getUTCFullYear() - 1970);
}

export function getPerson(household, ownerId) {
  return household.members.find((p) => p.QID === ownerId);
}
