import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Button, styled } from '@qwealth/qcore';
import {getHouseholdId, loadQScore} from '@qwealth/qdata';
import { VscWarning } from 'react-icons/all';
import anime from 'animejs';
import QLoader from './QLoader';
import QScoreResults from './QScoreResults';

const QScoreCard = styled(Card)`
   {
    width: 330px;
    margin: 0 auto;
    min-height: 320px;
    position: fixed;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 960px) {
      width: 100%;
      position: initial;
    }
  }
`;

const Title = styled.h5`
   {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;

const initiateAnimation = () => {
  const animateScore = anime.timeline({
    targets: '.score-value',
    duration: 2500,
    easing: 'spring',
    direction: 'normal',
    keyframes: [
      { visibility: 'visible' },
      { opacity: 0 },
      { scale: 2.5, translateY: 15, opacity: 1 },
      { scale: 1, translateY: 0 }
    ]
  });

  animateScore.add(
    {
      targets: ['.score-description', '.score-meter'],
      duration: 2000,
      easing: 'easeInCubic',
      keyframes: [{ visibility: 'visible' }, { opacity: 0 }, { opacity: 1 }]
    },
    '+=500'
  );
};

const showQScore = () =>
  anime({
    targets: ['.score-value', '.score-description', '.score-meter'],
    opacity: '1',
    duration: 1000,
    easing: 'easeInOutQuad'
  });

export default function QScore() {
  const householdId = getHouseholdId();
  const qscore = useSelector((state) => state.qscore);
  const { isLoading } = qscore;
  const { push } = useHistory();

  const dispatch = useDispatch();
  const qScoreLoadHandler = () => {
    dispatch(loadQScore(householdId, qscore.simulationIds, qscore.value > -1)).then(initiateAnimation);
  };

  useEffect(() => {
    if (qscore.value > -1) {
      showQScore();
    }
  }, [qscore.value]);

  const warningMessages = qscore.simulatedCOAList
    .map((COA) => COA.warningMessages)
    .reduce((a, b) => a.concat(b), []);

  return (
    <QScoreCard shadow>
      <Title>QScore</Title>
      <Box display="flex" flex="1" justifyContent="center" alignItems="center">
        {(qscore.value === -1 || isLoading) && <QLoader loading={isLoading} />}
        {qscore.value > -1 && !isLoading && <QScoreResults value={qscore.value} />}
      </Box>
      <Button mb="default" onClick={qScoreLoadHandler}>
        {qscore.value === -1 ? 'Let me see my QScore!' : 'Recalculate my QScore!'}
      </Button>
      {qscore.value > -1 && (
        <Button
          variant="outline"
          mb="default"
          onClick={() => {
            push({
              pathname: '/masterSimReport',
              search: `?household=${householdId}`
            });
          }}
        >
          QRoute Report
        </Button>
      )}
      {qscore.value > -1 && (
        <Button
          variant="outline"
          mb="default"
          onClick={() => {
            push({
              pathname: '/distributionReport',
              search: `?household=${householdId}`
            });
          }}
        >
          Distribution Report
        </Button>
      )}
      {qscore.value > -1 && (
        <Button
          variant="outline"
          mb="default"
          onClick={() => {
            push({
              pathname: '/projectionReport',
              search: `?household=${householdId}`
            });
          }}
        >
          Projection Report
        </Button>
      )}
      {warningMessages.map((warningMessage) => (
        <Box>
          <VscWarning />
          {warningMessage}
        </Box>
      ))}
    </QScoreCard>
  );
}
