import { DELETE_OVERRIDE, PUT_OVERRIDE } from '../actions/overrides';

export default function (state = [], action) {
  switch (action.type) {
    case PUT_OVERRIDE: {
      const { override } = action;
      const { uuid } = override;
      const target = state.find((a) => a.uuid === uuid);
      return target
        ? state.map((a) => (a.uuid === uuid ? { ...a, ...override } : a))
        : [...state, override];
    }
    case DELETE_OVERRIDE: {
      return state.filter((o) => o.uuid !== action.uuid);
    }
    default:
      return state;
  }
}
