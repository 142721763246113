import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  MainText2: {
    fontSize: 12,
    color: COLORS.PRIMARY
  }
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

const ReportClientQRoutes = ({ data }: { data: AggregatedQScoreData }) => {
  const chartQRouteImg = useChartJsAsImage(
    crateChartJsConfig('bar', data.chartQRoute.labels, data.chartQRoute.datasets)
  );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>SELECTED CLIENT QROUTES</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30,
          paddingTop: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          A Client QRoute is an alternative pathway that you may take on your{' '}
          <Text style={styles.BoldFont}>‘Journey’</Text>. It is a{' '}
          <Text style={styles.BoldFont}>“What If”</Text> scenario, chosen by{' '}
          <Text style={styles.BoldFont}>YOU</Text>, that you wish to evaluate. This report allows
          you to evaluate the impact of either a single decision or multiple decisions collectively.
          By way of example,
        </Text>
        <Text style={styles.MainText2}>
          “I would like to be retired at 65, but it would be really nice to see if I could be
          retired at 60.” <Text style={styles.BoldFont}>or</Text>
        </Text>
        <Text style={[styles.MainText2, { marginBottom: 20 }]}>
          “I would like to give $20,000 to each of my children to help them buy their first home,
          but not if it means I can’t travel in retirement.”
        </Text>
        {chartQRouteImg}
      </View>
    </Page>
  );
};

export default ReportClientQRoutes;
