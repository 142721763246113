import { applyMiddleware, createStore, compose, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import rootReducer from './reducers';
import {
  Account,
  IDataPointWorkshopDto,
  IGoalWorkshopDto,
  IPerson,
  ISimulationWorkshopDto
} from '@qwealth/qdata';

export interface IState {
  accounts: Array<Account>;
  workshop: {
    id: string | null;
    dataPointList: Array<IDataPointWorkshopDto>;
    goalList: Array<IGoalWorkshopDto>;
    simulationList: Array<ISimulationWorkshopDto>;
  };
  household: {
    initialized: boolean;
    members: Array<IPerson>;
  };
}

export type StateAction<ReturnType = void> = ThunkAction<
  ReturnType,
  IState,
  unknown,
  Action<string>
>;

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

// @ts-ignore
window.store = store; // TODO: for debugging. remove in production

export default store;
