import axios from 'axios';
import {
  errorHandler,
  getHouseholdQID,
  REACT_APP_QWEALTH_QC_API
} from '../../services/axiosService';

export const PUT_OVERRIDE = 'PUT_OVERRIDE';
export const DELETE_OVERRIDE = 'DELETE_OVERRIDE';

const putOverride = (override) => ({ type: PUT_OVERRIDE, override });
const removeOverride = (uuid) => ({ type: DELETE_OVERRIDE, uuid });

export const loadOverrides = () => {
  return (dispatch) => {
    const householdId = getHouseholdQID();
    return axios
      .get(`${REACT_APP_QWEALTH_QC_API}/qscore/household/${householdId}/overrides`)
      .then((response) => response.data)
      .then((overrides) => overrides.forEach((o) => dispatch(putOverride(o))))
      .catch(errorHandler(dispatch));
  };
};

export const saveOverride = (override) => {
  return (dispatch) => {
    return axios
      .put(
        `${REACT_APP_QWEALTH_QC_API}/qscore/household/${override.householdId}/overrides`,
        override
      )
      .then((response) => response.data)
      .then((o) => dispatch(putOverride(o)))
      .catch(errorHandler(dispatch));
  };
};

export const deleteOverride = (uuid) => {
  return (dispatch) => {
    const householdId = getHouseholdQID();
    return axios
      .delete(`${REACT_APP_QWEALTH_QC_API}/qscore/household/${householdId}/overrides/${uuid}`)
      .then(() => dispatch(removeOverride(uuid)))
      .catch(errorHandler(dispatch));
  };
};
