import axios from 'axios';
import { errorHandler, REACT_APP_QWEALTH_QC_API } from '../../services/axiosService';

export const getOptimalGuaranteedIncomeAge = (householdId, recalculateFlag) => {
  return (dispatch) => {
    const URL = new window.URL(
      `${REACT_APP_QWEALTH_QC_API}/qroute/household/${householdId}/optimalGuaranteedIncome`
    );
    if (recalculateFlag) {
      URL.searchParams.append('recalculate', recalculateFlag);
    }
    return axios
      .get(URL.toString())
      .then((response) => response.data)
      .catch(errorHandler(dispatch));
  };
};

export const getMaximumAnnualWithdrawal = (householdId, recalculateFlag) => {
  return (dispatch) => {
    const URL = new window.URL(
      `${REACT_APP_QWEALTH_QC_API}/qroute/household/${householdId}/maximumAnnualWithdrawal`
    );
    if (recalculateFlag) {
      URL.searchParams.append('recalculate', recalculateFlag);
    }
    return axios
      .get(URL.toString())
      .then((response) => response.data)
      .catch(errorHandler(dispatch));
  };
};
