import React from 'react';
import { Box } from '@qwealth/qcore';

export default function InputValue(props) {
  return (
    <Box as="div" fontSize="22px" fontWeight="700">
      {props.children}
    </Box>
  );
}
