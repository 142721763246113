export const hasColumnData = (field, rows) => rows.some((row) => row[field] > 0);

export const typeComparator = (a, b) => {
  if (a.type > b.type) {
    return 1;
  }
  if (b.type > a.type) {
    return -1;
  }
  if (a.ownerId > b.ownerId) {
    return 1;
  }
  if (b.ownerId > a.ownerId) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  if (b.id > a.id) {
    return -1;
  }
  return 0;
};

export const diffComparator = (diff) => (a, b) => {
  if (diff.includes(a.id) && !diff.includes(b.id)) {
    return -1;
  }
  if (!diff.includes(a.id) && diff.includes(b.id)) {
    return 1;
  }
  return 0;
};

export const getScoreTitle = (value) => {
  let title = '';
  if (value >= 75 && value <= 90) {
    title = 'Perfect';
  }

  if (value < 75) {
    title = 'Danger';
  }

  if (value > 90) {
    title = 'Warning';
  }

  return title;
};

export const getScoreMeaning = (value) => {
  let scoreMeaning = '';
  if (value >= 75 && value <= 90) {
    scoreMeaning =
      '"Perfect Score!" You are extremely likely to meet your retirement goals while maintaining a great lifestyle!';
  }

  if (value < 75) {
    scoreMeaning =
      '"Below our Comfort Level!" Almost 1 out of every 3 times we ran the plan, your goals were not funded.  Let’s look at changing some goals and assumptions.';
  }

  if (value > 90) {
    scoreMeaning =
      '"Above our Comfort Level!"  Yes, you read that right!  This means you will actually leave behind MORE money than you planned and means you are not allowing yourself to live life to the fullest.  Let’s explore bigger goals for you!';
  }

  return scoreMeaning;
};
