import React, { useState } from 'react';
import { Box, Card, Tab, Tabs } from '@qwealth/qcore';
import WithdrawOverride from './WithdrawOverride';
import AllocationOverride from './AllocationOverride';
import DepositOverride from './DepositOverride';

export default function Overrides() {
  const [selected, setSelected] = useState('surplus');
  return (
    <Box
      display="grid"
      rowGap="default"
      gridTemplateColumns={{ phone: '1fr', desktop: '1fr 1fr' }}
      columnGap="default"
    >
      <Box gridAutoRows="minmax(0, max-content) max-content">
        <AllocationOverride />
      </Box>
      <Box gridAutoRows="minmax(0, max-content) max-content">
        <Card title="Deposit/Withdraw Override">
          <p>
            Annual&nbsp;
            <b>surplus / deficit</b>
            &nbsp;is calculated by:
            <br />
            <code>
              Combined Income* - Annual Retirement/Current Spending&dagger; - Combined RMD*
            </code>
          </p>
          <Tabs selectedTab={selected} onSelect={(tab) => setSelected(tab)}>
            <Tab id="surplus">Deposit Override (Surplus)</Tab>
            <Tab id="deficit">Withdrawal Override (Deficit)</Tab>
          </Tabs>
          <Box mt="large">
            {selected === 'surplus' && <DepositOverride />}
            {selected === 'deficit' && <WithdrawOverride />}
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
