import React from 'react';
import * as PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import './EditableTable.scss';
import { AddButton, Box, Button } from '@qwealth/qcore';
import { BsTrash } from 'react-icons/bs';

export default function EditableTable(props) {
  const { addText, addHandler, cellEdit, columns, data, deleteHandler, keyField } = props;
  const deleteColumn = {
    dataField: 'delete',
    text: '',
    events: {
      onClick: (e, column, columnIndex, row) => {
        e.preventDefault();
        deleteHandler(row);
      }
    },
    formatter: () => (
      <Button color="gray.1" variant="icon" size="small" ml="small" onClick={() => {}}>
        <BsTrash />
      </Button>
    )
  };
  return (
    <div className="editable-table">
      <BootstrapTable
        bootstrap4
        keyField={keyField}
        cellEdit={cellEdit}
        data={Array.isArray(data) ? data : []}
        columns={columns.concat([deleteColumn])}
      />
      {addText && (
        <Box mt="large">
          <AddButton onClick={addHandler}>{addText}</AddButton>
        </Box>
      )}
    </div>
  );
}

EditableTable.propTypes = {
  addHandler: PropTypes.func,
  addText: PropTypes.string,
  cellEdit: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteHandler: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keyField: PropTypes.string.isRequired
};

EditableTable.defaultProps = {
  addHandler: () => {},
  addText: ''
};
