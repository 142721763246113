import axios from 'axios';
import {
  getPerson,
  IAddressDto,
  IIncomeDto,
  IHouseholdMemberDto,
  IPerson,
  IPersonDto
} from '@qwealth/qdata';
import { putNotification } from './notifications';
import { errorHandler, getHouseholdQID, REACT_APP_QWEALTH_API } from '../../services/axiosService';
import { StateAction } from '../store';
import { responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import { isLegalEntity, loadLegalEntity } from './legalEntity';

export const PUT_HOUSEHOLD_MEMBER = 'PUT_HOUSEHOLD_MEMBER';
export const HOUSEHOLD_INITIALIZED = 'HOUSEHOLD_INITIALIZED';

const putMember = (person: IPerson) => ({ type: PUT_HOUSEHOLD_MEMBER, person });
const setInitialized = (householdId: string) => ({ type: HOUSEHOLD_INITIALIZED, householdId });

const loadPerson = (memberDto: IHouseholdMemberDto): StateAction => (dispatch) => {
  const promises = Promise.all([
    axios
      .get(`${REACT_APP_QWEALTH_API}/persons/${memberDto.memberQID}`)
      .then((resp) => responseEntityMapper<IPersonDto>(resp)),
    axios
      .get(`${REACT_APP_QWEALTH_API}/persons/${memberDto.memberQID}/addresses`)
      .then((resp) => responseEntityMapper<IAddressDto>(resp, false)),
    axios
      .get(`${REACT_APP_QWEALTH_API}/persons/${memberDto.memberQID}/income`)
      .then((resp) => responseEntityMapper<IIncomeDto>(resp, false))
  ]);

  return promises
    .then(([personDto, addressDto, incomeDto]) => getPerson(personDto, addressDto, incomeDto))
    .then((person) => dispatch(putMember(person)))
    .catch(errorHandler(dispatch));
};

export const loadHousehold = (): StateAction => {
  const householdId = getHouseholdQID();
  if (!householdId) {
    return (dispatch) => dispatch(putNotification('Missing household param in url', 'Error'));
  }
  return (dispatch) => {
    return axios
      .get(`${REACT_APP_QWEALTH_API}/households/${householdId}/members`)
      .then((resp) => responseListMapper<IHouseholdMemberDto>(resp))
      .then((members) =>
        Promise.all(
          members.map((member: IHouseholdMemberDto) =>
            isLegalEntity(member) ? dispatch(loadLegalEntity(member)) : dispatch(loadPerson(member))
          )
        )
      )
      .then(() => dispatch(setInitialized(householdId)))
      .catch(errorHandler(dispatch));
  };
};
