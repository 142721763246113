import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  calculateGuaranteedRetirementIncome,
  calculateRetirementSpending,
  currentSpendingFilter,
  retirementAgeFilter,
  retirementIncomeFilter,
  retirementSpendingFilter,
  toCurrency,
  getAnnualSavings,
  legacyBalanceFilter
} from '@qwealth/qcore';

import { buildRetirementMap } from 'components/BaselinePlan';

import { getAge } from 'utils/HouseholdUtil';
import { annualFlowValue } from 'utils/FlowUtil';
import {
  Account,
  IGoalType,
  IGoalWorkshopDto,
  IPerson,
  dataPointListSelector,
  goalListSelector,
  initializeHouseholdAccounts,
  loadWorkshopData
} from '@qwealth/qdata';
import {
  selectHouseholdId,
  selectHouseholdMemberIds,
  selectPrimary,
  selectSecondary
} from 'data/selectors/householdSelectors';

// Types
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { IState } from 'data/store';
import type { AggregatedBaselinePlanData } from '../interfaces/AggregatedBaselinePlanData';

export const useAggregateBaselinePlanData = (): AggregatedBaselinePlanData | null => {
  const [ready, setReady] = useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatch<IState, any, AnyAction>>();
  const household = useSelector<IState, { initialized: boolean }>((state) => state.household);

  // COMMON
  // *******************

  const primary = useSelector<IState, IPerson>(selectPrimary);
  const primaryAge = getAge(primary.dateOfBirth);

  const secondary = useSelector<IState, IPerson>(selectSecondary);
  const secondaryAge = getAge(secondary.dateOfBirth);

  const goalList: Array<IGoalWorkshopDto> = useSelector(goalListSelector);
  const memberIds = useSelector<IState, string[]>(selectHouseholdMemberIds);
  const householdId = useSelector(selectHouseholdId);
  const dataPointList = useSelector(dataPointListSelector);
  const accounts = useSelector<IState, Account[]>((state) => state.accounts);

  const fieldNames = [primary.firstName || 'N/A', secondary.firstName || 'N/A'];

  useEffect(() => {
    if (!household.initialized) {
      return;
    }

    const waitAll = memberIds.reduce<Promise<any>[]>(
      // @ts-ignore
      (acc, id) => [...acc, dispatch(loadWorkshopData(id))],
      []
    );
    // @ts-ignore
    waitAll.push(dispatch(initializeHouseholdAccounts(householdId, false)));

    Promise.all(waitAll).then(() => setReady(true));
  }, [household.initialized]);

  // RETIREMENT SPENDING
  // *******************

  const findGuranteedIncomes = useCallback(
    (ownerId: string) =>
      dataPointList
        .filter(({ attendeeQID }) => attendeeQID === ownerId)
        .find(retirementIncomeFilter),
    [dataPointList]
  );

  const retirementSpendGoal = goalList.find(retirementSpendingFilter);
  const retirementSpendingValue = calculateRetirementSpending(
    retirementSpendGoal,
    dataPointList.find(currentSpendingFilter)
  );

  const retirementPrimaryGuaranteedIncomes = calculateGuaranteedRetirementIncome(
    findGuranteedIncomes(primary.QID)
  );

  const retirementSecondaryGuaranteedIncomes = calculateGuaranteedRetirementIncome(
    findGuranteedIncomes(secondary.QID)
  );

  const retirementAnnualWithdraw =
    retirementSpendingValue ||
    0 - retirementPrimaryGuaranteedIncomes - retirementSecondaryGuaranteedIncomes;

  // RETIREMENT SAVINGS
  // ******************

  const investmentAccounts = accounts.filter((a) => a.accountType === 'Investment');
  const jointAccounts = investmentAccounts.filter(({ ownershipType }) => ownershipType === 'Joint');
  const primaryAccounts = investmentAccounts.filter(
    ({ accountOwnersQID, ownershipType }) =>
      accountOwnersQID === primary?.QID && ownershipType === 'Sole'
  );
  const secondaryAccounts = investmentAccounts.filter(
    ({ accountOwnersQID }) => accountOwnersQID === secondary?.QID
  );

  // Retirement Inflows & Outflows
  // ******************

  const calcFlowTotalValueByType = useCallback(
    (type: IGoalType) => {
      const retirementGoals = goalList.filter(retirementAgeFilter);
      const retirementMap = buildRetirementMap(retirementGoals, primary, secondary);

      const ageMap = {
        [primary.QID]: getAge(primary.dateOfBirth),
        [secondary.QID]: getAge(secondary.dateOfBirth)
      };

      return goalList
        .filter(({ goalType }) => goalType === type)
        .map((flow) => annualFlowValue(flow, ageMap, retirementMap) as number)
        .reduce((a, b) => a + b, 0);
    },
    [goalList, primary, secondary]
  );

  const legacy = goalList.find(legacyBalanceFilter);

  if (!ready) return null;

  return {
    'Retirement Age': [
      {
        title: '',
        names: fieldNames,
        value: [primaryAge, secondaryAge]
      }
    ],
    'Retirement Spending': [
      {
        title: 'Targeted Annual Retirement Spend',
        names: fieldNames,
        value: [toCurrency(retirementSpendingValue, true)]
      },
      {
        title: 'Guaranteed Retirement Income',
        names: fieldNames,
        value: [
          toCurrency(retirementPrimaryGuaranteedIncomes, true),
          toCurrency(retirementSecondaryGuaranteedIncomes, true)
        ]
      },
      {
        title: 'Annual Withdrawal From Retirement Savings',
        names: fieldNames,
        value: [toCurrency(retirementAnnualWithdraw, true)]
      }
    ],
    'Retirement Savings': [
      {
        title: 'Retirement Savings',
        names: fieldNames,
        value: [
          toCurrency(
            primaryAccounts
              .map((acc) => getAnnualSavings(acc, primary, secondary))
              .reduce((a, b) => a + b, 0),
            true
          ),
          toCurrency(
            secondaryAccounts
              .map((acc) => getAnnualSavings(acc, secondary, primary))
              .reduce((a, b) => a + b, 0),
            true
          ),
          toCurrency(
            jointAccounts
              .map((acc) => getAnnualSavings(acc, primary, secondary))
              .reduce((a, b) => a + b, 0),
            true
          )
        ]
      }
    ],
    'Retirement Inflows': [
      {
        title: 'Retirement Inflows Total',
        names: fieldNames,
        value: [toCurrency(calcFlowTotalValueByType('Post-retirement: Inflow'), true)]
      }
    ],
    'Retirement Outflows': [
      {
        title: 'Retirement Outflows Total',
        names: fieldNames,
        value: [toCurrency(calcFlowTotalValueByType('Post-retirement: Outflow'), true)]
      }
    ],
    'Legacy Value': [
      {
        title: 'Legacy Ending Assets',
        names: [],
        value: [toCurrency(legacy?.legacyEndValue, true)]
      }
    ]
  };
};
