import React, { useState } from 'react';
import { Box, AccordionSection } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore';
import { IDataPointWorkshopDto, IGoalWorkshopDto, IPerson } from '@qwealth/qdata';
import {
  calculateGuaranteedRetirementIncome,
  calculateRetirementSpending,
  currentSpendingFilter,
  retirementIncomeFilter,
  retirementSpendingFilter
} from '@qwealth/qcore/build/es/utils/WorkshopUtil';
import EditButton from '../../Common/EditButton';
import GuaranteedRetirementIncome from './GuaranteedRetirementIncome';
import RetirementSpendingEditor from './Editor';
import InputLabel from '../../Common/InputLabel';
import InputValue from '../../Common/InputValue';
import BorderedSection from '../../Common/BorderedSection';

interface Props {
  primary: IPerson;
  secondary: IPerson;
  dataPointList: Array<IDataPointWorkshopDto>;
  goalList: Array<IGoalWorkshopDto>;
}

const RetirementSpending: React.FC<Props> = ({ primary, secondary, dataPointList, goalList }) => {
  const [show, setShow] = useState(false);

  const retirementSpendGoal = goalList.find(retirementSpendingFilter);
  const currentSpendingDataPoint = dataPointList.find(currentSpendingFilter);

  const retirementSpendingVal = calculateRetirementSpending(
    retirementSpendGoal,
    currentSpendingDataPoint
  );

  const findGuranteedIncomes = (ownerId: string) =>
    dataPointList.filter((d) => d.attendeeQID === ownerId).find(retirementIncomeFilter);

  const primaryGuaranteedIncomes = findGuranteedIncomes(primary.QID);
  const secondaryGuaranteedIncomes = findGuranteedIncomes(secondary.QID);

  const annualWithdraw =
    (retirementSpendingVal || 0) -
    calculateGuaranteedRetirementIncome(primaryGuaranteedIncomes) -
    calculateGuaranteedRetirementIncome(secondaryGuaranteedIncomes);

  return (
    <>
      <AccordionSection
        color="primary"
        backgroundColor="primaryTransparent"
        title="Retirement Spending"
      >
        <Box
          display="grid"
          gridTemplateColumns={{
            phone: '1fr'
          }}
          gap="default"
        >
          <BorderedSection>
            <InputLabel>Targeted Annual Retirement Spend</InputLabel>
            <Box display="flex" alignItems="center">
              <InputValue>{toCurrency(retirementSpendingVal, true)}</InputValue>
              <EditButton onClickHandler={() => setShow(true)} />
              <RetirementSpendingEditor show={show} setShow={setShow} goal={retirementSpendGoal} />
            </Box>
          </BorderedSection>
          <GuaranteedRetirementIncome
            primary={primary}
            secondary={secondary}
            primaryGuaranteedIncomes={primaryGuaranteedIncomes}
            secondaryGuaranteedIncomes={secondaryGuaranteedIncomes}
          />
          <BorderedSection>
            <InputLabel>Annual Withdrawal From Retirement Savings</InputLabel>
            <Box display="flex">
              <InputValue>{toCurrency(annualWithdraw, true)}</InputValue>
            </Box>
          </BorderedSection>
        </Box>
      </AccordionSection>
    </>
  );
};

export default RetirementSpending;
