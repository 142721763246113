import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Box, styled } from '@qwealth/qcore';
import { getAUMFees } from '@qwealth/qdata';
import { QAccount, showAssetAllocation } from './helper';

const Title = styled.h6`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #84878f;
`;

const Value = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 700;
`;

interface Props {
  account: QAccount | undefined;
}

const AssetAllocationDetails: React.FC<Props> = ({ account }) => {
  if (!account || !showAssetAllocation(account)) {
    return null;
  }
  const details =
    account.accountType === 'Investment'
      ? account.investmentAccountDetails
      : account.educationDisabilityAccountDetails;
  const { assetAllocation, growthRate, standardDeviation } = account;
  const effectiveAdvisorFeeRate = details?.effectiveAdvisorFeeRate || 1.07;
  const effectiveMERFeeRate = details?.effectiveMERFeeRate || 0.9;
  const feeSummary = `${effectiveAdvisorFeeRate} (advisory) <br /> + ${effectiveMERFeeRate} (MER)`;

  const feeString = getAUMFees(details);
  const fees = parseFloat(feeString);
  return (
    <Box
      color="gray.0"
      backgroundColor="gray.4"
      display="flex"
      justifyContent="space-around"
      borderRadius="default"
      m="large"
      p="large"
    >
      <Box textAlign="center">
        <Title>Asset Allocation</Title>
        <Value>{assetAllocation}</Value>
      </Box>
      <Box textAlign="center">
        <Title>Gross Expected Rate of Return</Title>
        <Value>{growthRate}</Value>
      </Box>
      <ReactTooltip multiline />
      <Box textAlign="center">
        <Title>Fees</Title>
        <Value data-tip={feeSummary}>{feeString}*</Value>
      </Box>
      <Box textAlign="center">
        <Title>Net Expected Rate of Return</Title>
        <Value>{(growthRate - fees).toFixed(2)}</Value>
      </Box>
      <Box textAlign="center">
        <Title>Standard Deviation</Title>
        <Value>{standardDeviation}</Value>
      </Box>
    </Box>
  );
};

export default AssetAllocationDetails;
