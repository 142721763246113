import { Account } from '@qwealth/qdata';

export type QAccount = Account & {
  growthRate: number;
  standardDeviation: number;
};

export const showAssetAllocation = (account: QAccount) => {
  if (account && account.assetAllocation) {
    const { accountType, investmentAccountDetails, educationDisabilityAccountDetails } = account;

    const hasInvestmentAllocation =
      accountType === 'Investment' &&
      investmentAccountDetails?.investmentAccountSubType !== 'Stock Option';

    const hasEducationAllocation =
      accountType === 'Education or Disability' &&
      educationDisabilityAccountDetails?.accountSubType === 'RESP';

    return hasInvestmentAllocation || hasEducationAllocation;
  }

  return false;
};
