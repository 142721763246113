import axios from 'axios';
import { errorHandler, REACT_APP_QWEALTH_QC_API } from '../../services/axiosService';

export const PUT_TIMELINE = 'PUT_TIMELINE';

export const loadTimeline = (householdId, types, startDate, endDate) => {
  const URL = new window.URL(
    `${REACT_APP_QWEALTH_QC_API}/qscore/household/${householdId}/timeline`
  );

  if (startDate) {
    URL.searchParams.append('startDate', startDate.toISOString());
  }
  if (endDate) {
    URL.searchParams.append('endDate', endDate.toISOString());
  }
  if (types) {
    URL.searchParams.set('types', types);
  }

  return (dispatch) => {
    axios
      .get(URL.toString())
      .then((response) => response.data)
      .then((timeline) => dispatch({ type: PUT_TIMELINE, timeline }))
      .catch(errorHandler(dispatch));
  };
};

export const getTimeline = async (householdId, types, startDate, endDate, dispatch) => {
  try {
    const URL = new window.URL(
      `${REACT_APP_QWEALTH_QC_API}/qscore/household/${householdId}/timeline`
    );

    if (startDate) {
      URL.searchParams.append('startDate', startDate.toISOString());
    }
    if (endDate) {
      URL.searchParams.append('endDate', endDate.toISOString());
    }
    if (types) {
      URL.searchParams.set('types', types);
    }

    return await axios.get(URL.toString()).then((response) => response.data);
  } catch (e) {
    errorHandler(dispatch);
    return undefined;
  }
};
