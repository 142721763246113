import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginBottom: 8
  },
  Bold: {
    fontWeight: 'bold'
  },
  Contents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30
  },
  Menus: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '60%'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    height: 35,
    letterSpacing: 1
  },
  BannerImage: {
    width: '40%',
    marginLeft: 32
  }
});

const ReportContent = () => {
  const contents = [
    { page: 1, title: 'WHAT IS A QSCORE?' },
    {
      page: 2,
      title: `WHAT IS MEANT BY\n“SEQUENCE OF RETURNS?”`
    },
    { page: 3, title: 'YOUR BASELINE PLAN ASSUMPTIONS' },
    { page: 4, title: 'YOUR ASSET ALLOCATION\nAND EXPECTED RATES OF RETURN' },
    { page: 5, title: 'YOUR QSCORE' },
    { page: 6, title: 'LEGACY VALUES' },
    { page: 7, title: 'PROJECTION REPORT' },
    { page: 8, title: 'CHART OF ACCOUNTS THROUGH TIME' },
    { page: 9, title: 'MASTER DISTRIBUTION REPORT' },
    { page: 10, title: 'SELECTED HISTORICAL QSCORES' },
    { page: 11, title: 'SELECTED CLIENT QROUTES' },
    { page: 12, title: 'SELECTED ADVISOR QROUTES' }
  ];

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <Image style={commonStyles.FooterLogo} src="/assets/q-logo.png" fixed />
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>TABLE OF CONTENTS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>
      <View style={styles.Contents}>
        <View style={styles.Menus}>
          <Text style={styles.Title}>
            Step Two: <Text style={styles.Bold}>Your QScore</Text>
          </Text>
          {contents.map(({ page, title }, index) => (
            <View style={styles.Row} key={index}>
              <Text
                style={{ textAlign: 'right', width: 32, color: COLORS.PRIMARY, fontWeight: 'bold' }}
              >
                {index + 1}
              </Text>
              <Text style={{ color: COLORS.GRAY1, paddingHorizontal: 8, fontWeight: 'bold' }}>
                /
              </Text>
              <Text style={{ fontSize: 14, fontWeight: 'semibold' }}>{title}</Text>
            </View>
          ))}
        </View>
        <Image src="/assets/qw-qscore-pdf-img2.jpeg" style={styles.BannerImage} />
      </View>
    </Page>
  );
};

export default ReportContent;
