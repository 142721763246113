import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { TableProps } from '@qwealth/pdf-support';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';

import type { QScoreLegacyValueData } from '../interfaces/AggregatedQScoreData';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';
import { hasColumnData } from '../../../../../utils/DataUtil';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Col: {
    flex: 1,
    fontSize: 8
  }
});

const createMasterDistributionCols = (
  primary: string,
  secondary: string,
  legacyValue: Array<QScoreLegacyValueData>
): TableProps<QScoreLegacyValueData>['cols'] => [
  { title: 'Year', val: ({ year }) => year },
  { title: `${primary}'s\nage`, val: ({ age }) => age },
  { title: `${secondary}'s\nage`, val: ({ secondaryAge }) => secondaryAge },
  {
    title: 'Total\nRetirement\nAssets',
    val: ({ endingBalance, endingInsuranceBalance }) =>
      toCurrency(endingBalance - endingInsuranceBalance || 0, true)
  },
  {
    title: 'Combined\nEarned\nIncome',
    val: ({ combinedNetEarnedIncome }) => toCurrency(combinedNetEarnedIncome, true)
  },
  {
    title: 'Total\nSpending†',
    val: ({ totalSpending }) => toCurrency(totalSpending, true)
  },
  {
    title: 'Guaranteed\nIncome',
    val: ({ netGuaranteedRetirementIncome }) => toCurrency(netGuaranteedRetirementIncome, true)
  },
  {
    title: 'Surplus/\nDeficit',
    val: ({ incomeSpendDifference }) => toCurrency(incomeSpendDifference, true)
  },
  // {
  //   title: `${primary}'s\nRMD`,
  //   val: ({ netRMD }) => toCurrency(netRMD, true)
  // },
  // {
  //   title: `${secondary}'s\nRMD*`,
  //   val: ({ secondaryNetRMD }) => toCurrency(secondaryNetRMD, true)
  // },
  {
    title: 'Combined\nRMD',
    val: ({ combinedNetRMD }) => toCurrency(combinedNetRMD, true)
  },
  {
    title: `${primary}'s\nRegistered\nWithdrawal`,
    hidden: !hasColumnData('registeredOutflows', legacyValue),
    val: ({ registeredOutflows }) => toCurrency(registeredOutflows, true)
  },
  {
    title: `${secondary}'s\nRegistered\nWithdrawal`,
    hidden: !hasColumnData('secondaryRegisteredOutflows', legacyValue),
    val: ({ secondaryRegisteredOutflows }) => toCurrency(secondaryRegisteredOutflows, true)
  },
  {
    title: 'Non-\nRegistered\nWithdrawal',
    hidden: !hasColumnData('nonRegisteredOutflows', legacyValue),
    val: ({ nonRegisteredOutflows }) => toCurrency(nonRegisteredOutflows, true)
  },
  {
    title: 'Tax-Free\nWithdrawal',
    hidden: !hasColumnData('taxFreeOutflows', legacyValue),
    val: ({ taxFreeOutflows }) => toCurrency(taxFreeOutflows, true)
  }
];

const ReportInOutFlows = ({ data }: { data: AggregatedQScoreData }) => {
  const cols = createMasterDistributionCols(
    data.personPrimary.firstName,
    data.personSecondary.firstName,
    data.legacyValue
  );

  return (
    <Page size="A4" orientation="landscape" style={[commonStyles.Root, { paddingBottom: 40 }]}>
      <View style={[commonStyles.Header, { marginBottom: 30 }]} fixed>
        <Text style={commonStyles.HeaderTitle}>INFLOWS/OUTFLOWS REPORT</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          This report shows you the flows of money going in and out of your investment accounts. It
          also shows you from which accounts we will be deriving your retirement income. This is
          only <Text style={styles.BoldFont}>ONE</Text> simulation. It is the exact middle
          simulation meaning half of the simulations had your values above these numbers and half
          had them below. As your investment accounts may very well perform differently from this
          report, it is VERY LIKELY, that the choice of accounts from which we derive your
          retirement income could also change.
        </Text>
        <View style={styles.Row}>
          {cols &&
            cols.map((col, index) => (
              <View key={`${2021 + index}`} style={styles.Col}>
                <View
                  style={{ height: 32, borderBottomWidth: 2, borderBottomColor: COLORS.GRAY1 }}
                  fixed
                >
                  <Text
                    style={{
                      color: COLORS.PRIMARY,
                      textTransform: 'uppercase',
                      fontWeight: 'semibold'
                    }}
                  >
                    {col.title}
                  </Text>
                </View>
                <View>
                  {data.legacyValue.map((legacyValue, index) => (
                    <View
                      key={`legacyvalue${index}`}
                      style={{
                        height: 16,
                        borderBottomWidth: 2,
                        borderBottomColor: COLORS.GRAY1,
                        paddingTop: 4
                      }}
                    >
                      <Text>{col.val(legacyValue)}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportInOutFlows;
