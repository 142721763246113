import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  registeredAccountFilter,
  nonRegisteredAccountFilter,
  taxFreeAccountFilter
} from '@qwealth/qcore';
import { Account, initializeHouseholdAccounts, loadWorkshopData } from '@qwealth/qdata';

import {
  selectHouseholdId,
  selectHouseholdMembers,
  selectHouseholdMemberIds
} from 'data/selectors/householdSelectors';
import { AggregatedAccountsData } from '../interfaces/AggregatedAccountsData';

// Types
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { IState } from 'data/store';

export const useAggregateAccountsData = (): AggregatedAccountsData | null => {
  const [ready, setReady] = useState<boolean>(false);

  const dispatch = useDispatch<ThunkDispatch<IState, any, AnyAction>>();
  const household = useSelector<IState, { initialized: boolean }>((state) => state.household);
  // @ts-ignore
  const legalEntities = useSelector((state) => state.legalEntities);
  const members = useSelector(selectHouseholdMembers);
  const memberIds = useSelector<IState, string[]>(selectHouseholdMemberIds);
  const householdId = useSelector(selectHouseholdId);
  const accounts = useSelector<IState, Account[]>((state) => state.accounts);

  useEffect(() => {
    if (!household.initialized) {
      return;
    }

    const waitAll = memberIds.reduce<Promise<any>[]>(
      // @ts-ignore
      (acc, id) => [...acc, dispatch(loadWorkshopData(id))],
      []
    );
    // @ts-ignore
    waitAll.push(dispatch(initializeHouseholdAccounts(householdId, false)));

    Promise.all(waitAll).then(() => setReady(true));
  }, [household.initialized]);

  // RETURN ASSUMPTIONS
  const registeredAccounts = accounts.filter(registeredAccountFilter);
  const taxFreeAccounts = accounts.filter(taxFreeAccountFilter);
  const nonRegisteredAccounts = accounts.filter(nonRegisteredAccountFilter);
  if (!ready) return null;

  return {
    accounts: {
      'Registered Accounts': registeredAccounts,
      'Tax Free': taxFreeAccounts,
      'Nonregistered Accounts': nonRegisteredAccounts
    },
    members,
    legalEntities
  };
};
