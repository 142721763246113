import { combineReducers } from 'redux';
import {accountsReducer, qscoreReducer, workshopReducer} from '@qwealth/qdata';
import household from './household';
import notifications from './notifications';
import overrides from './overrides';
import timeline from './timeline';
import legalEntities from './legalEntities';

export default combineReducers({
  accounts: accountsReducer,
  household,
  legalEntities,
  notifications,
  overrides,
  qscore: qscoreReducer,
  timeline,
  workshop: workshopReducer
});
