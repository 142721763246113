import React, { useEffect, useState } from 'react';
import { isNull, isEmpty, omit, omitBy, range } from 'lodash';
import { useDispatch } from 'react-redux';
// @ts-ignore
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Form, Modal } from 'react-bootstrap';
import {
  Box,
  Button,
  frequencies,
  nonRegisteredAccountFilter,
  registeredAccountFilter,
  Table,
  taxFreeAccountFilter,
  toCurrency
} from '@qwealth/qcore';
import { Account, assetAllocations, AllocationDetailMap, saveAccount } from '@qwealth/qdata';
import InlineSwitch from '../../Common/InlineSwitch';
import SectionTitle from '../../Common/SectionTitle';
import OwnerLabel from '../../Common/OwnerLabel';
import { OwnerDetails } from 'data/models/OwnerDetails';

type EditableAccount = Account & {
  isDirty?: boolean;
  growthRate?: number;
  deviation?: number;
};

const isActivelyContributing = (cell: any, row: Account) => {
  if (!row || !row.investmentAccountDetails) {
    return false;
  }
  const { isContributory } = row.investmentAccountDetails;
  return isContributory === 1;
};

type InvestmentType = 'Registered' | 'Non-Registered' | 'Tax Free';

const buildColumns = (
  investmentType: InvestmentType,
  setAccounts: Function,
  hiddenColumns: Array<string>
) => [
  {
    dataField: 'accountNumber',
    text: 'accountNumber',
    hidden: true
  },
  {
    dataField: 'accountTitle',
    text: 'Title*',
    editable: false
  },
  {
    dataField: 'isSimulatedInQScore',
    text: 'Send to QScore?',
    editable: false,
    events: {
      // @ts-ignore
      onClick: (e, column, columnIndex, row) => {
        e.preventDefault();
        const { isSimulatedInQScore, accountNumber } = row;
        setAccounts((prevState: Array<Account>) =>
          prevState.map((a) =>
            a.accountNumber === accountNumber
              ? {
                  ...a,
                  isSimulatedInQScore: isSimulatedInQScore === 1 ? 0 : 1,
                  isDirty: true
                }
              : a
          )
        );
      }
    },
    formatter: (isSimulatedInQScore: number) => <InlineSwitch checked={isSimulatedInQScore === 1} />
  },
  {
    dataField: 'investmentAccountDetails.investmentAccountSubType',
    text: 'Type*',
    editable: false
  },
  {
    dataField: 'assetAllocation',
    text: 'Asset Allocation*',
    editor: {
      type: Type.SELECT,
      options: assetAllocations.map((t) => ({ value: t, label: t }))
    },
    formatter: (cell?: string) => cell || 'Select Allocation',
    hidden: hiddenColumns.includes('assetAllocation')
  },
  {
    dataField: 'rateOfReturn',
    text: 'Rate of Return (%)',
    editable: false,
    formatter: (val: string, row: Account) => {
      const { assetAllocation } = row;
      const allocationDetail = assetAllocation && AllocationDetailMap[assetAllocation];
      return allocationDetail ? allocationDetail.growthRate : '';
    },
    hidden: hiddenColumns.includes('assetAllocation')
  },
  {
    dataField: 'standardDeviation',
    text: 'Standard Deviation (%)',
    editable: false,
    formatter: (val: string, row: Account) => {
      const { assetAllocation } = row;
      const allocationDetail = assetAllocation && AllocationDetailMap[assetAllocation];
      return allocationDetail ? allocationDetail.deviation : '';
    },
    hidden: hiddenColumns.includes('assetAllocation')
  },
  {
    dataField: 'investmentAccountDetails.isContributory',
    text: 'Actively Contributing?',
    editable: false,
    hidden: hiddenColumns.includes('contributions'),
    events: {
      // @ts-ignore
      onClick: (e, column, columnIndex, row) => {
        e.preventDefault();
        const { investmentAccountDetails, accountNumber } = row;
        if (!investmentAccountDetails) {
          return;
        }
        const { isContributory } = investmentAccountDetails;
        setAccounts((prevState: Array<Account>) =>
          prevState.map((a) =>
            a.accountNumber === accountNumber
              ? {
                  ...a,
                  isDirty: true,
                  investmentAccountDetails: {
                    ...investmentAccountDetails,
                    isContributory: isContributory === 1 ? 0 : 1
                  }
                }
              : a
          )
        );
      }
    },
    formatter: (isContributory: number) => <InlineSwitch checked={isContributory === 1} />
  },
  {
    dataField: 'investmentAccountDetails.contributionType',
    text: 'Contribution Type*',
    hidden: hiddenColumns.includes('contributions'),
    editor: {
      type: Type.SELECT,
      options: [
        { label: '%', value: 'Percentage' },
        { label: '$', value: 'Amount' }
      ]
    },
    formatter: (v: string | undefined) => {
      if (v) {
        return v === 'Percentage' ? '%' : '$';
      }
      return 'Select Type';
    },
    editable: isActivelyContributing
  },
  {
    dataField: 'investmentAccountDetails.contributionAmount',
    text: 'Contribution Amount',
    hidden: hiddenColumns.includes('contributions'),
    editable: (cell: any, row: Account) =>
      isActivelyContributing(cell, row) &&
      row.investmentAccountDetails!.contributionType === 'Amount',
    editor: {
      type: Type.TEXT
    },
    formatter: (val: number, row: EditableAccount) => {
      const { investmentAccountDetails } = row;
      return investmentAccountDetails?.contributionType !== 'Amount' ||
        !investmentAccountDetails?.isContributory
        ? '-'
        : toCurrency(val, true);
    }
  },
  {
    dataField: 'investmentAccountDetails.contributionsPercentageOfIncome',
    text: 'Contribution %',
    hidden: hiddenColumns.includes('contributions'),
    editable: (cell: any, row: Account) =>
      isActivelyContributing(cell, row) &&
      row.investmentAccountDetails!.contributionType === 'Percentage',
    editor: {
      type: Type.TEXT
    },
    formatter: (val: number, row: EditableAccount) => {
      const { investmentAccountDetails } = row;
      return investmentAccountDetails?.contributionType !== 'Percentage' ||
        !investmentAccountDetails?.isContributory
        ? '-'
        : `${val}%`;
    }
  },
  {
    dataField: 'investmentAccountDetails.contributionFrequency',
    text: 'Contribution Frequency*',
    hidden: hiddenColumns.includes('contributions'),
    editor: {
      type: Type.SELECT,
      options: frequencies.map((t) => ({ value: t, label: t }))
    },
    editable: isActivelyContributing
  },
  {
    dataField: 'investmentAccountDetails.contributionStart',
    text: 'Contribution Starts*',
    hidden: hiddenColumns.includes('contributions'),
    editor: {
      type: Type.SELECT,
      options: ['Retirement']
        .concat(range(25, 95).map((age) => age + ''))
        .map((t) => ({ value: t, label: t }))
    },
    editable: isActivelyContributing
  },
  {
    dataField: 'investmentAccountDetails.contributionEnd',
    text: 'Contribution Ends*',
    hidden: hiddenColumns.includes('contributions'),
    editor: {
      type: Type.SELECT,
      options: ['Retirement', 'Death']
        .concat(range(25, 95).map((age) => age + ''))
        .map((t) => ({ value: t, label: t }))
    },
    editable: isActivelyContributing
  },
  {
    dataField: 'value',
    text: 'Value*',
    formatter: (cell: number) => toCurrency(cell, true),
    sort: true,
    editor: {
      type: Type.TEXT
    }
  }
];

const buildCellEdit = (setAccounts: Function) =>
  cellEditFactory({
    mode: 'click',
    blurToSave: true,
    // @ts-ignore
    afterSaveCell: (oldValue, newValue, row: EditableAccounts) => {
      const { accountNumber, assetAllocation } = row;
      const allocationDetail = AllocationDetailMap[assetAllocation] || {};
      const { growthRate, deviation } = allocationDetail;
      setAccounts((prevAccounts: Array<Account>) => {
        return prevAccounts.map((a) => {
          if (a.accountNumber === accountNumber) {
            return { ...row, growthRate, deviation, isDirty: true };
          }
          return a;
        });
      });
    }
  });

interface AccountsTableProps {
  title: string;
  type: InvestmentType;
  hiddenColumns: Array<string>;
  accounts: Array<EditableAccount>;
  setAccounts: Function;
}

const AccountsTable: React.FC<AccountsTableProps> = ({
  title,
  type,
  hiddenColumns,
  accounts,
  setAccounts
}) =>
  accounts.length > 0 ? (
    <div>
      <SectionTitle>{title}</SectionTitle>
      <Table
        // @ts-ignore
        keyField="accountNumber"
        cellEdit={buildCellEdit(setAccounts)}
        columns={buildColumns(type, setAccounts, hiddenColumns)}
        data={accounts}
      />
    </div>
  ) : null;

interface EditorProps {
  accounts: Array<Account>;
  owner?: OwnerDetails;
  setOwner: Function;
}

const Editor: React.FC<EditorProps> = ({ accounts, owner, setOwner }) => {
  const dispatch = useDispatch();

  const [hiddenColumns, setHiddenColumns] = useState<Array<string>>([]);
  const [registeredAccounts, setRegisteredAccounts] = useState<EditableAccount[]>([]);
  const [nonRegisteredAccounts, setNonRegisteredAccounts] = useState<EditableAccount[]>([]);
  const [taxFreeAccounts, setTaxFreeAccounts] = useState<EditableAccount[]>([]);

  const handleClose = () => {
    setRegisteredAccounts([]);
    setNonRegisteredAccounts([]);
    setTaxFreeAccounts([]);
    setOwner(null);
  };

  useEffect(() => {
    if (owner) {
      setRegisteredAccounts(accounts.filter(registeredAccountFilter));
      setNonRegisteredAccounts(accounts.filter(nonRegisteredAccountFilter));
      setTaxFreeAccounts(accounts.filter(taxFreeAccountFilter));
    }
  }, [owner, accounts]);

  const isAllEmpty =
    isEmpty(registeredAccounts) && isEmpty(nonRegisteredAccounts) && isEmpty(taxFreeAccounts);

  return (
    <Modal show={owner != null} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {'Retirement Savings - '}
          <OwnerLabel type={owner && owner.type}>{owner && owner.name}</OwnerLabel>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box display="flex" flexDirection="row" gap="default" mb="large">
          Fields:
          <Form.Check
            type="checkbox"
            label="Asset Allocation"
            checked={!hiddenColumns.includes('assetAllocation')}
            onChange={(e) => {
              if (e.target.checked) {
                setHiddenColumns((prevState) => prevState.filter((c) => c !== 'assetAllocation'));
              } else {
                setHiddenColumns((prevState) => [...prevState, 'assetAllocation']);
              }
            }}
          />
          <Form.Check
            type="checkbox"
            label="Contributions"
            checked={!hiddenColumns.includes('contributions')}
            onChange={(e) => {
              if (e.target.checked) {
                setHiddenColumns((prevState) => prevState.filter((c) => c !== 'contributions'));
              } else {
                setHiddenColumns((prevState) => [...prevState, 'contributions']);
              }
            }}
          />
        </Box>

        {isAllEmpty && <p>Please add accounts via QLife</p>}

        <AccountsTable
          accounts={registeredAccounts}
          setAccounts={setRegisteredAccounts}
          title="Registered Savings"
          type="Registered"
          hiddenColumns={hiddenColumns}
        />

        <AccountsTable
          accounts={nonRegisteredAccounts}
          setAccounts={setNonRegisteredAccounts}
          title="Non-Registered Savings"
          type="Non-Registered"
          hiddenColumns={hiddenColumns}
        />

        <AccountsTable
          accounts={taxFreeAccounts}
          setAccounts={setTaxFreeAccounts}
          title="Tax-Free Savings"
          type="Tax Free"
          hiddenColumns={hiddenColumns}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="default"
          onClick={() => {
            const allAccounts = registeredAccounts
              .concat(nonRegisteredAccounts)
              .concat(taxFreeAccounts);

            allAccounts
              .filter((a) => a.isDirty)
              .map((account) => {
                const investmentAccountDetails = omitBy(account.investmentAccountDetails, isNull);
                let { accountOwnershipDetails } = account;
                // @ts-ignore
                accountOwnershipDetails =
                  accountOwnershipDetails && omitBy(accountOwnershipDetails, isNull);
                return omitBy(
                  { ...account, investmentAccountDetails, accountOwnershipDetails, update: true },
                  isNull
                );
              })
              .map((account) =>
                omit(account, ['isDirty', 'growthRate', 'deviation', 'value', 'accountId'])
              )
              .forEach((account) => dispatch(saveAccount(account)));

            handleClose();
          }}
        >
          Save
        </Button>
        <Button variant="outline" color="gray.2" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Editor;
