import React from 'react';
import { Block } from '@qwealth/qcore';

export default function BorderedSection(props) {
  return (
    <Block
      borderRadius="default"
      borderColor={props.highlighted ? 'red' : 'gray.3'}
      backgroundColor={props.highlighted ? 'redTransparent' : 'white'}
      borderWidth="2px"
      borderStyle="solid"
      px="large"
      py="default"
      {...props}
    >
      {props.children}
    </Block>
  );
}
