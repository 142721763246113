import React from 'react';
import { Box } from '@qwealth/qcore';
import QScoreMeter from './QScoreMeter';
import QScoreDescription from './QScoreDescription';

export default function QScoreResults(props) {
  return (
    <div>
      <Box className="score-value" textAlign="center" fontWeight="700" fontSize="3rem" opacity="0">
        {props.value}
      </Box>
      <QScoreMeter value={props.value} />
      <QScoreDescription value={props.value} />
    </div>
  );
}
