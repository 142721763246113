import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, DeleteButton, TextInput } from '@qwealth/qcore';
import { adoptInput, updateHistoricalQScore } from '@qwealth/qdata';
import { BsX } from 'react-icons/bs';
import * as PropTypes from 'prop-types';

const projectionsTypes = ['GUARANTEED_INCOME_PROJECTION', 'MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION'];

const QScoreDetails = ({ archivedQScore, deleteHandler, clearHandler, reload }) => {
  const dispatch = useDispatch();
  const updateHandler = (updatedQScore) =>
    dispatch(updateHistoricalQScore(updatedQScore)).then(() => reload());

  const adoptHandler = () => dispatch(adoptInput(archivedQScore)).then(() => reload());

  return (
    <Card flex="1">
      <Box style={{ position: 'relative' }} mb="large">
        <Box textStyle="sectionTitle" textAlign="center">
          {new Date(archivedQScore.calcDate).toDateString()}
        </Box>
        <Button
          variant="icon"
          onClick={clearHandler}
          style={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
        >
          <BsX />
        </Button>
      </Box>
      <TextInput
        defaultValue={archivedQScore.name}
        onBlur={(event) => {
          const { value } = event.target;
          if (value !== archivedQScore.name) {
            updateHandler({ ...archivedQScore, name: value });
          }
        }}
        style={{ width: '100%' }}
      />
      <Box textAlign="center" fontSize="3em" fontWeight="bolder" my="large">
        {archivedQScore.qScore}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap="default">
        <Button onClick={() => adoptHandler()}>Adopt Plan</Button>
        <DeleteButton
          mx="auto"
          disabled={projectionsTypes.includes(archivedQScore.type)}
          onClick={() => deleteHandler(archivedQScore.id)}
        />
      </Box>
    </Card>
  );
};

QScoreDetails.propTypes = {
  reload: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  archivedQScore: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    qScore: PropTypes.number.isRequired,
    calcDate: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'BASELINE',
      'SIMULATION',
      'GUARANTEED_INCOME_PROJECTION',
      'MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION'
    ]).isRequired
  }).isRequired
};

export default QScoreDetails;
