import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  currentSpendingFilter,
  getFlowDisplayValue,
  getLegacyDisplayValue,
  getPreRetirementSavingsDisplayValue,
  getRetirementSpendingDisplayValue,
  getRiskManagementDeathDisplayValue,
  getRiskManagementLongTermCareDisplayValue,
  Table,
  toCurrency
} from '@qwealth/qcore';
import {
  dataPointListSelector,
  loadWorkshopData,
  setSimulationIds,
  simulationListSelector
} from '@qwealth/qdata';
import { selectHouseholdMemberIds } from 'data/selectors/householdSelectors';
import { getPerson } from 'utils/HouseholdUtil';
import InlineSwitch from '../../Common/InlineSwitch';

const frequencyTypes = [
  'Pre-retirement: Spending',
  'Post-retirement: Inflow',
  'Post-retirement: Outflow',
  'Beneficiary: Other'
];

const buildColumns = (household, dataPoints, selectedSimulations, setSimulations) => [
  {
    dataField: 'id',
    text: 'id',
    hidden: true
  },
  {
    dataField: 'selected',
    text: 'selected',
    events: {
      onClick: (e, column, columnIndex, row) => {
        e.preventDefault();
        const { id } = row;
        const index = selectedSimulations.indexOf(id);
        let newSelection = [...selectedSimulations, id];
        if (index > -1) {
          newSelection = selectedSimulations.filter((s) => s !== id);
        }
        setSimulations(newSelection);
      }
    },
    formatter: (v, row) => <InlineSwitch checked={selectedSimulations.indexOf(row.id) > -1} />
  },
  {
    dataField: 'simulationType',
    text: 'Type'
  },
  {
    dataField: 'attendeeQID',
    text: 'Owner',
    formatExtraData: household,
    formatter: (ownerId, row, rowIndex, householdParam) => {
      const owner = getPerson(householdParam, ownerId);
      return owner ? owner.firstName : 'N/A';
    }
  },
  {
    dataField: 'value',
    text: 'Value',
    sort: true,
    formatExtraData: { household, dataPoints },
    formatter: (val, row, rowIndex, { household: { members }, dataPoints: dataPointList }) => {
      let type = row.goalType;
      if (!type) {
        type = row.datapointType;
      }
      if (!type) {
        type = row.simulationType;
      }

      if (type === 'Retirement: Age') {
        return row.retirementAgeValue;
      }
      let amount;
      if (frequencyTypes.includes(type)) {
        return getFlowDisplayValue(row);
      }

      if (type === 'Guaranteed Retirement Income') {
        const { guaranteedRetirementIncome } = row;
        return guaranteedRetirementIncome.map(
          ({ griType, amount: griAmount, startAge, endAge }) => (
            <p>{`${griType} ${toCurrency(griAmount)} from ${startAge} to ${endAge}`}</p>
          )
        );
      }

      if (type === 'Current Spending') {
        amount = row.currentSpendingValue;
      }

      if (type === 'Retirement: Spending') {
        const currentSpendingDataPoint = dataPointList.find(currentSpendingFilter);
        return getRetirementSpendingDisplayValue(row, currentSpendingDataPoint);
      }
      if (type === 'Pre-retirement: Savings') {
        return getPreRetirementSavingsDisplayValue(row);
      }
      if (type === 'Legacy: End Value') {
        return getLegacyDisplayValue(row);
      }
      if (type === 'Risk Management: Long-term care') {
        return getRiskManagementLongTermCareDisplayValue(row);
      }
      if (type === 'Risk Management: Death') {
        const person = members.find((p) => p.id === row.attendeeQID);
        return getRiskManagementDeathDisplayValue(row, person);
      }

      return amount ? toCurrency(amount, true) : 'N/A';
    }
  },
  {
    dataField: 'comment',
    text: 'Comments'
  }
];

export default function SimulationSelector() {
  const [household, qscore] = useSelector((state) => [state.household, state.qscore]);
  const simulations = useSelector(simulationListSelector);
  const dataPoints = useSelector(dataPointListSelector);

  const dispatch = useDispatch();
  const memberIds = useSelector(selectHouseholdMemberIds);

  useEffect(() => {
    memberIds.forEach((id) => dispatch(loadWorkshopData(id)));
  }, [dispatch, memberIds]);

  useEffect(() => {
    return () => {
      dispatch(setSimulationIds([]));
    };
  }, [dispatch]);

  const setSimulations = (simulationIds) => dispatch(setSimulationIds(simulationIds));
  const columns = buildColumns(household, dataPoints, qscore.simulationIds, setSimulations);

  console.log('---', columns, simulations, qscore.simulationIds);
  return (
    <Box mb="large">
      <Table tableHeight="300px" fixedHeader columns={columns} data={simulations} />
    </Box>
  );
}
