import _ from 'lodash';
import { PUT_HOUSEHOLD_MEMBER, HOUSEHOLD_INITIALIZED } from '../actions/household';

const ORDER = ['Primary', 'Secondary', 'Beneficiary'];
export default function (state = { initialized: false, householdId: '', members: [] }, action) {
  switch (action.type) {
    case HOUSEHOLD_INITIALIZED: {
      const { householdId } = action;
      return { ...state, householdId, initialized: true };
    }
    case PUT_HOUSEHOLD_MEMBER: {
      const { person } = action;
      const { QID } = person;
      const { members } = state;
      const index = members.map((p) => p.QID).indexOf(QID);
      if (index > -1) {
        return {
          ...state,
          members: members.map((p, idx) => (idx === index ? person : p))
        };
      }

      return {
        ...state,
        members: _.sortBy([...members, person], (p) => _.indexOf(ORDER, p.type))
      };
    }
    default:
      return state;
  }
}
