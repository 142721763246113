import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, omit } from 'lodash';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import { AddButton, Box, Button, Modal, Table } from '@qwealth/qcore';
import {
  IDataPointWorkshopDto,
  IGuaranteedRetirementDto,
  loadCreateWorkshop,
  saveWorkshop,
  deleteGri
} from '@qwealth/qdata';
import { OwnerDetails } from 'data/models/OwnerDetails';
import { buildColumns, EditableGuaranteedRetirementDto, getGriTypes } from './helper';

type Props = {
  isEditorOpen: boolean;
  setEditorOpen: (open: boolean) => void;
  owner?: OwnerDetails;
  startAge: number;
  incomeDataPoint?: IDataPointWorkshopDto;
};

let initialId = 999999999;

const Editor: React.FC<Props> = ({ isEditorOpen, setEditorOpen, owner, startAge, incomeDataPoint  }) => {
  const [griList, setGriList] = useState<Array<EditableGuaranteedRetirementDto>>([]);

  const parent = incomeDataPoint?.parent || 'Enhancement';
  const ownerId = owner?.id || '';
  const dispatch = useDispatch();

  const handleClose = () => setEditorOpen(false);

  useEffect(() => {
    if (isEditorOpen && isEmpty(griList) && !isEmpty(incomeDataPoint?.guaranteedRetirementIncome)) {
      setGriList(incomeDataPoint!.guaranteedRetirementIncome!.filter(gri => !isEmpty(gri)));
    }
  }, [incomeDataPoint, griList, isEditorOpen]);

  const addGriHandler = useCallback(() => {
    Promise.resolve(dispatch(loadCreateWorkshop(ownerId, parent)))
      .then((workshopID) => {
        const newFlow: EditableGuaranteedRetirementDto = {
          id: --initialId,
          griType: 'rentalPropertyIncome',
          startAge: 'Retirement',
          endAge: 'Death',
          amount: 0,
          spousalBenefit: 0,
          isDirty: true,
          isNew: true,
          // @ts-ignore
          workshopID,
        };
        setGriList([...griList, newFlow]);
      });
  }, [griList, owner]);

  const deleteHandler = useCallback((row: EditableGuaranteedRetirementDto) => setGriList((prevIncomes) =>
    prevIncomes.map((income) => (income.id === row.id ? { ...income, isDeleted: true } : income))
  ), []);

  const saveHandler = () => {
    griList
      .filter(({ isDeleted, isNew }) => isDeleted && !isNew)
      .forEach(gri => dispatch(deleteGri(gri.id)));

    const guaranteedRetirementIncome: Array<IGuaranteedRetirementDto> = griList
      .filter(({ isDeleted }) => !isDeleted)
      .map(gri => ({
        ...omit(gri, ['isNew']),
        id: gri.isNew ? 0 : gri.id,
      }));
    const griToSave: IDataPointWorkshopDto = {
      ...omit(incomeDataPoint, 'parent'),
      guaranteedRetirementIncome,
    };
    dispatch(saveWorkshop(griToSave, 'Data Point', ownerId, parent));
    handleClose();
  };

  const columns = useMemo(() => {
    const currentTypes = griList.filter(gri => !gri.isDeleted).map(gri => gri.griType);
    const typeOptions = getGriTypes(currentTypes);
    return buildColumns(deleteHandler, typeOptions, startAge);
  }, [deleteHandler, griList, startAge]);

  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue: any, newValue: any, row: EditableGuaranteedRetirementDto) => {
      const { id } = row;
      setGriList(prevGriList =>
        prevGriList.map((f) => {
          if (f.id === id) {
            return { ...row, isDirty: true };
          }
          return f;
        })
      );
    }
  });

  return (
    <Modal
      show={isEditorOpen}
      onHide={handleClose}
      size="lg"
      onClose={() => setEditorOpen(false)}
      title={`Guaranteed Retirement Income - ${owner?.name}`}
    >
      <Modal.Body>
        <Table
          // @ts-ignore
          fixedHeader
          cellEdit={cellEdit}
          tableHeight="auto"
          keyField="id"
          columns={columns}
          data={griList.filter(({ isDeleted }) => !isDeleted)}
        />
        <Box mt="large">
          <AddButton color="primary" onClick={addGriHandler}>Add GRI</AddButton>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveHandler}>
          Save
        </Button>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Editor;
