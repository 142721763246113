import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Box } from '@qwealth/qcore';
import { dataPointListSelector, goalListSelector, loadWorkshopData } from '@qwealth/qdata';
import QScore from '../QScore';
import RetirementAge from './RetirementAge';
import RetirementSpending from './RetirementSpending';
import RetirementSavings from './RetirementSavings';
import RetirementFlows from './RetirementFlows';
import Legacy from './Legacy';
import { getAge } from '../../utils/HouseholdUtil';
import {
  selectHouseholdMemberIds,
  selectPrimary,
  selectSecondary
} from '../../data/selectors/householdSelectors';
import { retirementAgeFilter } from '@qwealth/qcore/build/es/utils/WorkshopUtil';

export const buildRetirementMap = (retirementGoals, primary, secondary) => {
  const retirementMap = {};
  if (!retirementGoals || retirementGoals.length === 0) {
    return retirementMap;
  }

  const primaryGoal = retirementGoals.find((r) => r.attendeeQID === primary.QID);
  const secondaryGoal = retirementGoals.find((r) => r.attendeeQID === secondary.QID);
  if (primaryGoal) {
    retirementMap[primary.QID] = primaryGoal.retirementAgeValue;
  }

  if (secondaryGoal) {
    retirementMap[secondary.QID] = secondaryGoal.retirementAgeValue;
  }

  return retirementMap;
};

export default function BaselinePlan() {
  const primary = useSelector(selectPrimary);
  const secondary = useSelector(selectSecondary);

  const memberIds = useSelector(selectHouseholdMemberIds);
  const goalList = useSelector(goalListSelector);
  const dataPointList = useSelector(dataPointListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    memberIds.forEach((id) => dispatch(loadWorkshopData(id)));
  }, [dispatch, memberIds]);

  const retirementGoals = goalList.filter(retirementAgeFilter);

  const preRetirementSpendingFlows = goalList
    .filter(({ goalType }) => goalType === 'Pre-retirement: Spending');
  const inflows = goalList.filter(({ goalType }) => goalType === 'Post-retirement: Inflow');
  const outflows = goalList.filter(({ goalType }) => goalType === 'Post-retirement: Outflow');

  const ageMap = useMemo(() => {
    const map = {};
    if (primary) {
      map[primary.QID] = getAge(primary.dateOfBirth);
    }
    if (secondary) {
      map[secondary.QID] = getAge(secondary.dateOfBirth);
    }
    return map;
  }, [primary, secondary]);

  const retirementMap = useMemo(() => {
    return buildRetirementMap(retirementGoals, primary, secondary);
  }, [retirementGoals, primary, secondary]);

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns={{
          phone: '1fr',
          desktop: '1fr 350px'
        }}
        rowGap="default"
      >
        <Box
          order={{
            phone: '2',
            desktop: '1'
          }}
        >
          <Card shadow title="Current Plan of Execution">
            <RetirementAge
              primary={primary}
              secondary={secondary}
              retirementGoals={retirementGoals}
            />
            <RetirementSpending
              primary={primary}
              secondary={secondary}
              goalList={goalList}
              dataPointList={dataPointList}
            />
            <RetirementSavings primary={primary} secondary={secondary} />
            <RetirementFlows
              ageMap={ageMap}
              retirementMap={retirementMap}
              goalType="Pre-retirement: Spending"
              flows={preRetirementSpendingFlows}
            />
            <RetirementFlows
              ageMap={ageMap}
              retirementMap={retirementMap}
              goalType="Post-retirement: Inflow"
              flows={inflows}
            />
            <RetirementFlows
              ageMap={ageMap}
              retirementMap={retirementMap}
              goalType="Post-retirement: Outflow"
              flows={outflows}
            />
            <Legacy goalList={goalList} />
          </Card>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          order={{
            phone: '1',
            desktop: '2'
          }}
        >
          <QScore />
        </Box>
      </Box>
    </>
  );
}
