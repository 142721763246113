import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  }
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

const ReportProjection = ({ data }: { data?: AggregatedQScoreData }) => {
  const chartProjectionImg = useChartJsAsImage(
    crateChartJsConfig('line', data!.chartProjection.labels, data!.chartProjection.datasets)
  );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>PROJECTION REPORT</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30,
          paddingTop: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          This report gives you a better sense of <Text style={styles.BoldFont}>ALL</Text> the
          possible outcomes. The <Text style={styles.BoldFont}>10th percentile</Text>
          means 90% of the simulations had year-end values more than this range of results.{' '}
          <Text style={styles.BoldFont}>Median</Text>
          returns mean half the simulations will be above this range and half will be below. The
          <Text style={styles.BoldFont}>90th percentile</Text> means fewer than 10% of the results
          were greater than this range. It is common in a Monte Carlo Simulation to see higher
          possible outcome ranges on higher percentiles, though no greater likelihood statistically
          of that event occurring.
        </Text>
        {chartProjectionImg}
      </View>
    </Page>
  );
};

export default ReportProjection;
