import React, { useState } from 'react';
import { Button, Card } from '@qwealth/qcore';
import { useDispatch } from 'react-redux';

export default function Projection({ householdId, projection, reload }) {
  const { calcFn, title, description } = projection;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const recalculateHandler = () => {
    setLoading(true);
    dispatch(calcFn(householdId, true))
      .then(() => setLoading(false))
      .then(() => reload());
  };

  return (
    <Card mb="large" title={title}>
      <p>{description}</p>
      <Button disabled={loading} onClick={recalculateHandler}>
        Recalculate Projection
      </Button>
    </Card>
  );
}
