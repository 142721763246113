import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export default function OptionalColumnSelector(props) {
  const { optionalColumnMap, visibleColumns, setVisibleColumns } = props;
  return (
    <div key="inline-checkbox" className="mb-3">
      {Object.keys(optionalColumnMap).map((column) => (
        <Form.Check
          key={column}
          inline
          label={optionalColumnMap[column]}
          type="checkbox"
          id={column}
          checked={visibleColumns.includes(column)}
          onChange={(event) => {
            const { checked } = event.target;
            if (checked) {
              setVisibleColumns((prevState) => [...prevState, column]);
            } else {
              setVisibleColumns((prevState) => prevState.filter((col) => col !== column));
            }
          }}
        />
      ))}
    </div>
  );
}

OptionalColumnSelector.propTypes = {
  setVisibleColumns: PropTypes.func.isRequired,
  visibleColumns: PropTypes.array.isRequired,
  optionalColumnMap: PropTypes.shape({}).isRequired
};
