import React, { useState } from 'react';
import { Box, NumberedList, TextInput } from '@qwealth/qcore';
import { useSelector } from 'react-redux';
import { selectPrimary, selectSecondary } from '../../data/selectors/householdSelectors';

export default function WithdrawOverride() {
  const primary = useSelector(selectPrimary);
  const secondary = useSelector(selectSecondary);

  const [nonRegisteredSplit, setNonRegisteredSplit] = useState(50);
  const [taxFreeSplit, setTaxFreeSplit] = useState(50);
  const [primarySplit, setPrimarySplit] = useState(50);
  const [secondarySplit, setSecondarySplit] = useState(50);

  const isSplitValid = taxFreeSplit + nonRegisteredSplit === 100;
  const isRegisteredSplitValid = primarySplit + secondarySplit === 100;

  return (
    <Box>
      <Box as="p" mb="largest">
        The following withdrawal rules are applied in ranking order:
      </Box>
      <NumberedList>
        <li>
          <h5>Non-Registered Withdrawal</h5>
        </li>
        <Box mx={60} mb="largest">
          <p>
            Annual deficit will be withdrawn from non-registered accounts, for tax efficiency, if
            the following conditions are met:
          </p>
          <p>
            <code>Non-Registered Beginning Balance &gt; Annual Deficit</code>
          </p>
          <p>
            <code>
              Non-Registered Beginning Balance - Annual Deficit &gt; 20% Total Retirement Assets
            </code>
          </p>
        </Box>

        <li>
          <h5>Non-Registered/Tax-Free Withdrawal</h5>
        </li>
        <Box ml={60} mb="largest">
          <p>
            Annual deficit will be withdrawn from non-registered AND tax-free accounts with the
            following split:
          </p>
          <label>Non-Registered Withdrawal:</label>
          <TextInput
            ml="small"
            mb="default"
            type="number"
            value={nonRegisteredSplit}
            onChange={(event) => setNonRegisteredSplit(parseFloat(event.target.value))}
          />
          <label>Tax-Free Withdrawal:</label>
          <TextInput
            ml="small"
            mb="default"
            type="number"
            value={taxFreeSplit}
            onChange={(event) => setTaxFreeSplit(parseFloat(event.target.value))}
          />
          <br />
          {!isSplitValid && (
            <span className="alert-warning">
              {`Non - Registered AND Tax - Free withdrawal must equal to 100%, but is equal to ${
                nonRegisteredSplit + taxFreeSplit
              }%`}
            </span>
          )}
          <p>
            Annual deficit will be withdrawn from both non-registered <b>AND</b> tax-free accounts
            if the following conditions are met:
          </p>
          <p>
            <code>
              {`Non-Registered Beginning Balance > ${nonRegisteredSplit}% Annual Deficit`}
            </code>
          </p>
          <p>
            <code>
              {`Non-Registered Beginning Balance - ${nonRegisteredSplit}% annual deficit > 20% Total Retirement Assets`}
            </code>
          </p>
          <p>
            <code>{`Tax-Free Beginning Balance > ${taxFreeSplit}% Annual Deficit`}</code>
          </p>
          <p>
            <code>
              {`Tax-Free Beginning Balance - ${taxFreeSplit}% Annual Deficit > 20% Total Retirement Assets`}
            </code>
          </p>
        </Box>

        <li>
          <h5>Registered Withdrawal</h5>
        </li>
        <Box ml={60} mb="largest">
          <p>
            {`Annual deficit will be withdrawn from ${primary && primary.firstName}'s and
              ${secondary && secondary.firstName}'s registered accounts with the following 
              split:`}
          </p>
          <label>{`${primary && primary.firstName}'s Registered Withdrawal:`}</label>
          <TextInput
            ml="small"
            mb="default"
            type="number"
            value={primarySplit}
            onChange={(event) => setPrimarySplit(parseFloat(event.target.value))}
          />
          <label>{`${secondary && secondary.firstName}'s Registered Withdrawal:`}</label>
          <TextInput
            ml="small"
            mb="default"
            type="number"
            value={secondarySplit}
            onChange={(event) => setSecondarySplit(parseFloat(event.target.value))}
          />
          <br />
          {!isRegisteredSplitValid && (
            <span className="alert-warning">
              {`${primary && primary.firstName} AND ${
                secondary && secondary.firstName
              } withdrawal must equal to 100%, but is equal to ${primarySplit + secondarySplit}%`}
            </span>
          )}
          <p>
            {`Annual deficit will be withdrawn from both ${primary && primary.firstName}'s `}
            <b>AND</b>
            {` ${secondary && secondary.firstName}'s registered accounts if the following are met:`}
          </p>
          <p>
            <code>
              {`${
                primary && primary.firstName
              }'s Registered Beginning Balance > ${primarySplit}% Annual Deficit`}
            </code>
          </p>
          <p>
            <code>
              {`${
                secondary && secondary.firstName
              }'s Registered Beginning Balance > ${secondarySplit}% Annual Deficit`}
            </code>
          </p>
        </Box>

        <li>
          <h5>Withdraw Any</h5>
        </li>
        <Box ml={60} mb="largest">
          <p>
            See if funds can be withdrawn from Non-Registered, Tax-Free, Banking or Taxable accounts
            in that order. A negative balance will occur if all accounts has insufficient funds.
          </p>
        </Box>
      </NumberedList>

      <small>
        * = Net of federal AND provincial taxes
        <br />
        &dagger; = Inflation adjusted
      </small>
    </Box>
  );
}
