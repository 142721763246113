import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdSwap } from 'react-icons/io';
import _ from 'lodash';
import { deleteInput, loadInput } from '@qwealth/qdata';
import { Box, Button, Card, styled, themeGet } from '@qwealth/qcore';

import QScoreDetails from './QScoreDetails';
import { normalizeWorkshopDTO } from './helper';
import { ArchivedInput } from '../../../data/models/ArchivedInput';

const StyledTable = styled.table`
  width: 100%;
  font-size: ${themeGet('fontSizes.small')};
  background: ${themeGet('colors.gray.4')};

  thead {
    color: ${themeGet('colors.primary')};
    background: ${themeGet('colors.primaryTransparent')};
    text-transform: uppercase;
  }

  th,
  td {
    padding: ${themeGet('space.default')};
  }

  tbody tr:not(:last-child) {
    border-bottom: 1px solid ${themeGet('colors.gray.3')};
  }
`;

interface Props {
  reload: Function;
  clearHandler: Function;
  swapHandler: Function;
  selected: Array<any>;
}

const QScoreMetadata: React.FC<Props> = ({ reload, selected, clearHandler, swapHandler }) => {
  // @ts-ignore
  const household = useSelector((state) => state.household);
  const dispatch = useDispatch();

  const [archivedInput, setArchivedInput] = useState<ArchivedInput>({} as ArchivedInput);
  const { householdId, workshopDTOs, diff = {} } = archivedInput;

  const deleteHandler = (inputId: string) =>
    dispatch(deleteInput(inputId))
      // @ts-ignore
      .then(() => reload())
      .then(() => setArchivedInput({}));

  useEffect(() => {
    setArchivedInput({});
  }, [reload]);

  useEffect(() => {
    if (selected.length > 0) {
      const diffInputId = selected.length > 1 ? selected[1].archivedInputId : undefined;
      // @ts-ignore
      dispatch(loadInput(selected[0].archivedInputId, diffInputId)).then(
        (input: ArchivedInput) => input && setArchivedInput(input)
      );
    } else {
      setArchivedInput({});
    }
  }, [dispatch, selected]);

  const normalizedWorkshopDTOs =
    workshopDTOs && normalizeWorkshopDTO(household, diff, workshopDTOs);

  return (
    <>
      {selected.length > 0 && householdId && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box textAlign="center" textStyle="sectionTitle" mb="default">
            Selected QScore(s)
          </Box>
          {selected.length > 1 && <p>Please note that the order of the comparison matters</p>}
          <Box width="100%" mb="large" display="flex" gap="large" alignItems="center">
            {selected.map((archivedQScore, index) => (
              <>
                {index === 1 && (
                  // @ts-ignore
                  <Button width="50px" height="50px" variant="icon" onClick={swapHandler}>
                    <IoMdSwap />
                  </Button>
                )}
                <QScoreDetails
                  key={archivedQScore.id}
                  archivedQScore={archivedQScore}
                  clearHandler={clearHandler(archivedQScore)}
                  deleteHandler={deleteHandler}
                  // @ts-ignore
                  reload={reload}
                />
              </>
            ))}
          </Box>
          <Card overflow="hidden" width="100%" mb="large">
            <Box textAlign="center" textStyle="sectionTitle" p="default">
              Inputs
            </Box>
            <Box borderRadius="default" overflow="hidden">
              <StyledTable>
                <thead>
                  <tr>
                    <th>Owner</th>
                    <th>Type</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {normalizedWorkshopDTOs &&
                    normalizedWorkshopDTOs.map(
                      ({ owner, workshopType, values, hasChanged, id }, index) => (
                        <Box
                          as="tr"
                          key={`${index} - ${id}`}
                          backgroundColor={hasChanged ? 'primary' : 'white'}
                          color={hasChanged ? 'white' : 'gray.0'}
                        >
                          <td>{owner && owner.firstName}</td>
                          <td>{workshopType}</td>
                          <td>
                            {values.map((value: any, idx: number) => (
                              <Box key={`${id}-${idx}`} marginTop={idx > 0 ? 'small' : 'none'}>
                                {value}
                              </Box>
                            ))}
                          </td>
                        </Box>
                      )
                    )}
                </tbody>
              </StyledTable>
            </Box>
          </Card>
        </Box>
      )}
    </>
  );
};

export default QScoreMetadata;
