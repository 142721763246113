import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { Card, styled, SelectInput2, Button } from '@qwealth/qcore';
import { loadQScore } from '@qwealth/qdata';
import { getHouseholdQID } from '../../../services/axiosService';
import { loadTimeline } from '../../../data/actions/timeline';
import QScoreMetadata from './QScoreMetadata';
import { getStartDate, buildOptions } from './TimelineHelper';
import RouteCalculator from './RouteCalculator';
import SimulationSelector from './SimulationSelector';

const ChartContainer = styled.div`
  canvas {
    width: 100% !important;
  }
`;

const dateFilters = {
  last_30_days: 'Last Month',
  YTD: 'YTD',
  last_3_years: 'Last 3 Years',
  last_5_years: 'Last 5 Years',
  all: 'ALL'
};

const typeMap = {
  Baseline: ['BASELINE'],
  Simulation: ['BASELINE', 'SIMULATION'],
  Advisor: ['BASELINE', 'GUARANTEED_INCOME_PROJECTION', 'MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION']
};

const mapToZeroFilter = (type) => (qScore) => {
  return qScore.type === type ? qScore : { qScore: 0 };
};

export default function Timeline({ type, title }) {
  const householdId = getHouseholdQID();
  const dispatch = useDispatch();

  const [range, setRange] = useState('last_30_days');
  const [visibleTypes, setVisibleTypes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [activeElements, setActiveElements] = useState([]);

  useEffect(() => {
    setVisibleTypes(typeMap[type]);
    setRange(type === 'Baseline' ? 'last_30_days' : 'all');
  }, [type]);

  const reload = useCallback(() => {
    const startDate = getStartDate(range);
    const endDate = range === 'all' ? null : moment();
    if (!_.isEmpty(visibleTypes)) {
      dispatch(loadTimeline(householdId, visibleTypes, startDate, endDate));
    }
    setSelected([]);
    setActiveElements([]);
  }, [dispatch, householdId, visibleTypes, range]);

  useEffect(() => {
    reload();
  }, [reload]);

  const timeline = useSelector((state) => state.timeline);
  const qscore = useSelector((state) => state.qscore);

  const dateList = timeline.map(({ calcDate }) => calcDate);

  const qScoreList = timeline.map(mapToZeroFilter('BASELINE'));
  const simulationList = timeline.map(mapToZeroFilter('SIMULATION'));
  const guaranteedIncomeProjections = timeline.map(mapToZeroFilter('GUARANTEED_INCOME_PROJECTION'));
  const maximumWithdrawalProjections = timeline.map(
    mapToZeroFilter('MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION')
  );

  const datasets = [
    {
      label: 'Baseline',
      backgroundColor: '#3772b8',
      data: qScoreList.map(({ qScore }) => qScore)
    }
  ];

  if (type === 'Simulation') {
    datasets.push({
      label: 'Simulation',
      backgroundColor: '#79B94E',
      data: simulationList.map(({ qScore }) => qScore)
    });
  }

  if (type === 'Advisor') {
    datasets.push({
      label: 'Optimal Guaranteed Income',
      backgroundColor: '#007bff',
      data: guaranteedIncomeProjections.map(({ qScore }) => qScore)
    });
    datasets.push({
      label: 'Maximum Annual Withdraw',
      backgroundColor: '#ffc107',
      data: maximumWithdrawalProjections.map(({ qScore }) => qScore)
    });
  }

  const data = {
    labels: dateList,
    datasets
  };

  const dataList = [qScoreList];
  if (type === 'Simulation') {
    dataList.push(simulationList);
  } else if (type === 'Advisor') {
    dataList.push(guaranteedIncomeProjections, maximumWithdrawalProjections);
  }

  const chartRef = useRef();

  useEffect(() => {
    if (!_.isEmpty(activeElements)) {
      activeElements.forEach((element) => {
        element._model.borderColor = '#00000050';
        element._model.borderWidth = 5;
      });
    }
  }, [activeElements]);

  const elementClickHandler = (elements) => {
    if (!_.isEmpty(elements)) {
      const index = elements[0]._index;
      const datasetIndex = elements[0]._datasetIndex;
      const dataset = dataList[datasetIndex];
      const archivedQScore = dataset[index];
      if (selected.includes(archivedQScore) || selected.length >= 2) {
        return;
      }

      setActiveElements([...activeElements, elements[0]]);

      setSelected([...selected, archivedQScore]);
    }
  };

  const clearHandler = (archivedQScore) => () => {
    setSelected(selected.filter((s) => s !== archivedQScore));
    setActiveElements(activeElements.filter((e) => e._model.label !== archivedQScore.calcDate));
  };

  const swapHandler = () => {
    setSelected(selected.slice().reverse());
    setActiveElements(activeElements.slice().reverse());
  };

  const qCalcHandler = () => {
    dispatch(loadQScore(householdId, qscore.simulationIds, true)).then(() => reload());
  };
  return (
    <Card
      title={title}
      mb="large"
      actions={
        <>
          {type === 'Baseline' && (
            <SelectInput2
              data={Object.keys(dateFilters)}
              toLabel={(r) => dateFilters[r]}
              onChange={(r) => setRange(r)}
              value={range}
            />
          )}

          {type === 'Simulation' && (
            <Button
              mb="default"
              onClick={qCalcHandler}
              disabled={qscore.simulationIds.length === 0 || qscore.isLoading}
            >
              {qscore.isLoading ? 'Calculating QRoute...' : 'Calculate QRoute'}
            </Button>
          )}
        </>
      }
    >
      {type === 'Simulation' && <SimulationSelector />}
      {type === 'Advisor' && <RouteCalculator householdId={householdId} reload={reload} />}
      <ChartContainer>
        <Bar
          ref={chartRef}
          data={data}
          options={buildOptions(setVisibleTypes, chartRef, dataList)}
          getElementAtEvent={elementClickHandler}
        />
      </ChartContainer>
      <QScoreMetadata
        selected={selected}
        clearHandler={clearHandler}
        reload={reload}
        swapHandler={swapHandler}
      />
    </Card>
  );
}

Timeline.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
