import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Box, styled } from '@qwealth/qcore';
import { Line } from 'react-chartjs-2';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';
import QScore from '../QScore';

const ChartContainer = styled.div`
  canvas {
    width: 100% !important;
  }
`;

export default function ProjectionReport() {
  const qscore = useSelector((state) => state.qscore);
  const { ninetyPercentileProjection, tenthPercentileProjection, simulationReportEntries } = qscore;

  const bestBalances = ninetyPercentileProjection.map(({ endingBalance }) => endingBalance);
  const mediaBalances = simulationReportEntries.map(({ endingBalance }) => endingBalance);
  const worstBalances = tenthPercentileProjection.map(({ endingBalance }) => endingBalance);
  const years = ninetyPercentileProjection.map(({ year }) => year);

  const data = {
    labels: years,
    datasets: [
      {
        label: '90th Percentile',
        backgroundColor: 'rgba(75,192,192,0.4)',
        data: bestBalances
      },
      {
        label: 'Median',
        backgroundColor: 'rgba(75,192,192,1)',
        data: mediaBalances
      },
      {
        label: '10th Percentile',
        backgroundColor: '#007bff',
        data: worstBalances
      }
    ]
  };

  const options = {
    legend: {
      position: 'bottom'
    },
    tooltips: {
      titleFontSize: 18,
      bodyFontSize: 18,
      callbacks: {
        label(tooltipItem) {
          return toCurrency(tooltipItem.yLabel, true);
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback(value) {
              return toCurrency(value, true);
            }
          }
        }
      ]
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        phone: '1fr',
        desktop: '1fr 350px'
      }}
      rowGap="default"
    >
      <Box
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <Card title="Projections">
          <ChartContainer>
            <Line data={data} options={options} />
          </ChartContainer>
        </Card>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <QScore />
      </Box>
    </Box>
  );
}
