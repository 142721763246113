import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Card, Table, toCurrency } from '@qwealth/qcore';
import QScore from '../../QScore';
import SectionTitle from '../../Common/SectionTitle';
import AssetAllocationDetails from './AssetAllocationDetails';
import OptionalColumnSelector from '../OptionalColumnSelector';
import { selectPrimary, selectSecondary } from '../../../data/selectors/householdSelectors';

const buildColumns = (primary, secondary, type, visibleColumns) => {
  return [
    {
      dataField: 'uuid',
      text: 'uuid',
      hidden: true
    },
    {
      dataField: 'year',
      text: 'Year',
      classes: 'text_bold'
    },
    {
      dataField: 'age',
      text: `${primary.firstName}'s Age`,
      hidden: !visibleColumns.includes('age')
    },
    {
      dataField: 'secondaryAge',
      text: `${secondary.firstName}'s Age`,
      hidden: !visibleColumns.includes('secondaryAge')
    },
    {
      dataField: 'beginningBalance',
      text: 'Beginning Balance',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'inflows',
      text: 'Inflows',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'outflows',
      text: 'Outflows',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'endingBalance',
      text: type === 'insuranceAccount' ? 'Death Benefit Amount' : 'Ending Balance',
      classes: 'text_bold',
      formatter: (cell) => toCurrency(cell, true)
    }
  ];
};

const buildOptionalColumnMap = (primary, secondary) => {
  const map = {};
  if (primary) {
    map.age = `${primary.firstName}'s Age`;
  }
  if (secondary) {
    map.secondaryAge = `${secondary.firstName}'s Age`;
  }
  return map;
};

export default function ChartOfAccount() {
  const primary = useSelector(selectPrimary);
  const secondary = useSelector(selectSecondary);

  const simulatedCOAList = useSelector((state) => state.qscore.simulatedCOAList);

  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [account, setAccount] = useState();
  const [chartOfAccountList, setChartOfAccountList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([]);

  const [type, ownerId] = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return [urlParams.get('type'), urlParams.get('ownerId')];
  }, []);

  useEffect(() => {
    let newTitle = '';
    let filterFn = () => false;

    if (!_.isEmpty(ownerId) && type === 'registeredAccountType') {
      const person = ownerId === primary.QID ? primary : secondary;
      if (person) {
        newTitle += `${person.firstName}'s `;
      }
      newTitle += 'Registered Accounts';
      filterFn = (COA) => COA.ownerId === ownerId && COA.registeredAccountType === true;
    }
    if (type === 'nonRegisteredAccountType') {
      newTitle = 'Combined Non-Registered Accounts';

      filterFn = (COA) => COA.nonRegisteredAccountType === true;
    }
    if (type === 'taxFreeAccountType') {
      newTitle = ' Tax-Free Accounts';

      filterFn = (COA) => COA.taxFreeAccountType === true;
    }
    if (type === 'bankingAccount') {
      newTitle = 'Banking Account';

      filterFn = (COA) => COA.bankingAccount === true;
    }
    if (type === 'insuranceAccount') {
      newTitle = 'Insurance Account';

      filterFn = (COA) => COA.insuranceAccount === true;
    }

    if (!_.isEmpty(simulatedCOAList)) {
      const COAList = simulatedCOAList.filter(filterFn);
      setChartOfAccountList(COAList);
      if (!_.isEmpty(COAList)) {
        setSelected(COAList[0].account.accountNumber);
        setData(COAList[0].entries);
      }
    }
    setTitle(newTitle);
  }, [primary, secondary, simulatedCOAList, type, ownerId]);

  useEffect(() => {
    const selectedChartOfAccounts = chartOfAccountList.find(
      (COA) => COA.account.accountNumber === selected
    );
    setData(selectedChartOfAccounts ? selectedChartOfAccounts.entries : []);
  }, [chartOfAccountList, selected]);

  useEffect(() => {
    const chartOfAccounts = chartOfAccountList.find(
      (COA) => COA.account.accountNumber === selected
    );
    setAccount(chartOfAccounts?.account);
  }, [chartOfAccountList, selected]);

  const columns = useMemo(() => buildColumns(primary, secondary, type, visibleColumns), [
    primary,
    secondary,
    type,
    visibleColumns
  ]);

  return (
    <Row>
      <Col lg={9} className="left-container">
        <Card shadow>
          <SectionTitle variant="secondary">{title}</SectionTitle>
          <select onChange={(e) => setSelected(e.target.value)}>
            {chartOfAccountList.map((COA) => (
              <option key={COA.account.accountNumber} value={COA.account.accountNumber}>
                {`${COA.account.accountTitle} - ${COA.account.institutionName} - ${COA.account.accountType}`}
              </option>
            ))}
          </select>
          <OptionalColumnSelector
            optionalColumnMap={buildOptionalColumnMap(primary, secondary)}
            setVisibleColumns={setVisibleColumns}
            visibleColumns={visibleColumns}
          />
          <AssetAllocationDetails account={account} />
          <Table fixedHeader tableHeight="700px" columns={columns} data={data} />
        </Card>
      </Col>
      <Col className="mb-3" lg={3}>
        <QScore />
      </Col>
    </Row>
  );
}
