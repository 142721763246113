import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { defaultTheme, ThemeProvider } from '@qwealth/qcore';
import { MsalProvider } from '@azure/msal-react';

import App from './App';
import store from './data/store';
import { msalInstance } from './services/msalService';

import './index.css';
import './App.scss';
import { QContext } from "@qwealth/qdata";
import { errorHandler} from "./services/axiosService";

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: defaultTheme.colors.blue,
    primaryTransparent: defaultTheme.colors.blueTransparent
  }
};

const { REACT_APP_VERSION } = process.env;

console.log(`Current app version is: ${REACT_APP_VERSION}`);

QContext.setErrorHandler(errorHandler);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
