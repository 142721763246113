const getAnnualMultiplier = (frequency) => {
  let multiplier;
  switch (frequency) {
    case 'Monthly':
      multiplier = 12;
      break;
    case 'Bi-Monthly':
      multiplier = 6;
      break;
    default:
      multiplier = 1;
      break;
  }
  return multiplier;
};

const getYearMultiplier = (frequency) => {
  let multiplier;
  switch (frequency) {
    case 'Every 2 years':
      multiplier = 2;
      break;
    case 'Every 3 years':
      multiplier = 3;
      break;
    case 'Every 4 years':
      multiplier = 4;
      break;
    case 'Every 5 years':
      multiplier = 5;
      break;
    case 'Every 10 years':
      multiplier = 10;
      break;
    default:
      multiplier = 1;
      break;
  }

  return multiplier;
};

export const annualFlowValue = (goal, ageMap, retirementMap) => {
  const { attendeeQID, frequency, amount } = goal;
  let { startAge, endAge } = goal;

  if (frequency === 'One Time Event') {
    return amount;
  }

  if (startAge === 'Retirement') {
    startAge = retirementMap[attendeeQID] || 65;
  } else if (startAge === 'Now') {
    startAge = ageMap[attendeeQID];
  }

  if (endAge === 'Death') {
    endAge = 95;
  }

  let total = 0;
  const yearMultiplier = getYearMultiplier(frequency);
  const annualMultiplier = getAnnualMultiplier(frequency);

  startAge = parseInt(startAge, 10);
  endAge = parseInt(endAge, 10);

  for (let i = startAge; i <= endAge; i += yearMultiplier) {
    total += amount * annualMultiplier;
  }

  return total;
};
