import React, { useState } from 'react';
import { Box, SelectInput } from '@qwealth/qcore';

export default function DepositOverride() {
  const [depositTarget, setDepositTarget] = useState('Non-Registered');
  return (
    <Box>
      <span>If annual surplus is generated, it will be deposited to</span>
      <SelectInput onChange={(event) => setDepositTarget(event.target.value)} mx="small">
        <option>Non-Registered</option>
        <option>Banking</option>
      </SelectInput>
      <span>
        {depositTarget === 'Non-Registered' ? 'for reinvestment.' : 'for extra spending.'}
      </span>
    </Box>
  );
}
