import React from 'react';
import { BsTrash } from 'react-icons/bs';
// @ts-ignore
import { Type } from 'react-bootstrap-table2-editor';
import { Button, toCurrency } from '@qwealth/qcore';
import { IGuaranteedRetirementDto } from '@qwealth/qdata';
import { range, startCase } from 'lodash';

export type EditableGuaranteedRetirementDto = IGuaranteedRetirementDto & {
  isDirty?: boolean;
  isDeleted?: boolean;
  isNew?: boolean;
};

type RowHandler = (row: EditableGuaranteedRetirementDto) => void;

export type Option = {
  value: string;
  label: string;
}

const fixedTypes = ['pension', 'rentalPropertyIncome', 'trustIncome', 'otherIncome'];
export const getGriTypes = (currentTypes: Array<string>): Array<Option> => {
  let types: Array<string> = [];
  if (!currentTypes.includes('CPP')) {
    types = types.concat(['CPP']);
  }
  if (!currentTypes.includes('OAS')) {
    types = types.concat(['OAS']);
  }
  types = types.concat(fixedTypes);
  return types.map(t => ({ value: t, label: startCase(t) }));
};

export const buildColumns = (
  deleteHandler: RowHandler,
  typeOptions: Array<Option>,
  age: number,
) => [{
  dataField: 'id',
  text: 'id',
  hidden: true
}, {
  dataField: 'griType',
  text: 'Type',
  editor: {
    type: Type.SELECT,
    options: typeOptions
  },
  formatter: (type: string) => startCase(type)
}, {
  dataField: 'amount',
  text: 'Amount',
  formatter: (amount: number) => toCurrency(amount, true)
}, {
  dataField: 'startAge',
  text: 'Start',
  editor: {
    type: Type.SELECT,
    options: ['Retirement']
      .concat(range(age, 100).map(a => a + ''))
      .map(a => ({ value: a, label: a}))
  },
}, {
  dataField: 'endAge',
  text: 'End',
  editor: {
    type: Type.SELECT,
    options: ['Retirement', 'Death']
      .concat(range(age, 100).map(a => a + ''))
      .map(a => ({ value: a, label: a}))
  },
}, {
  dataField: 'spousalBenefit',
  text: 'Spousal Benefit',
  editable: (spousalBenefit: number | undefined, row: EditableGuaranteedRetirementDto) => {
    return !['CPP', 'OAS'].includes(row.griType);
  },
  formatter: (spousalBenefit: number | undefined, row: EditableGuaranteedRetirementDto) => {
    return ['CPP', 'OAS'].includes(row.griType) ? 'N/A' : spousalBenefit;
  }
}, {
  dataField: 'delete',
  text: '',
  classes: 'delete-cell',
  editable: false,
  formatter: () => (
    <Button
      color="gray.1"
      variant="icon"
      size="small"
      ml="small"
      onClick={() => {}}
    >
      <BsTrash />
    </Button>
  ),
  events: {
    onClick: (e: any, column: any, columnIndex: number, row: EditableGuaranteedRetirementDto) => {
      e.preventDefault();
      deleteHandler(row);
    }
  }
}];
