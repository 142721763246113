import { compose } from 'ramda';
import { createSelector } from 'reselect';

export const selectHouseholdMembers = (state) => state.household.members;
export const selectHouseholdId = (state) => state.household.householdId;
export const selectHouseholdMemberIds = ({ household }) =>
  household.initialized ? household.members.map(({ QID }) => QID) : [];

const findByType = (type) => (members) =>
  members.find(({ memberType }) => memberType === type) || {};

export const selectPrimary = compose(findByType('Primary'), selectHouseholdMembers);

export const selectSecondary = createSelector(selectHouseholdMembers, findByType('Secondary'));
