import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { GoalScenario, IGoalWorkshopDto, RouteType, saveWorkshop } from '@qwealth/qdata';
import { Box, SelectInput2 } from '@qwealth/qcore';
import RetirementExpenseElimination from './GuaranteedRetirementIncome/ExpenseElimination';

interface Props {
  setShow: Function;
  show: boolean;
  goal?: IGoalWorkshopDto;
}

const Editor: React.FC<Props> = ({ goal, show, setShow }) => {
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  const [retirementSpendingPercentage, setRetirementSpendingPercentage] = useState<number>();
  const [retirementSpendingEstimateExpense, setRetirementSpendingEstimateExpense] = useState<
    Array<GoalScenario>
  >();

  useEffect(() => {
    if (goal && !retirementSpendingPercentage) {
      setRetirementSpendingPercentage(goal.retirementSpendingPercentage);
    }
    if (goal && !retirementSpendingEstimateExpense) {
      setRetirementSpendingEstimateExpense(goal.retirementSpendingEstimateExpense);
    }
  }, [goal, retirementSpendingEstimateExpense, retirementSpendingPercentage]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Retirement Spending</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <Box textStyle="formLabel">% of Current Spending in Retirement*</Box>
          <SelectInput2
            value={retirementSpendingPercentage}
            toLabel={(v) => `${v}%`}
            data={[120, 110, 100, 90, 80, 75, 65]}
            // @ts-ignore
            onChange={(v: string) => {
              setRetirementSpendingPercentage(Number.parseInt(v, 10));
            }}
          />
        </Box>

        <Form.Group controlId="eliminatedSpending">
          <Form.Label>Expenses Eliminated in Retirement</Form.Label>
          <RetirementExpenseElimination
            // @ts-ignore
            workshopID={goal && goal.workshopID}
            // @ts-ignore
            retirementSpendingEstimateExpense={retirementSpendingEstimateExpense}
            setRetirementSpendingEstimateExpense={setRetirementSpendingEstimateExpense}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            const payload = {
              ..._.omit(goal, 'parent'),
              retirementSpendingPercentage,
              retirementSpendingEstimateExpense
            };
            // @ts-ignore
            dispatch(saveWorkshop(payload, RouteType.Goal, goal.attendeeQID, goal.parent));
            handleClose();
          }}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Editor;
