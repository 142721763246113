import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGoalWorkshopDto, loadCreateWorkshop, saveWorkshop } from '@qwealth/qdata';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import { selectPrimary } from 'data/selectors/householdSelectors';

interface Props {
  show: boolean;
  setShow: Function;
  goal?: IGoalWorkshopDto;
}

const Editor: React.FC<Props> = ({ show, setShow, goal }) => {
  const primary = useSelector(selectPrimary);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);

  const [legacyIncludeHome, setLegacyIncludeHome] = useState(0);
  const [legacyEndValue, setLegacyEndValue] = useState(0);

  useEffect(() => {
    if (goal) {
      setLegacyIncludeHome(goal.legacyIncludeHome || 0);
      setLegacyEndValue(goal.legacyEndValue || 0);
    }
  }, [goal]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Legacy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group controlId="includePersonalHomeValue">
              <Form.Check
                type="switch"
                id="includePersonalHomeValue"
                label="Include Personal Home Value?*"
                checked={legacyIncludeHome === 1}
                onChange={(e) => setLegacyIncludeHome(e.target.checked ? 1 : 0)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="estateValue">
              <Form.Label>Ending Value of Estate*</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Value"
                value={legacyEndValue}
                onChange={(event) => setLegacyEndValue(parseInt(event.target.value, 10))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={async () => {
            const ownerId = goal ? goal.attendeeQID : primary.QID;
            const parent = goal && goal.parent ? goal.parent : 'Protection';
            const workshopID = await dispatch(loadCreateWorkshop(ownerId, parent));
            // @ts-ignore
            let payload: IGoalWorkshopDto = {
              legacyEndValue,
              legacyIncludeHome,
              goalType: 'Legacy: End Value'
            };
            if (goal) {
              payload = { ..._.omit(goal, 'parent'), ...payload };
            } else {
              // @ts-ignore
              payload = { ...payload, workshopID };
            }

            await dispatch(saveWorkshop(payload, 'Goal', ownerId, parent));
            handleClose();
          }}
        >
          Save
        </Button>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Editor;
