import React from 'react';
import { Button } from '@qwealth/qcore';
import { BsPencil } from 'react-icons/bs';

export default function EditButton(props) {
  const { disabled, onClickHandler } = props;
  return (
    <Button
      color="primary"
      size="small"
      ml="small"
      height="100%"
      variant="icon"
      onClick={!disabled ? onClickHandler : () => {}}
    >
      <BsPencil />
    </Button>
  );
}
