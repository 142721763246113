import React from 'react';
import * as PropTypes from 'prop-types';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { toCurrency } from '@qwealth/qcore';
import EditableTable from '../../../Common/EditableTable';

export default function ExpenseElimination({
  workshopID,
  retirementSpendingEstimateExpense,
  setRetirementSpendingEstimateExpense
}) {
  const columns = [
    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'expense',
      text: 'Expense',
      sort: true,
      editor: {
        type: Type.TEXT
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      editor: {
        type: Type.TEXTAREA
      }
    },
    {
      dataField: 'value',
      text: 'Value',
      formatter: (cell) => toCurrency(cell),
      sort: true,
      editor: {
        type: Type.TEXT
      }
    }
  ];

  const addScenarioHandler = () => {
    const scenario = {
      isDirty: true,
      workshopID,
      expense: '',
      description: '',
      value: 0
    };
    setRetirementSpendingEstimateExpense([...retirementSpendingEstimateExpense, scenario]);
  };

  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row) => {
      setRetirementSpendingEstimateExpense(
        retirementSpendingEstimateExpense.map((s) =>
          s.id === row.id ? { ...row, isDirty: true } : s
        )
      );
    }
  });

  return (
    <EditableTable
      addHandler={addScenarioHandler}
      deleteHandler={(row) =>
        setRetirementSpendingEstimateExpense(
          retirementSpendingEstimateExpense.filter((r) => r.id !== row.id)
        )
      }
      addText="Add Scenario"
      cellEdit={cellEdit}
      data={retirementSpendingEstimateExpense || []}
      columns={columns}
      keyField="id"
    />
  );
}

ExpenseElimination.propTypes = {
  workshopID: PropTypes.string.isRequired,
  setRetirementSpendingEstimateExpense: PropTypes.func.isRequired,
  retirementSpendingEstimateExpense: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
