import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center',
    fontWeight: 'medium',
    lineHeight: '110%'
  },
  SubText1: {
    fontSize: 10,
    paddingHorizontal: 60,
    textAlign: 'center'
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  SubText2: {
    fontSize: 10,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold'
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginVertical: 20,
    paddingHorizontal: 10
  },
  BannerImage: {
    height: 200
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
    height: 32
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 32,
    color: COLORS.WHITE,
    fontWeight: 'bold'
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 32,
    paddingHorizontal: 15,
    fontSize: 12,
    fontWeight: 'bold'
  },
  BoldFont: {
    fontWeight: 'bold'
  }
});

const list = [
  {
    text: 'Defining YOUR DESIRED OUTCOMES'
  },
  {
    text: 'Identifying the assets that you have accumulated to date'
  },
  {
    text: 'Identifying what you plan to save between now and retirement'
  },
  {
    text: 'Identifying what you plan to spend between now and retirement'
  },
  {
    text: 'Identifying how your assets are invested and what you should expect in growth'
  },
  {
    text: 'Documenting what you would like to leave behind after you are gone'
  }
];

const ReportOverview = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>Journey Based Planning</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View style={{ paddingHorizontal: 20 }}>
      <Text style={[styles.MainText1, { paddingBottom: 0 }]}>
        <Text style={styles.BoldFont}>‘Journey Based Planning’</Text> is the process of mapping out
        your lifetime <Text style={styles.BoldFont}>‘Journey’</Text>.
      </Text>
      <Text style={[styles.MainText1, { paddingTop: 0 }]}>
        In this QScore phase, we take the <Text style={styles.BoldFont}>‘Desired Outcomes’</Text>{' '}
        that you have previously provided and convert those{' '}
        <Text style={styles.BoldFont}>‘Outcomes’</Text> into mathematical calculations. We then
        combine those calculations into one numeric score between 1 - 100. Your{' '}
        <Text style={styles.BoldFont}>‘QScore’</Text>!
      </Text>
      <Text style={styles.SubText1}>
        Unlike taking an exam, this is one time you <Text style={styles.BoldFont}>DO NOT</Text> want
        the highest score possible. Ideally, you want a score between 80-85. To calculate your{' '}
        <Text style={styles.BoldFont}>‘QScore’</Text> we need to document the following data points:
      </Text>

      <View style={styles.Content}>
        <View style={styles.TextPanel}>
          {list.map((rowData, index) => (
            <View style={styles.Row} key={`no-${index}`}>
              <View
                style={[
                  styles.NumberColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY }
                ]}
              >
                <Text>{index + 1}</Text>
              </View>
              <View
                style={[
                  styles.TextColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 }
                ]}
              >
                <Text>{rowData.text}</Text>
              </View>
            </View>
          ))}
        </View>
        <Image style={styles.BannerImage} src="/assets/qw-qscore-pdf-img1.jpeg" />
      </View>

      <Text style={styles.SubText2}>
        This document is <Text style={styles.HighLight1}>Step Two</Text> in the creation of your{' '}
        <Text style={styles.HighLight1}>Journey Based Plan</Text>. By the end of this document, you
        will have a fundamental understanding of the question:
      </Text>
      <Text style={styles.MainText2}>Can I live the life I Choose?</Text>
    </View>
  </Page>
);

export default ReportOverview;
