export default function buildUrl(params) {
  const urlParams = new URLSearchParams(window.location.search);
  if (params) {
    const { type, ownerId } = params;
    if (type) {
      urlParams.set('type', type);
    }
    if (ownerId) {
      urlParams.set('ownerId', ownerId);
    }
  }
  return urlParams.toString();
}
