import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  }
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

const ReportHistoricalQScore = ({ data }: { data: AggregatedQScoreData }) => {
  const chartQScoreImg = useChartJsAsImage(
    crateChartJsConfig('bar', data.chartQScore.labels, data.chartQScore.datasets)
  );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>HISTORICAL QSCORE</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30,
          paddingTop: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          This report shows the change in your selected QScores over time. It is valuable to see how
          changes in the plan or actual market returns (and the order in which they have occurred)
          have affected the QScore. In theory, if you have no changes to your plan and you achieve
          the exact returns as projected, your QScore will never change. Obviously, that is not
          realistic. What is important to remember is that you want your QScores to remain within an
          acceptable range, (ideally between <Text style={styles.BoldFont}>80-85</Text>).
        </Text>
        {chartQScoreImg}
      </View>
    </Page>
  );
};

export default ReportHistoricalQScore;
