import React from 'react';
import { Box } from '@qwealth/qcore';

export default function SectionTitle(props) {
  const variants = {
    primary: {
      color: 'primary',
      backgroundColor: 'primaryTransparent'
    },
    secondary: {
      color: 'gray.0',
      backgroundColor: 'gray.4'
    }
  };

  const variant =
    props.variant && variants[props.variant] ? variants[props.variant] : variants['primary'];

  return (
    <Box
      as="h6"
      color={variant.color}
      backgroundColor={variant.backgroundColor}
      borderRadius="default"
      textStyle="sectionTitle"
      mb="large"
      px="large"
      py="default"
    >
      {props.children}
    </Box>
  );
}
