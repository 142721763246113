import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Box, styled } from '@qwealth/qcore';
import { Bar } from 'react-chartjs-2';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';
import QScore from '../../QScore';

const ChartContainer = styled.div`
  canvas {
    width: 100% !important;
  }
`;

const options = {
  legend: {
    position: 'bottom'
  },
  tooltips: {
    titleFontSize: 18,
    bodyFontSize: 18,
    callbacks: {
      label({ yLabel, datasetIndex }, data) {
        return `${data.datasets[datasetIndex].label}: ${toCurrency(yLabel, true)}`;
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback(value) {
            return toCurrency(value, true);
          }
        }
      }
    ]
  }
};

export default function SimGraph() {
  const qscore = useSelector((state) => state.qscore);
  const { simulationReportEntries } = qscore;

  const grossBalances = simulationReportEntries.map(
    ({ endingBalance, endingInsuranceBalance }) => endingBalance - endingInsuranceBalance
  );
  const netTaxBalances = simulationReportEntries.map(
    ({ netTaxEndingBalance, endingInsuranceBalance }) =>
      netTaxEndingBalance - endingInsuranceBalance
  );
  const years = simulationReportEntries.map(({ year }) => year);

  const data = {
    labels: years,
    datasets: [
      {
        label: 'Total Assets (Gross)',
        backgroundColor: '#3772b8',
        data: grossBalances
      },
      {
        label: 'Total Assets (Net Taxes)',
        backgroundColor: '#007bff',
        data: netTaxBalances
      }
    ]
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        phone: '1fr',
        desktop: '1fr 350px'
      }}
      rowGap="default"
    >
      <Box
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <Card title="Retirement Assets Report">
          <ChartContainer>
            <Bar data={data} options={options} />
          </ChartContainer>
        </Card>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <QScore />
      </Box>
    </Box>
  );
}
