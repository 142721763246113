import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Button } from '@qwealth/qcore';
import { IGoalWorkshopDto, RouteType, saveWorkshop } from '@qwealth/qdata';
import OwnerLabel from '../../Common/OwnerLabel';

export interface Owner {
  name: string;
  type: 'primary' | 'secondary';
}

interface Props {
  goal?: IGoalWorkshopDto;
  setGoal: Function;
  owner: Owner;
}

const Editor: React.FC<Props> = ({ goal, setGoal, owner }) => {
  const dispatch = useDispatch();
  const handleClose = () => setGoal(null);

  const [retirementAgeValue, setRetirementAge] = useState<number>(65);
  useEffect(() => {
    if (goal && goal.retirementAgeValue) {
      setRetirementAge(goal.retirementAgeValue);
    }
  }, [goal]);

  return (
    <Modal show={!!goal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Retirement Age - <OwnerLabel type={owner && owner.type}>{owner && owner.name}</OwnerLabel>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="retirementAge">
          <Form.Label>Age at Retirement*</Form.Label>
          <Form.Control
            required
            type="number"
            placeholder="Value"
            value={retirementAgeValue}
            onChange={(event) => setRetirementAge(parseInt(event.target.value, 10))}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="default"
          onClick={() => {
            const payload = { ..._.omit(goal, 'parent'), retirementAgeValue };
            // @ts-ignore
            dispatch(saveWorkshop(payload, RouteType.Goal, goal.attendeeQID, goal.parent));
            handleClose();
          }}
        >
          Save
        </Button>
        <Button variant="outline" color="gray.2" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Editor;
