import { createMsalInstance } from '@qwealth/qcore';

const {
  REACT_APP_QWEALTH_MICROSOFT_APPLICATION_ID,
  REACT_APP_QWEALTH_MICROSOFT_AUTHORITY
} = process.env;

export const scopes = ['calendars.readwrite', 'mailboxSettings.read', 'user.read.all', 'user.read'];

export const msalInstance = createMsalInstance(
  {
    auth: {
      authority: REACT_APP_QWEALTH_MICROSOFT_AUTHORITY,
      clientId: REACT_APP_QWEALTH_MICROSOFT_APPLICATION_ID || ''
    }
  },
  { scopes }
);
