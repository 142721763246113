import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Box, Table, SelectInput2 } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';
import { hasColumnData } from 'utils/DataUtil';
import QScore from '../../QScore';
import buildUrl from '../helper';
import { selectPrimary, selectSecondary } from '../../../data/selectors/householdSelectors';

const buildColumns = (primary, secondary, data, isLegacy, push) => {
  return [
    {
      dataField: 'uuid',
      text: 'uuid',
      hidden: true
    },
    {
      dataField: 'year',
      text: 'Year',
      classes: 'text_bold'
    },
    {
      dataField: 'age',
      text: `${primary.firstName}'s Age`
    },
    {
      dataField: 'secondaryAge',
      text: `${secondary.firstName}'s Age`
    },
    {
      dataField: 'endingRegisteredBalance',
      text: `${primary.firstName}'s RRSP`,
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('endingRegisteredBalance', data),
      headerClasses: 'linked-col',
      highlightable: true,
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'registeredAccountType', ownerId: primary && primary.QID })
          });
        }
      }
    },
    {
      dataField: 'endingSecondaryRegisteredBalance',
      text: `${secondary.firstName}'s RRSP`,
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('endingSecondaryRegisteredBalance', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({
              type: 'registeredAccountType',
              ownerId: secondary && secondary.QID
            })
          });
        }
      }
    },
    {
      dataField: 'endingNonRegisteredBalance',
      text: 'Non-Registered Balance',
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('endingNonRegisteredBalance', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'nonRegisteredAccountType' })
          });
        }
      }
    },
    {
      dataField: 'endingTaxFreeBalance',
      text: 'Tax Free Balance',
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('endingTaxFreeBalance', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'taxFreeAccountType' })
          });
        }
      }
    },
    {
      dataField: 'endingBankingBalance',
      text: 'Banking Balance',
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('endingBankingBalance', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'bankingAccount' })
          });
        }
      }
    },
    {
      dataField: 'endingInsuranceBalance',
      hidden: !isLegacy || !hasColumnData('endingInsuranceBalance', data),
      text: 'Insurance Balance',
      formatter: (cell) => toCurrency(cell, true),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'insuranceAccount' })
          });
        }
      }
    },
    {
      dataField: 'endingBalance',
      text: isLegacy ? 'Legacy Value' : 'Total Retirement Assets',
      formatter: (cell, row) => {
        let totalBalance = cell;
        if (!isLegacy) {
          totalBalance -= row.endingInsuranceBalance;
        }
        return toCurrency(totalBalance, true);
      },
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/simGraph',
            search: buildUrl()
          });
        }
      }
    }
  ];
};

export default function MasterSimReport() {
  const data = useSelector((state) => state.qscore.simulationReportEntries);
  const primary = useSelector(selectPrimary);
  const secondary = useSelector(selectSecondary);

  const [isLegacy, setLegacy] = useState(false);

  const { push } = useHistory();
  const columns = useMemo(() => buildColumns(primary, secondary, data, isLegacy, push), [
    primary,
    secondary,
    data,
    isLegacy,
    push
  ]);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        phone: '1fr',
        desktop: '1fr 350px'
      }}
      rowGap="default"
    >
      <Box
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <Card
          title={isLegacy ? 'Legacy Value' : 'Retirement Assets Accumulation Value'}
          shadow
          actions={
            <SelectInput2
              data={['Retirement Assets Report', 'Legacy Report']}
              onChange={() => setLegacy(!isLegacy)}
            />
          }
        >
          <Table fixedHeader tableHeight="700px" columns={columns} data={data} />
        </Card>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <QScore />
      </Box>
    </Box>
  );
}
