import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import type { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { getScoreTitle, getScoreMeaning } from '../../../../../utils/DataUtil';

const styles = StyleSheet.create({
  Content: {
    padding: 30
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Column: {
    flex: 1,
    height: 200
  }
});

export interface ReportQScoreProps {
  data: AggregatedQScoreData | null;
}

const ReportYourQScore = ({ data }: ReportQScoreProps) => {
  const title = getScoreTitle(data?.qScore);
  const scoreMeaning = getScoreMeaning(data?.qScore);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>YOUR QSCORE</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={styles.Content}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'medium',
            lineHeight: '130%',
            color: COLORS.PRIMARY,
            textAlign: 'center',
            marginBottom: 20
          }}
        >
          We have now taken your <Text style={styles.BoldFont}>Baseline Assumptions</Text> and
          applied our <Text style={styles.BoldFont}>Expected Rates of Return</Text> along with the{' '}
          <Text style={styles.BoldFont}>Standard Deviation</Text>. We then ran 1,000{' '}
          <Text style={styles.BoldFont}>Simulations</Text>. The number below is tells us the
          percentage of those simulations that were successful.
        </Text>

        <Text style={{ fontSize: 16, color: COLORS.BLACK, textAlign: 'center', marginBottom: 40 }}>
          Again, success is determined by you achieving{' '}
          <Text style={styles.BoldFont}>ALL your Desired Outcomes</Text> as stated in your Baseline
          Assumptions and leaving behind the amount you intended at your death.
        </Text>

        <View style={[styles.Row, { paddingHorizontal: 30 }]}>
          <View style={[styles.Column, { backgroundColor: COLORS.PRIMARY }]}>
            <Text
              style={{
                color: COLORS.WHITE,
                fontWeight: 'bold',
                fontSize: 76,
                textAlign: 'center',
                marginTop: 20
              }}
            >
              {data && data.qScore}
            </Text>
            <View style={[styles.Row, { paddingHorizontal: 20, marginTop: 30 }]}>
              <View
                style={{
                  flex: 1,
                  height: 15,
                  backgroundColor: '#D54441',
                  marginRight: 5,
                  position: 'relative'
                }}
              >
                <Text
                  style={{
                    color: COLORS.WHITE,
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'right'
                  }}
                >
                  75
                </Text>
                {data && data.qScore < 75 && (
                  <View
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      top: -5,
                      width: 25,
                      height: 25,
                      left: `${(data.qScore / 75) * 100 - 10}%`,
                      borderRadius: '50%',
                      borderColor: '#D54441',
                      borderWidth: 3,
                      backgroundColor: COLORS.WHITE
                    }}
                  ></View>
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  height: 15,
                  backgroundColor: '#6FB956',
                  marginHorizontal: 5,
                  position: 'relative'
                }}
              >
                <Text
                  style={{
                    color: COLORS.WHITE,
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'center'
                  }}
                >
                  75-90
                </Text>
                {data && data.qScore >= 75 && data.qScore < 90 && (
                  <View
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      top: -5,
                      width: 25,
                      height: 25,
                      left: `${((data.qScore - 75) / 15) * 100 - 10}%`,
                      borderRadius: '50%',
                      borderColor: '#6FB956',
                      borderWidth: 3,
                      backgroundColor: COLORS.WHITE
                    }}
                  ></View>
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  height: 15,
                  backgroundColor: '#D99C52',
                  marginLeft: 5,
                  position: 'relative'
                }}
              >
                <Text
                  style={{
                    color: COLORS.WHITE,
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'left'
                  }}
                >
                  90
                </Text>
                {data && data.qScore >= 90 && (
                  <View
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      top: -5,
                      width: 25,
                      height: 25,
                      left: `${((data.qScore - 90) / 10) * 100 - 10}%`,
                      borderRadius: '50%',
                      borderColor: '#D99C52',
                      borderWidth: 3,
                      backgroundColor: COLORS.WHITE
                    }}
                  ></View>
                )}
              </View>
            </View>
          </View>
          <View
            style={[
              styles.Column,
              {
                backgroundColor: COLORS.GRAY1,
                padding: 15,
                display: 'flex',
                justifyContent: 'center'
              }
            ]}
          >
            <Text
              style={{
                color: COLORS.PRIMARY,
                fontSize: 16,
                fontWeight: 'bold',
                textTransform: 'uppercase'
              }}
            >
              {title}
            </Text>
            <Text style={{ color: COLORS.PRIMARY_LIGHT, fontSize: 18, marginTop: 5 }}>
              {scoreMeaning.split('"')[1]}
            </Text>
            <Text style={{ color: COLORS.BLACK, fontSize: 12, marginTop: 20 }}>
              {scoreMeaning.split('"')[2]}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportYourQScore;
