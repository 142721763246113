import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';
import moment from 'moment';

import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Column: {
    flex: 1,
    height: 200
  },
  TableHeader: {
    fontSize: 10,
    color: COLORS.PRIMARY,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  TableRow: {
    paddingVertical: 5,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY1
  },
  TableColumn: {
    fontSize: 10
  }
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

const renderScoreBox = (date: string, score: number) => (
  <View style={[styles.Column, { backgroundColor: COLORS.PRIMARY }]}>
    <Text
      style={{
        color: COLORS.WHITE,
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20
      }}
    >
      {date}
    </Text>
    <Text
      style={{
        color: COLORS.WHITE,
        fontWeight: 'bold',
        fontSize: 76,
        textAlign: 'center',
        marginTop: 5
      }}
    >
      {score}
    </Text>
    <View style={[styles.Row, { paddingHorizontal: 20, marginTop: 10 }]}>
      <View
        style={{
          flex: 1,
          height: 15,
          backgroundColor: '#D54441',
          marginRight: 5,
          position: 'relative'
        }}
      >
        <Text
          style={{
            color: COLORS.WHITE,
            marginTop: 20,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'right'
          }}
        >
          {`<75`}
        </Text>
        {score < 75 && (
          <View
            style={{
              position: 'absolute',
              bottom: -5,
              top: -5,
              width: 25,
              height: 25,
              left: `${(score / 75) * 100 - 10}%`,
              borderRadius: '50%',
              borderColor: '#D54441',
              borderWidth: 3,
              backgroundColor: COLORS.WHITE
            }}
          ></View>
        )}
      </View>
      <View
        style={{
          flex: 1,
          height: 15,
          backgroundColor: '#6FB956',
          marginHorizontal: 5,
          position: 'relative'
        }}
      >
        <Text
          style={{
            color: COLORS.WHITE,
            marginTop: 20,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center'
          }}
        >
          75-90
        </Text>
        {score >= 75 && score < 90 && (
          <View
            style={{
              position: 'absolute',
              bottom: -5,
              top: -5,
              width: 25,
              height: 25,
              left: `${((score - 75) / 15) * 100 - 10}%`,
              borderRadius: '50%',
              borderColor: '#6FB956',
              borderWidth: 3,
              backgroundColor: COLORS.WHITE
            }}
          ></View>
        )}
      </View>
      <View
        style={{
          flex: 1,
          height: 15,
          backgroundColor: '#D99C52',
          marginLeft: 5,
          position: 'relative'
        }}
      >
        <Text
          style={{
            color: COLORS.WHITE,
            marginTop: 20,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'left'
          }}
        >
          {`>90`}
        </Text>
        {score >= 90 && (
          <View
            style={{
              position: 'absolute',
              bottom: -5,
              top: -5,
              width: 25,
              height: 25,
              left: `${((score - 90) / 10) * 100 - 10}%`,
              borderRadius: '50%',
              borderColor: '#D99C52',
              borderWidth: 3,
              backgroundColor: COLORS.WHITE
            }}
          ></View>
        )}
      </View>
    </View>
  </View>
);

const ReportHistoricalQScoreComparison = ({ data }: { data: AggregatedQScoreData }) => {
  // @ts-ignore
  const { chartQScoreComparison } = data;
  const { labels, datasets, workshopDTOs } = chartQScoreComparison;
  const qScoreComaprisionData = datasets[0].data;
  const chartQScoreComparisonImg = useChartJsAsImage(crateChartJsConfig('bar', labels, datasets));

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={[commonStyles.Header, { marginBottom: 30 }]} fixed>
        <Text style={commonStyles.HeaderTitle}>HISTORICAL QSCORE COMPARISON</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 30 }]}>
          This report allows us to compare one Historical QScore vs. another and see what
          assumptions have changed. By evaluating the assumption changes against one another, it
          allows us to see the implications, both positive and negative, to the QScore. What is
          important to remember though, is that a positive change brings your score to between
          80-85. As an example, moving your QScore from 95 to 85 is positive, particularly if it
          means you are retired 5 years earlier, or are able to spend an additional $10,000 in
          retirement, or have reduced the amount of risk (volatility) in your portfolio. Similarly,
          a positive change would be moving your QScore from 75 to 82, if the change was you
          increased your savings pre-retirement.
        </Text>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {renderScoreBox(
            moment(labels[0]).format('ddd MMM D YYYY'),
            qScoreComaprisionData! && (qScoreComaprisionData[0] as number)
          )}
          <View style={{ flex: 1, marginHorizontal: 10, marginVertical: 'auto' }}>
            {chartQScoreComparisonImg}
          </View>
          {renderScoreBox(
            moment(labels[1]).format('ddd MMM D YYYY'),
            qScoreComaprisionData! && (qScoreComaprisionData[1] as number)
          )}
        </View>
        <View style={{ marginTop: 30 }}>
          <View style={[styles.Row, styles.TableRow, { paddingVertical: 5 }]} fixed>
            <View style={{ width: 100 }}>
              <Text style={styles.TableHeader}>OWNER</Text>
            </View>
            <View style={{ width: 200 }}>
              <Text style={styles.TableHeader}>TYPE</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.TableHeader}>VALUE</Text>
            </View>
          </View>
          {workshopDTOs &&
            workshopDTOs.map(({ id, owner, workshopType, values, hasChanged }, index) => (
              <View
                style={[
                  styles.Row,
                  styles.TableRow,
                  { backgroundColor: hasChanged ? '#3771b8' : '#ffffff' }
                ]}
                key={`${index} - ${id}`}
              >
                <View style={{ width: 100 }}>
                  <Text style={[styles.TableColumn, { color: hasChanged ? '#ffffff' : '#45404d' }]}>
                    {owner && owner.firstName}
                  </Text>
                </View>
                <View style={{ width: 200 }}>
                  <Text style={[styles.TableColumn, { color: hasChanged ? '#ffffff' : '#45404d' }]}>
                    {workshopType}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  {values.map((value: any, idx: number) => (
                    <Text
                      key={`${id}-${idx}`}
                      style={[styles.TableColumn, { color: hasChanged ? '#ffffff' : '#45404d' }]}
                    >
                      {value}
                    </Text>
                  ))}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportHistoricalQScoreComparison;
