import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  Box,
  AccordionSection,
  getAnnualSavings,
  useDispatchOnMount,
  toCurrency
} from '@qwealth/qcore';
import { Account, initializeHouseholdAccounts, IPerson } from '@qwealth/qdata';
import EditButton from '../../Common/EditButton';
import Editor from './Editor';
import InputLabel from '../../Common/InputLabel';
import InputValue from '../../Common/InputValue';
import OwnerLabel from '../../Common/OwnerLabel';
import BorderedSection from '../../Common/BorderedSection';
import { OwnerDetails } from 'data/models/OwnerDetails';

interface Props {
  primary?: IPerson;
  secondary?: IPerson;
}

const RetirementSavings: React.FC<Props> = ({ primary, secondary }) => {
  // @ts-ignore
  const household = useSelector((state) => state.household);
  // @ts-ignore
  const accounts: Array<Account> = useSelector((state) => state.accounts);

  const [owner, setOwner] = useState<OwnerDetails | undefined>();
  const [editableAccounts, setEditableAccounts] = useState<Array<Account>>([]);

  const { initialized, householdId } = household;

  useDispatchOnMount(initializeHouseholdAccounts, initialized ? householdId : undefined);

  const investmentAccounts = accounts.filter((a) => a.accountType === 'Investment');
  const jointAccounts = investmentAccounts.filter(({ ownershipType }) => ownershipType === 'Joint');
  const primaryAccounts = investmentAccounts.filter(
    ({ accountOwnersQID, ownershipType }) =>
      accountOwnersQID === primary?.QID && ownershipType === 'Sole'
  );
  const secondaryAccounts = investmentAccounts.filter(
    ({ accountOwnersQID }) => accountOwnersQID === secondary?.QID
  );

  return (
    <>
      <AccordionSection
        color="primary"
        backgroundColor="primaryTransparent"
        title="Retirement Savings"
      >
        <Box
          display="grid"
          gridTemplateColumns={{
            phone: '1fr',
            desktop: '1fr 1fr'
          }}
          gap="default"
        >
          {!isEmpty(secondary) && (
            <BorderedSection
              gridColumn={{
                phone: 'initial',
                desktop: '1 / span 2'
              }}
            >
              <InputLabel>
                <Box as="span" mr="small">
                  Retirement Savings -
                </Box>
                <OwnerLabel type="joint">Joint</OwnerLabel>
              </InputLabel>
              <Box display="flex" alignItems="center">
                <InputValue>
                  {toCurrency(
                    jointAccounts
                      .map((acc) => getAnnualSavings(acc, primary, secondary))
                      .reduce((a, b) => a + b, 0),
                    true
                  )}
                </InputValue>
                <EditButton
                  onClickHandler={() => {
                    setOwner({ type: 'Joint', id: primary?.QID, name: 'joint', householdId });
                    setEditableAccounts(jointAccounts);
                  }}
                />
              </Box>
            </BorderedSection>
          )}
          <BorderedSection>
            <InputLabel>
              <Box as="span" mr="small">
                Retirement Savings -
              </Box>
              <OwnerLabel type="primary">{primary?.firstName}</OwnerLabel>
            </InputLabel>
            <Box display="flex" alignItems="center">
              <InputValue>
                {toCurrency(
                  primaryAccounts
                    .map((acc) => getAnnualSavings(acc, primary, secondary))
                    .reduce((a, b) => a + b, 0),
                  true
                )}
              </InputValue>
              <EditButton
                onClickHandler={() => {
                  if (primary) {
                    setOwner({
                      id: primary.QID,
                      name: primary.firstName,
                      type: 'Primary',
                      householdId
                    });
                  }
                  setEditableAccounts(primaryAccounts);
                }}
              />
            </Box>
          </BorderedSection>
          {!isEmpty(secondary) && (
            <BorderedSection>
              <InputLabel>
                <Box as="span" mr="small">
                  Retirement Savings -
                </Box>
                <OwnerLabel type="secondary">{secondary?.firstName}</OwnerLabel>
              </InputLabel>
              <Box display="flex" alignItems="center">
                <InputValue>
                  {toCurrency(
                    secondaryAccounts
                      .map((acc) => getAnnualSavings(acc, secondary, primary))
                      .reduce((a, b) => a + b, 0),
                    true
                  )}
                </InputValue>
                <EditButton
                  onClickHandler={() => {
                    if (secondary) {
                      setOwner({
                        id: secondary?.QID,
                        name: secondary?.firstName,
                        type: 'Secondary',
                        householdId
                      });
                    }
                    setEditableAccounts(secondaryAccounts);
                  }}
                />
              </Box>
            </BorderedSection>
          )}
        </Box>
      </AccordionSection>
      <Editor owner={owner} setOwner={setOwner} accounts={editableAccounts} />
    </>
  );
};

export default RetirementSavings;
