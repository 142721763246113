import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, AccordionSection } from '@qwealth/qcore';
import EditButton from '../../Common/EditButton';
import Editor, { Owner } from './Editor';
import InputLabel from '../../Common/InputLabel';
import InputValue from '../../Common/InputValue';
import OwnerLabel from '../../Common/OwnerLabel';
import BorderedSection from '../../Common/BorderedSection';
import { IGoalWorkshopDto, IPerson } from '@qwealth/qdata';

interface Props {
  primary: IPerson;
  secondary: IPerson;
  retirementGoals: Array<IGoalWorkshopDto>;
}

const RetirementAge: React.FC<Props> = ({ primary, secondary, retirementGoals }) => {
  const [goal, setGoal] = useState<IGoalWorkshopDto>();
  const [owner, setOwner] = useState<Owner>();
  const retirement = retirementGoals.find((r) => r.attendeeQID === primary.QID);
  const secondaryRetirement = retirementGoals.find((r) => r.attendeeQID === secondary.QID);

  return (
    <>
      <AccordionSection color="primary" backgroundColor="primaryTransparent" title="Retirement Age">
        <Box
          display="grid"
          gridTemplateColumns={{
            phone: '1fr',
            desktop: '1fr 1fr'
          }}
          gap="default"
        >
          <BorderedSection>
            <InputLabel>
              <OwnerLabel type="primary">{primary.firstName}</OwnerLabel>
            </InputLabel>
            <Box display="flex" alignItems="center">
              <InputValue>{retirement && retirement.retirementAgeValue}</InputValue>
              <EditButton
                onClickHandler={() => {
                  setOwner({ name: primary.firstName, type: 'primary' });
                  setGoal(retirement);
                }}
              />
            </Box>
          </BorderedSection>
          {!isEmpty(secondary) && (
            <BorderedSection>
              <InputLabel>
                <OwnerLabel type="secondary">{secondary.firstName}</OwnerLabel>
              </InputLabel>
              <Box display="flex" alignItems="center">
                <InputValue>
                  {secondaryRetirement && secondaryRetirement.retirementAgeValue}
                </InputValue>
                <EditButton
                  onClickHandler={() => {
                    setOwner({ name: secondary.firstName, type: 'secondary' });
                    setGoal(secondaryRetirement);
                  }}
                />
              </Box>
            </BorderedSection>
          )}
        </Box>
      </AccordionSection>
      <Editor
        goal={goal}
        setGoal={setGoal}
        // @ts-ignore
        owner={owner}
      />
    </>
  );
};

export default RetirementAge;
