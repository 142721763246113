import React, { useState } from 'react';
import { Box, AccordionSection } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';
import { IGoalWorkshopDto } from '@qwealth/qdata';
import { legacyBalanceFilter } from '@qwealth/qcore/build/es/utils/WorkshopUtil';
import EditButton from '../../Common/EditButton';
import Editor from './Editor';
import InputLabel from '../../Common/InputLabel';
import InputValue from '../../Common/InputValue';
import BorderedSection from '../../Common/BorderedSection';

interface Props {
  goalList: Array<IGoalWorkshopDto>;
}

const Legacy: React.FC<Props> = ({ goalList }) => {
  const legacy = goalList.find(legacyBalanceFilter);

  const [show, setShow] = useState(false);
  return (
    <>
      <AccordionSection color="primary" backgroundColor="primaryTransparent" title="Legacy Value">
        <BorderedSection>
          <InputLabel>Legacy Ending Assets</InputLabel>
          <Box display="flex" alignItems="center">
            <InputValue>{legacy && toCurrency(legacy.legacyEndValue, true)}</InputValue>
            <EditButton onClickHandler={() => setShow(true)} />
          </Box>
        </BorderedSection>
      </AccordionSection>
      <Editor show={show} setShow={setShow} goal={legacy} />
    </>
  );
};

export default Legacy;
