import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import type { AggregatedBaselinePlanData } from '../interfaces/AggregatedBaselinePlanData';

const Titles = {
  Retirement_Age: 'Retirement Age',
  Retirement_Spending: 'Retirement Spending',
  Retirement_Saving: 'Retirement Savings',
  Retirement_Inflows: 'Retirement Inflows',
  Retirement_Outflows: 'Retirement Outflows',
  Legacy_Value: 'Legacy Value'
};

const styles = StyleSheet.create({
  Content: {
    paddingHorizontal: 80,
    paddingVertical: 30
  },
  Title: {
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 9,
    paddingHorizontal: 15,
    paddingVertical: 3,
    marginVertical: 5,
    fontWeight: 'bold'
  },
  Block: {
    backgroundColor: COLORS.GRAY1,
    paddingLeft: 10,
    height: 30,
    paddingVertical: 3
  },
  BlockTitle: {
    color: '#888888',
    fontSize: 8,
    fontWeight: 'bold'
  },
  BlockText: {
    color: COLORS.BLACK,
    fontSize: 10,
    fontWeight: 'bold'
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  VerticalAlign: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const createFields = ({ names, value }: { names: string[]; value: (string | number)[] }) =>
  value.map((_, i) => ({
    name: names[i],
    val: value[i]
  }));

export type ReportBaselinePlanProps = {
  data: AggregatedBaselinePlanData | null;
};

const ReportBaselineAssumption1 = ({ data }: ReportBaselinePlanProps) => {
  const retirementAge = data && data[Titles.Retirement_Age] && data[Titles.Retirement_Age][0];
  const retirementSpending =
    data && data[Titles.Retirement_Spending] && data[Titles.Retirement_Spending];
  const retirementSaving = data && data[Titles.Retirement_Saving][0];
  const retirementInflows = data && data[Titles.Retirement_Inflows];
  const retirementOutflows = data && data[Titles.Retirement_Outflows];
  const legacyValue = data && data[Titles.Legacy_Value][0];

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>BASELINE PLAN ASSUMPTIONS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={styles.Content}>
        <View style={styles.Title}>
          <Text>{Titles.Retirement_Spending}</Text>
        </View>
        <View style={styles.Row}>
          {retirementAge &&
            createFields(retirementAge).map(({ name, val }, index) => (
              <View
                key={`age-${index}`}
                style={[
                  styles.Row,
                  styles.Block,
                  { flex: 1, alignItems: 'flex-end', marginLeft: index === 1 ? 5 : 0 }
                ]}
              >
                <Text style={{ fontSize: 12, fontWeight: 'bold', color: COLORS.PRIMARY }}>
                  {name}
                </Text>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: COLORS.BLACK,
                    marginLeft: 15
                  }}
                >
                  {val}
                </Text>
              </View>
            ))}
        </View>

        <View style={styles.Title}>
          <Text>{Titles.Retirement_Spending}</Text>
        </View>
        <View style={[styles.Block, styles.VerticalAlign]}>
          <Text style={styles.BlockTitle}>Targeted Annual Retirement Spend</Text>
          <Text style={styles.BlockText}>{retirementSpending && retirementSpending[0].value}</Text>
        </View>
        <View style={[styles.Row, { marginVertical: 5 }]}>
          {retirementSpending &&
            createFields(retirementSpending[1]).map(({ name, val }, index) => (
              <View
                key={`spending-${index}`}
                style={[styles.Block, { flex: 1, marginLeft: index !== 0 ? 5 : 0 }]}
              >
                <Text style={styles.BlockTitle}>
                  Guaranteed Retirement Income &#183;{' '}
                  <Text style={{ color: COLORS.PRIMARY }}>{name}</Text>
                </Text>
                <Text style={styles.BlockText}>{val}</Text>
              </View>
            ))}
        </View>
        <View style={[styles.Block, styles.VerticalAlign]}>
          <Text style={styles.BlockTitle}>Annual Withdrawal From Retirement Savings</Text>
          <Text style={styles.BlockText}>
            {retirementSpending && retirementSpending[2].value[0]}
          </Text>
        </View>

        <View style={styles.Title}>
          <Text>{Titles.Retirement_Saving}</Text>
        </View>
        {retirementSaving && (
          <View style={[styles.Block, { flex: 1, marginBottom: 5 }]}>
            <Text style={styles.BlockTitle}>Retirement Savings</Text>
            <Text style={styles.BlockText}>{retirementSaving.value[2]}</Text>
          </View>
        )}
        <View style={styles.Row}>
          {retirementSaving &&
            createFields(retirementSaving).map(
              ({ name, val }, index) =>
                index !== 2 && (
                  <View
                    key={`spending-${index}`}
                    style={[styles.Block, { flex: 1, marginLeft: index !== 0 ? 5 : 0 }]}
                  >
                    <Text style={styles.BlockTitle}>
                      Retirement Savings &#183;{' '}
                      <Text style={{ color: COLORS.PRIMARY }}>{name}</Text>
                    </Text>
                    <Text style={styles.BlockText}>{val}</Text>
                  </View>
                )
            )}
        </View>

        <View style={styles.Title}>
          <Text>{Titles.Retirement_Inflows}</Text>
        </View>
        {retirementInflows &&
          retirementInflows.map((inflow, index) => (
            <View style={[styles.Block, styles.VerticalAlign]} key={`inflow-${index}`}>
              <Text style={styles.BlockTitle}>{inflow.title}</Text>
              <Text style={styles.BlockText}>{inflow.value[0]}</Text>
            </View>
          ))}

        <View style={styles.Title}>
          <Text>{Titles.Retirement_Outflows}</Text>
        </View>
        {retirementOutflows &&
          retirementOutflows.map((inflow, index) => (
            <View style={[styles.Block, styles.VerticalAlign]} key={`outflow-${index}`}>
              <Text style={styles.BlockTitle}>{inflow.title}</Text>
              <Text style={styles.BlockText}>{inflow.value[0]}</Text>
            </View>
          ))}

        <View style={styles.Title}>
          <Text>{Titles.Legacy_Value}</Text>
        </View>
        {legacyValue && (
          <View style={[styles.Block, styles.VerticalAlign]}>
            <Text style={styles.BlockTitle}>{legacyValue.title}</Text>
            <Text style={styles.BlockText}>{legacyValue.value}</Text>
          </View>
        )}
      </View>
    </Page>
  );
};

export default ReportBaselineAssumption1;
