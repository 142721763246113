import React from 'react';
import { Box, Button, SplashPage } from '@qwealth/qcore';
import { useMsal } from '@azure/msal-react';
import { scopes } from 'services/msalService';

export default function Home() {
  const { instance } = useMsal();
  return (
    <SplashPage
      mt={{
        phone: 'large',
        desktop: '5rem'
      }}
    >
      <SplashPage.Body>
        <SplashPage.Title>QScore</SplashPage.Title>
        <Box mb="largest">
          <p>
            Now that we have assembled your complete data, we&apos;re ready to begin the process of
            calculating your Qscore.
          </p>

          <p>
            The Baseline Plan will allow you to see your probability of success of reaching your
            fundamental retirement goals.
          </p>

          <p>
            Each Simulation will run a brand new calculation of the probability of success of adding
            that specific goal to your already established Baseline Plan. If the score is within the
            acceptable range, then you can transpose that additional goal into your Baseline Plan.
          </p>
        </Box>
        <SplashPage.Quote>Can I live the life I want in Retirement?</SplashPage.Quote>
        <SplashPage.Actions>
          <Button
            onClick={() =>
              instance.loginRedirect({
                redirectStartPage: `/baselineplan${window.location.search}`,
                scopes
              })
            }
          >
            Let&apos;s Begin!
          </Button>
        </SplashPage.Actions>
      </SplashPage.Body>
      <SplashPage.Image imageSrc="/assets/intro.png" style={{ backgroundPosition: 'center' }} />
    </SplashPage>
  );
}
