import axios from 'axios';
import { putNotification } from '../data/actions/notifications';
import { ValidationError } from '../data/helpers/validationError';

export const { REACT_APP_QWEALTH_API } = process.env;
export const { REACT_APP_QWEALTH_QC_API } = process.env;

// TODO: the below is hardcoded for sandbox env. please refactor later
export default async function configureAxios(idToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;

  return Promise.resolve();
}

export const getHouseholdQID = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('household');
};

export const responseMapper = (response) => {
  const { data } = response.data;
  return data;
};

export const errorHandler = (dispatch, errorText) => (error) => {
  console.error(error);
  dispatch(putNotification(errorText || 'API Error. Please contact support', 'Error'));
};

export const notifyError = (error) => (dispatch) => {
  const message = error instanceof ValidationError && error.message;

  errorHandler(dispatch, message)(error);
};
