import _ from 'lodash';
import { PUT_ENTITY, REMOVE_ENTITY } from '../actions/legalEntity';

const ORDER = ['Corporate', 'Trust'];
export default function (state = [], action) {
  switch (action.type) {
    case PUT_ENTITY: {
      const { entity } = action;
      const idx = state.findIndex((e) => e.QID === entity.QID);
      let legalEntities = [...state, entity];
      if (idx > -1) {
        legalEntities = [...state.slice(0, idx), entity, ...state.slice(idx + 1)];
      }

      return _.sortBy(legalEntities, (e) => _.indexOf(ORDER, e.entityType));
    }
    case REMOVE_ENTITY: {
      const { QID } = action;
      return state.filter((e) => e.QID !== QID);
    }
    default:
      return state;
  }
}
