import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { toCurrency } from '@qwealth/qcore';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 10,
    paddingHorizontal: 12
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 30,
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    // height: 220,
    width: '45%'
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
    height: 35
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 35,
    color: COLORS.WHITE
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 35,
    paddingHorizontal: 20,
    fontSize: 12
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  TableRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8
  },
  TableHeader: {
    fontSize: 10,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    padding: 5
  }
});

const scenario1 = [
  { percent: 1.7, amount: 947000 },
  { percent: -12.2, amount: 761466 },
  { percent: -12.8, amount: 593998.35 },
  { percent: 19.3, amount: 638640.03 },
  { percent: 13.9, amount: 657411 },
  { percent: 22.9, amount: 737958.12 },
  { percent: 17.4, amount: 796362.83 },
  { percent: 7.8, amount: 788479.13 },
  { percent: -35.3, amount: 438569.04 },
  { percent: 30.7, amount: 503209.73 },
  { percent: 14.4, amount: 505671.94 },
  { percent: -11.1, amount: 379542.34 },
  { percent: 4, amount: 324724.05 },
  { percent: 9.6, amount: 285897.55 },
  { percent: 7.4, amount: 237053.97 },
  { percent: -11.1, amount: 140740.98 },
  { percent: 17.5, amount: 95370.65 },
  { percent: 6, amount: 31092.89 },
  { percent: 11.6, amount: 35300.33 },
  { percent: 22.9, amount: 113384.11 }
];

const scenario2 = [
  { percent: 22.9, amount: 1159000 },
  { percent: 11.6, amount: 1223444 },
  { percent: 6, amount: 1226850.64 },
  { percent: 17.5, amount: 1371549.5 },
  { percent: -11.1, amount: 1149307.51 },
  { percent: 7.4, amount: 1164356.26 },
  { percent: 9.6, amount: 1206134.46 },
  { percent: 4, amount: 1184379.84 },
  { percent: -11.1, amount: 982913.68 },
  { percent: 14.4, amount: 1054453.25 },
  { percent: 30.7, amount: 1308170.4 },
  { percent: -35.5, amount: 773769.91 },
  { percent: 7.8, amount: 764123.96 },
  { percent: 17.4, amount: 827081.53 },
  { percent: 22.9, amount: 946483.2 },
  { percent: 13.9, amount: 1008044.36 },
  { percent: 19.3, amount: 1132596.92 },
  { percent: -12.8, amount: 917624.52 },
  { percent: -12.2, amount: 735674.33 },
  { percent: 1.7, amount: 678180.79 }
];

const scenario3 = [
  { percent: -11.1, amount: 819000 },
  { percent: 14.4, amount: 866936 },
  { percent: 30.7, amount: 1063085.35 },
  { percent: -35.5, amount: 615690.05 },
  { percent: 7.8, amount: 593713.88 },
  { percent: 17.4, amount: 627020.09 },
  { percent: 22.9, amount: 700607.69 },
  { percent: 13.9, amount: 727992.16 },
  { percent: 19.3, amount: 798494.65 },
  { percent: -12.8, amount: 626287.33 },
  { percent: -12.2, amount: 479880.28 },
  { percent: 1.7, amount: 418038.24 },
  { percent: 22.9, amount: 443769 },
  { percent: 11.6, amount: 425246.2 },
  { percent: 6, amount: 380760.98 },
  { percent: 17.5, amount: 377394.15 },
  { percent: -11.1, amount: 265503.4 },
  { percent: 7.4, amount: 245150.65 },
  { percent: 9.6, amount: 165805.11 },
  { percent: 4, amount: 102437.32 }
];

const scenario4 = [
  { percent: 7.8, amount: 100800 },
  { percent: 17.4, amount: 113392 },
  { percent: 22.9, amount: 1298358.77 },
  { percent: 13.9, amount: 1408830.64 },
  { percent: 19.3, amount: 1610734.64 },
  { percent: -12.8, amount: 1334560.88 },
  { percent: -12.2, amount: 1101744.45 },
  { percent: 1.7, amount: 1050474.1 },
  { percent: 22.9, amount: 1221032.67 },
  { percent: 11.6, amount: 1292672.47 },
  { percent: 6, amount: 1300232.81 },
  { percent: 17.5, amount: 1457773.56 },
  { percent: -11.1, amount: 1225960.69 },
  { percent: 7.4, amount: 1246681.78 },
  { percent: 9.6, amount: 1296363.23 },
  { percent: 4, amount: 1278217.76 },
  { percent: -11.1, amount: 1066335.59 },
  { percent: 14.4, amount: 1149887.92 },
  { percent: 30.7, amount: 1432903.51 },
  { percent: -35.5, amount: 85422.76 }
];

const ReportSample = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>WHAT IS MEANT BY SEQUENCE OF RETURNS?</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View style={{ paddingHorizontal: 30, paddingTop: 30 }}>
      <Text style={styles.SubText1}>
        We know how much to <Text style={styles.BoldFont}>‘randomize’</Text> your returns by using
        historical data detailing how much above or below, the actual returns of your portfolio has
        been compared to the <Text style={styles.BoldFont}>‘average rate of return’</Text>. This is
        called your <Text style={styles.BoldFont}>‘standard deviation’</Text>. Despite the fact that
        returns
        <Text style={styles.BoldFont}>‘deviate’</Text>, history has shown us that we generally
        return to the same average return over longer periods of time. That is why we need to know
        both the <Text style={styles.BoldFont}>‘average rate of return’</Text> and the{' '}
        <Text style={styles.BoldFont}>‘standard deviation’</Text>.
      </Text>
      <Text style={[styles.SubText1, { marginTop: 10 }]}>
        Below is a sample of the variety of end results that can occur. We begin with a portfolio of
        $1,000,000. Each year we will withdraw $70,000 from the portfolio to fund retirement needs.
      </Text>

      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20, paddingLeft: 20 }}>
        <View style={{ marginTop: 31, width: 30 }}>
          {scenario1.map((data, index) => (
            <View
              key={`year-${index + 1}`}
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Text
                style={{
                  backgroundColor: index % 2 === 0 ? COLORS.PRIMARY : COLORS.PRIMARY_LIGHT,
                  color: COLORS.WHITE,
                  fontSize: 8,
                  paddingHorizontal: 5,
                  paddingVertical: 3,
                  width: '100%',
                  borderBottomColor: index % 2 === 0 ? COLORS.PRIMARY : COLORS.PRIMARY_LIGHT,
                  borderBottomWidth: 1
                }}
              >
                {2000 + index}
              </Text>
            </View>
          ))}
        </View>
        <View style={{ marginRight: 10, flex: 1 }}>
          <View>
            <View style={styles.TableHeader}>
              <Text>Scenario 1 - Actual Returns TSX</Text>
              <View>
                <View style={styles.TableRow}>
                  <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                  <Text style={{ width: '10%' }}>$</Text>
                  <Text style={{ width: '30%' }}>70,000.00</Text>
                </View>
              </View>
            </View>
            <View>
              {scenario1.map((data, index) => (
                <View
                  key={`year-${index + 1}`}
                  style={[
                    styles.TableRow,
                    {
                      flex: 1,
                      fontSize: 8,
                      backgroundColor: COLORS.GRAY1,
                      paddingHorizontal: 5,
                      paddingVertical: 3,
                      borderBottomWidth: 1,
                      borderBottomColor: '#cccccc'
                    }
                  ]}
                >
                  <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                  <Text style={{ width: '10%' }}>$</Text>
                  <Text
                    style={{
                      width: '30%'
                    }}
                  >
                    {toCurrency(data.amount)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>
        <View style={{ marginRight: 10, flex: 1 }}>
          <View style={[styles.TableHeader, { backgroundColor: COLORS.PRIMARY }]}>
            <Text>Scenario 2</Text>
            <View>
              <View style={styles.TableRow}>
                <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>70,000.00</Text>
              </View>
            </View>
          </View>
          <View>
            {scenario2.map((data, index) => (
              <View
                key={`year-${index + 1}`}
                style={[
                  styles.TableRow,
                  {
                    flex: 1,
                    fontSize: 8,
                    backgroundColor: COLORS.GRAY1,
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderBottomWidth: 1,
                    borderBottomColor: '#cccccc'
                  }
                ]}
              >
                <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>{toCurrency(data.amount)}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ marginRight: 10, flex: 1 }}>
          <View style={styles.TableHeader}>
            <Text>Scenario 3</Text>
            <View>
              <View style={styles.TableRow}>
                <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>70,000.00</Text>
              </View>
            </View>
          </View>
          <View>
            {scenario3.map((data, index) => (
              <View
                key={`year-${index + 1}`}
                style={[
                  styles.TableRow,
                  {
                    flex: 1,
                    fontSize: 8,
                    backgroundColor: COLORS.GRAY1,
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderBottomWidth: 1,
                    borderBottomColor: '#cccccc'
                  }
                ]}
              >
                <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>{toCurrency(data.amount)}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ marginRight: 20, flex: 1 }}>
          <View style={[styles.TableHeader, { backgroundColor: COLORS.PRIMARY }]}>
            <Text>Scenario 4</Text>
            <View>
              <View style={styles.TableRow}>
                <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>70,000.00</Text>
              </View>
            </View>
          </View>
          <View>
            {scenario4.map((data, index) => (
              <View
                key={`year-${index + 1}`}
                style={[
                  styles.TableRow,
                  {
                    flex: 1,
                    fontSize: 8,
                    backgroundColor: COLORS.GRAY1,
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderBottomWidth: 1,
                    borderBottomColor: '#cccccc'
                  }
                ]}
              >
                <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>{toCurrency(data.amount)}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  </Page>
);

export default ReportSample;
