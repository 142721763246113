import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  MainText2: {
    fontSize: 12,
    color: COLORS.PRIMARY
  }
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets']
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets
  }
});

const ReportAdvisorQRoutes = ({ data }: { data: AggregatedQScoreData }) => {
  const chartAdvisorQRouteImg = useChartJsAsImage(
    crateChartJsConfig('bar', data.chartAdvisorQRoutes.labels, data.chartAdvisorQRoutes.datasets)
  );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>SELECTED ADVISOR QROUTES</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30,
          paddingTop: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          An Advisor QRoute is an alternative pathway that is chosen by the Advisor to evaluate your
          <Text style={styles.BoldFont}>‘Journey’</Text>. It allows both{' '}
          <Text style={styles.BoldFont}>YOU</Text> and{' '}
          <Text style={styles.BoldFont}>YOUR ADVISOR</Text> an opportunity to evaluate the impact of
          planning options recommended to you by your advisor. By way of example,
        </Text>
        <Text style={styles.MainText2}>
          “Let’s see what the MAXIMUM amount you could safely withdraw from your retirement assets
          in retirement.” <Text style={styles.BoldFont}>or</Text>
        </Text>
        <Text style={[styles.MainText2, { marginBottom: 20 }]}>
          “What is the optimal age to begin receiving OAS and CPP?” Or “What is the optimal
          withdrawal strategy utilizing my registered accounts knowing I want to leave behind
          $750,000 for each one of my children?”
        </Text>
        {chartAdvisorQRouteImg}
      </View>
    </Page>
  );
};

export default ReportAdvisorQRoutes;
