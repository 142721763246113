import { StateAction } from '../store';
import axios from 'axios';
import { REACT_APP_QWEALTH_API, responseMapper } from '../../services/axiosService';
import { getPartnerShortCode, responseEntityMapper } from '@qwealth/qcore';
import {
  getAddressDto,
  getLegalEntityDTO,
  getLegalEntityHouseholdMemberDTO,
  IAddressDto,
  ILegalEntityDTO,
  IHouseholdMemberDto,
  ILegalEntity,
  toLegalEntity
} from '@qwealth/qdata';

export const PUT_ENTITY = 'PUT_ENTITY';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';

const putEntity = (entity: ILegalEntity) => ({ type: PUT_ENTITY, entity });
const removeEntity = (QID: string | undefined) => ({ type: REMOVE_ENTITY, QID });

export const saveEntity = (entity: ILegalEntity, householdId: string): StateAction => (
  dispatch
) => {
  let saveFn;
  if (entity.QID) {
    saveFn = (entityDTO: ILegalEntityDTO) =>
      axios.patch(`${REACT_APP_QWEALTH_API}/legalEntities/${entity.QID}`, entityDTO);
  } else {
    const partnerShortCode = getPartnerShortCode(householdId);
    saveFn = (entityDTO: ILegalEntityDTO) =>
      axios
        .post(`${REACT_APP_QWEALTH_API}/QIDgenerator/bulk/`, {
          partner: partnerShortCode,
          numberRequired: 1,
          QIDType: 'Business'
        })
        .then(responseMapper)
        .then((qid) => {
          const { QID } = qid[0];
          entityDTO.QID = QID;
          return axios.post(`${REACT_APP_QWEALTH_API}/legalEntities`, entityDTO);
        })
        .then((response) => {
          const legalEntityHouseholdMemberDTO = getLegalEntityHouseholdMemberDTO(
            entityDTO,
            householdId
          );
          const url = `${REACT_APP_QWEALTH_API}/householdEntities/hhQID/${entityDTO.QID}`;
          return axios.post(url, legalEntityHouseholdMemberDTO).then(() => response);
        });
  }
  return saveFn(getLegalEntityDTO(entity))
    .then((response) => responseEntityMapper<ILegalEntityDTO>(response))
    .then((entityDTO) => {
      const { QID } = entityDTO;
      entity.QID = QID;
      return QID;
    })
    .then((QID) => {
      if (entity.addressId) {
        return axios.patch(
          `${REACT_APP_QWEALTH_API}/addresses/${entity.addressId}`,
          getAddressDto(entity, householdId)
        );
      }
      return axios.post(
        `${REACT_APP_QWEALTH_API}/legalEntities/${QID}/addresses/`,
        getAddressDto(entity, householdId)
      );
    })
    .then(() => dispatch(putEntity(entity)));
};

export const loadLegalEntity = (member: IHouseholdMemberDto): StateAction => (dispatch) => {
  return Promise.all([
    axios
      .get(`${REACT_APP_QWEALTH_API}/legalEntities/${member.legalEntityQID}`)
      .then((resp) => responseEntityMapper<ILegalEntityDTO>(resp)),
    axios
      .get(`${REACT_APP_QWEALTH_API}/legalEntities/${member.legalEntityQID}/addresses`)
      .then((response) => responseEntityMapper<IAddressDto>(response, false))
  ])
    .then(
      ([legalEntityDTO, addressDTO]) =>
        legalEntityDTO && addressDTO && toLegalEntity(legalEntityDTO, addressDTO)
    )
    .then((legalEntity) => legalEntity && dispatch(putEntity(legalEntity)));
};

export const deleteEntity = (entity: ILegalEntity): StateAction => (dispatch) => {
  const { QID, addressId } = entity;
  return Promise.all([
    axios.delete(`${REACT_APP_QWEALTH_API}/legalEntities/${QID}`),
    axios.delete(`${REACT_APP_QWEALTH_API}/householdEntities/QID/${QID}`),
    axios.delete(`${REACT_APP_QWEALTH_API}/addresses/${addressId}`)
  ]).then(() => dispatch(removeEntity(QID)));
};

export const isLegalEntity = (member: IHouseholdMemberDto) =>
  ['Corporate', 'Trust'].includes(member.memberType);
