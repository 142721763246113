import React from 'react';
import { Box } from '@qwealth/qcore';

import { getScoreTitle, getScoreMeaning } from '../../utils/DataUtil';

export default function QScoreDescription({ value }) {
  const title = getScoreTitle(value);
  const scoreMeaning = getScoreMeaning(value);

  return (
    <Box className="score-description" opacity="0">
      {value > -1 && (
        <Box
          as="p"
          fontWeight="700"
          mb="0"
          color={title === 'Perfect' ? 'green' : title === 'Danger' ? 'red' : 'yellow'}
        >
          {title.toUpperCase()}
        </Box>
      )}
      {value > -1 && (
        <Box as="p" fontSize="12px" fontStyle="italic">
          {scoreMeaning}
        </Box>
      )}
    </Box>
  );
}
