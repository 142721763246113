import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@qwealth/qcore';

const Loading = (
  <Box textAlign="center">
    <img src="/assets/loader.gif" width="127" height="127" alt="Loading..." />
    <p>Putting your QScore together...</p>
  </Box>
);

const NotLoading = <img src="/assets/Q.png" width="127" height="127" alt="Q" />;

export default function QLoader(props) {
  return props.loading ? Loading : NotLoading;
}

QLoader.propTypes = {
  loading: PropTypes.bool.isRequired
};
