import React from 'react';
import * as PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export default function InlineSwitch(props) {
  const { checked, onChangeHandler } = props;

  return (
    <Form.Check
      type="switch"
      checked={checked}
      id="custom-switch"
      label=""
      onChange={onChangeHandler}
    />
  );
}

InlineSwitch.propTypes = {
  checked: PropTypes.bool,
  onChangeHandler: PropTypes.func
};

InlineSwitch.defaultProps = {
  checked: false,
  onChangeHandler: () => {}
};
