import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@qwealth/qcore';
import { v4 as uuidv4 } from 'uuid';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import EditableTable from '../Common/EditableTable';
import { deleteOverride, loadOverrides, saveOverride } from '../../data/actions/overrides';
import { getHouseholdQID } from '../../services/axiosService';

const householdId = getHouseholdQID();

const assetAllocations = {
  '100Equity0FixedIncome': '100 Equity, 0 Fixed Income',
  '80Equity20FixedIncome': '80 Equity, 20 Fixed Income',
  '70Equity30FixedIncome': '70 Equity, 30 Fixed Income',
  '60Equity40FixedIncome': '60 Equity, 40 Fixed Income',
  '50Equity50FixedIncome': '50 Equity, 50 Fixed Income',
  '40Equity60FixedIncome': '40 Equity, 60 Fixed Income',
  '30Equity70FixedIncome': '30 Equity, 70 Fixed Income',
  '20Equity80FixedIncome': '20 Equity, 80 Fixed Income',
  '0Equity100FixedIncome': '0 Equity, 100 Fixed Income'
};

const columns = [
  {
    dataField: 'uuid',
    text: 'uuid',
    hidden: true
  },
  {
    dataField: 'assetAllocation',
    text: 'Asset Allocation',
    editor: {
      type: Type.SELECT,
      options: Object.keys(assetAllocations).map((value) => ({
        value,
        label: assetAllocations[value]
      }))
    },
    formatter: (val) => assetAllocations[val]
  },
  {
    dataField: 'growthRate',
    text: 'Growth Rate (%)'
  },
  {
    dataField: 'standardDeviation',
    text: 'Standard Deviation (%)'
  }
];

export default function AllocationOverride() {
  const overrides = useSelector((state) => state.overrides);

  const [initialized, setInitialized] = useState(false);

  const dispatch = useDispatch();

  const addHandler = () => {
    dispatch(
      saveOverride({
        uuid: uuidv4(),
        householdId,
        assetAllocation: '50 Equity, 50 Fixed Income',
        growthRate: 1.0,
        standardDeviation: 1.0
      })
    );
  };

  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row) => {
      if (!row.assetAllocation) {
        dispatch(saveOverride({ ...row, assetAllocation: '50Equity50FixedIncome' }));
      } else {
        dispatch(saveOverride(row));
      }
    }
  });

  useEffect(() => {
    if (!initialized) {
      dispatch(loadOverrides()).then(() => setInitialized(true));
    }
  }, [dispatch, initialized]);

  const allocations = overrides.map((o) => o.assetAllocation);
  const hasDuplicates = allocations.some((item, index) => allocations.indexOf(item) !== index);

  return (
    <Card title="Asset Allocation Overrides">
      {hasDuplicates && (
        <span className="alert-warning">Please remove duplicate asset allocation overrides</span>
      )}
      <EditableTable
        addHandler={addHandler}
        deleteHandler={(row) => dispatch(deleteOverride(row.uuid))}
        addText="Add Override"
        cellEdit={cellEdit}
        data={overrides}
        columns={columns}
        keyField="uuid"
      />
    </Card>
  );
}
