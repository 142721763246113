import React from 'react';
import { Box } from '@qwealth/qcore';

export default function InputLabel(props) {
  return (
    <Box textStyle="formLabel" display="flex">
      {props.children}
    </Box>
  );
}
