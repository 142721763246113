import React, { useMemo, useState } from 'react';
import { Box, AccordionSection, toCurrency } from '@qwealth/qcore';
import { FlowEditor } from '@qwealth/qworkshop';
import EditButton from '../Common/EditButton';
import { annualFlowValue } from '../../utils/FlowUtil';
import InputLabel from '../Common/InputLabel';
import InputValue from '../Common/InputValue';
import BorderedSection from '../Common/BorderedSection';
import { IGoalWorkshopDto } from '@qwealth/qdata';

interface Props {
  ageMap: Record<string, number>;
  retirementMap: Record<string, number>;
  goalType: 'Post-retirement: Inflow' | 'Post-retirement: Outflow' | 'Pre-retirement: Spending';
  flows: Array<IGoalWorkshopDto>;
}

const RetirementFlows: React.FC<Props> = ({ ageMap, retirementMap, goalType, flows }) => {
  const [show, setShow] = useState(false);

  const totalValue = useMemo(
    () =>
      flows.map((flow) => annualFlowValue(flow, ageMap, retirementMap)).reduce((a, b) => a + b, 0),
    [flows, ageMap, retirementMap]
  );

  return (
    <>
      <AccordionSection
        color="primary"
        backgroundColor="primaryTransparent"
        title={goalType}
      >
        <BorderedSection>
          <InputLabel>{`Retirement ${goalType} Total`}</InputLabel>
          <Box display="flex" alignItems="center">
            <InputValue>{toCurrency(totalValue, true)}</InputValue>
            <EditButton onClickHandler={() => setShow(true)} />
          </Box>
        </BorderedSection>
      </AccordionSection>
      <FlowEditor flows={flows} goalType={goalType} show={show} setShow={setShow} />
    </>
  );
};

export default RetirementFlows;
