import React, { ReactElement, useEffect, useState } from 'react';
import { Image } from '@react-pdf/renderer';
import ChartJs from 'chart.js';

import type { ChartConfiguration } from 'chart.js';

export const useChartJsAsImage = (config: ChartConfiguration): ReactElement | null => {
  const [base64, setBase64] = useState<string | null>(null);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';

    document.body.append(canvas);

    new ChartJs(
      canvas,
      Object.assign(config, {
        width: 1200,
        plugins: [
          ...(config?.plugins ?? []),
          {
            // Saves chart to base64 once it's rendered
            afterRender: (c) => {
              setBase64(c.toBase64Image());
            }
          }
        ],
        options: {
          legend: {
            labels: {
              fontSize: 14
            }
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: 14
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: 14
                }
              }
            ]
          }
        }
      })
    );

    return () => {
      if (canvas) document.body.removeChild(canvas);
    };
  }, [config]);

  if (!base64) return null;

  return <Image src={base64} />;
};
