import { PUT_TIMELINE } from '../actions/timeline';

export default function (state = [], action) {
  switch (action.type) {
    case PUT_TIMELINE: {
      return action.timeline;
    }
    default:
      return state;
  }
}
