import React from 'react';
import { Box } from '@qwealth/qcore';

const colorMap = {
  primary: 'blue',
  secondary: 'red',
  joint: 'teal',
  default: 'black'
};

export default function OwnerLabel({ type, ...props }) {
  const _type = type ? type.toLowerCase() : 'default';

  return (
    <Box as="span" color={colorMap[_type]}>
      {props.children}
    </Box>
  );
}
