import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { TableProps } from '@qwealth/pdf-support';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';

import type { QScoreLegacyValueData } from '../interfaces/AggregatedQScoreData';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQScoreData } from '../interfaces/AggregatedQScoreData';
import { hasColumnData } from '../../../../../utils/DataUtil';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 10
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row'
  },
  Col: {
    flex: 1,
    fontSize: 8
  }
});

const createLegacyValueCols = (
  primary: string,
  secondary: string,
  legacyValue: Array<QScoreLegacyValueData>
): TableProps<QScoreLegacyValueData>['cols'] => [
  { title: 'Year', val: ({ year }) => year },
  { title: `${primary}'s age`, val: ({ age }) => age },
  { title: `${secondary}'s age`, val: ({ secondaryAge }) => secondaryAge },
  {
    title: `${primary}'s RRSP`,
    hidden: !hasColumnData('endingRegisteredBalance', legacyValue),
    val: ({ endingRegisteredBalance }) => toCurrency(endingRegisteredBalance, true)
  },
  {
    title: `${primary}'s RRSP`,
    hidden: !hasColumnData('endingSecondaryRegisteredBalance', legacyValue),
    val: ({ endingSecondaryRegisteredBalance }) =>
      toCurrency(endingSecondaryRegisteredBalance, true)
  },
  {
    title: 'Non-Registered\nBalance',
    hidden: !hasColumnData('endingNonRegisteredBalance', legacyValue),
    val: ({ endingNonRegisteredBalance }) => toCurrency(endingNonRegisteredBalance, true)
  },
  {
    title: 'Tax Free Balance',
    hidden: !hasColumnData('endingTaxFreeBalance', legacyValue),
    val: ({ endingTaxFreeBalance }) => toCurrency(endingTaxFreeBalance, true)
  },
  {
    title: 'Banking Balance',
    hidden: !hasColumnData('endingBankingBalance', legacyValue),
    val: ({ endingBankingBalance }) => toCurrency(endingBankingBalance, true)
  },
  { title: 'Total Retirement Assets', val: ({ endingBalance }) => toCurrency(endingBalance, true) }
];

const ReportLegacyValues1 = ({ data }: { data: AggregatedQScoreData }) => {
  const cols = createLegacyValueCols(
    data.personPrimary.firstName,
    data.personSecondary.firstName,
    data.legacyValue
  );

  return (
    <Page size="A4" orientation="landscape" style={[commonStyles.Root, { paddingBottom: 40 }]}>
      <View style={[commonStyles.Header, { marginBottom: 30 }]} fixed>
        <Text style={commonStyles.HeaderTitle}>YEAR END AND VALUES OF EACH ACCOUNT</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={{
          paddingHorizontal: 30
        }}
      >
        <Text style={[styles.MainText1, { marginBottom: 10 }]}>
          This report tells you what the Year End Values of each of your investment accounts MIGHT
          be. This is only <Text style={styles.BoldFont}>ONE</Text> simulation. It is the exact
          middle simulation meaning half of the simulations had your values above these numbers and
          half had them below. It is a valuable benchmark to see to what extent you are tracking
          either above or below the middle results. Every time you rerun the plan; the numbers will
          change, based on the order of the returns. You should however see patterns emerge. Most
          importantly, you want to see your individual accounts performing in alignment with the
          benchmarks of that allocation. If over an extended period (one year or more) that is{' '}
          <Text style={styles.BoldFont}>NOT THE CASE</Text>, you may want to consider changing this
          investment approach, as you potentially may not reach the stated Desired Outcomes in your
          plan.
        </Text>
        <Text style={{ color: COLORS.PRIMARY, fontWeight: 'semibold', marginBottom: 20 }}>
          Legacy Value
        </Text>
        <View style={styles.Row}>
          {cols &&
            cols.map((col, index) => (
              <View key={`${2021 + index}`} style={styles.Col}>
                <View
                  style={{ height: 26, borderBottomWidth: 2, borderBottomColor: COLORS.GRAY1 }}
                  fixed
                >
                  <Text
                    style={{
                      color: COLORS.PRIMARY,
                      textTransform: 'uppercase',
                      fontWeight: 'semibold'
                    }}
                  >
                    {col.title}
                  </Text>
                </View>
                <View>
                  {data.legacyValue.map((legacyValue, index) => (
                    <View
                      key={`legacyvalue-${index}`}
                      style={{
                        height: 16,
                        borderBottomWidth: 2,
                        borderBottomColor: COLORS.GRAY1,
                        paddingTop: 4
                      }}
                    >
                      <Text>{col.val(legacyValue)}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportLegacyValues1;
