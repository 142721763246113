import React from 'react';
import { Box } from '@qwealth/qcore';
import {
  getMaximumAnnualWithdrawal,
  getOptimalGuaranteedIncomeAge
} from '../../../data/actions/qroute';
import Projection from './Projection';

const projectionMap = {
  GUARANTEED_INCOME_PROJECTION: {
    title: 'Optimal Guaranteed Retirement Starting Age',
    description:
      'Run 8 QScore simulations for guaranteed retirement income with starting age ' +
      'ranging from 62 to 70 and increasing CPP/OAS amounts annually.',
    calcFn: getOptimalGuaranteedIncomeAge
  },
  MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION: {
    title: 'Maximum Annual Withdrawal Percentage',
    description:
      'Runs multiple QScore simulations from the current baseline plan until a minimum ' +
      'QScore of 80 is reached while increasing the percent of income withdrawn annually in ' +
      'retirement.',
    calcFn: getMaximumAnnualWithdrawal
  }
};

export default function RouteCalculator({ householdId, reload }) {
  return (
    <Box display="grid">
      {Object.keys(projectionMap).map((p) => (
        <Projection
          key={p}
          projection={projectionMap[p]}
          householdId={householdId}
          reload={reload}
        />
      ))}
    </Box>
  );
}
