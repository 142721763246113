import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadQScore, loadInput } from '@qwealth/qdata';
import map from 'lodash/map';

import { getTimeline } from 'data/actions/timeline';
import { selectPrimary, selectSecondary } from 'data/selectors/householdSelectors';
import { getHouseholdQID } from 'services/axiosService';

// Types
import type { IState } from 'data/store';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { IPerson } from '@qwealth/qdata';
import type {
  AggregatedQScoreData,
  QScoreLegacyValueData,
  IWorkshopDTO
} from '../interfaces/AggregatedQScoreData';
import { ArchivedInput } from 'data/models/ArchivedInput';
import { normalizeWorkshopDTO } from '../../../Timeline/helper';

type QScore = {
  qScore: number;
  calcDate: string;
  type: 'BASELINE' | 'SIMULATION' | 'GUARANTEED_INCOME_PROJECTION';
};

// TODO:
type IStateToFix = IState & {
  timeline: Array<QScore>;
  qscore: {
    value: number;
    simulationIds: string[];
    simulationReportEntries: Array<QScoreLegacyValueData>;
    tenthPercentileProjection: Array<{
      endingBalance: number;
    }>;
    ninetyPercentileProjection: Array<{
      year: string;
      endingBalance: number;
    }>;
  };
};

const typeMap = {
  Baseline: ['BASELINE'],
  Simulation: ['BASELINE', 'SIMULATION'],
  Advisor: ['BASELINE', 'GUARANTEED_INCOME_PROJECTION', 'MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION']
};

const mapToZeroFilter = (type: string) => (item: QScore): QScore => ({
  ...item,
  qScore: item.type === type ? item.qScore : 0
});

export const useAggregateQScoreData = (): AggregatedQScoreData | null => {
  // @ts-ignore
  const household = useSelector((state) => state.household);
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useDispatch<ThunkDispatch<IStateToFix, any, AnyAction>>();
  const [qScoreTimeline, setQScoreTimeline] = useState([]);
  const [qRouteTimeline, setQRouteTimeline] = useState([]);
  const [qScoreComparison, setQScoreComparison] = useState([]);
  const [qRoutesAdvisorTimeline, setQRoutesAdvisorTimeline] = useState([]);
  const [workshopDTOs, setWorkshopDTOs] = useState<IWorkshopDTO[]>([]);

  // COMMON
  // *******************
  const householdId = getHouseholdQID();

  const primary = useSelector<IState, IPerson>(selectPrimary);
  const secondary = useSelector<IState, IPerson>(selectSecondary);

  const {
    value,
    simulationReportEntries,
    tenthPercentileProjection,
    ninetyPercentileProjection
  } = useSelector<IStateToFix, IStateToFix['qscore']>((state) => state.qscore);

  const labels = map(ninetyPercentileProjection, 'year');

  useEffect(() => {
    Promise.all([
      dispatch(loadQScore(householdId, [], false)),
      getTimeline(householdId, typeMap.Baseline, null, null, dispatch),
      getTimeline(
        householdId,
        typeMap.Simulation,
        moment().subtract(7, 'days'),
        moment(),
        dispatch
      ),
      getTimeline(householdId, typeMap.Advisor, moment().subtract(7, 'days'), moment(), dispatch)
    ]).then((values) => {
      setQScoreTimeline(values[1]);
      setQRouteTimeline(values[2]);
      setQRoutesAdvisorTimeline(values[3]);

      if (values[1].length > 1) {
        const lastTwoQScores = values[1].slice(-2);
        setQScoreComparison(lastTwoQScores);

        const diffInputId =
          // @ts-ignore
          lastTwoQScores.length > 1 ? lastTwoQScores[1].archivedInputId : undefined;
        // @ts-ignore
        dispatch(loadInput(lastTwoQScores[0].archivedInputId, diffInputId)).then(
          (archivedInput: ArchivedInput) => {
            const { workshopDTOs, diff = {} } = archivedInput;

            const normalizedWorkshopDTOs = normalizeWorkshopDTO(household, diff, workshopDTOs!);

            setWorkshopDTOs(normalizedWorkshopDTOs);
            setReady(true);
          }
        );
      } else {
        setReady(true);
      }
    });
  }, [householdId, dispatch]);

  // QSCORE CHART
  const qScoreTimelineLabels = qScoreTimeline.map(({ calcDate }) => moment(calcDate).format('L'));
  const qScoreTimelineData = qScoreTimeline.map(({ qScore }) => qScore);

  // QSCORE COMPARISON DATA
  const qScoreComparisonLabels = qScoreComparison.map(({ calcDate }) =>
    moment(calcDate).format('L')
  );
  const qScoreComparisonData = qScoreComparison.map(({ qScore }) => qScore);

  // QROUTE CHART
  const qRouteTimelineLabels = qRouteTimeline.map(({ calcDate }) => moment(calcDate).format('L'));
  const qRouteBaselineTimelineData = qRouteTimeline
    .map(mapToZeroFilter('BASELINE'))
    .map(({ qScore }) => qScore);
  const qRouteSimulationTimelineData = qRouteTimeline
    .map(mapToZeroFilter('SIMULATION'))
    .map(({ qScore }) => qScore);

  // ADVISOR QROUTES CHART
  const qRoutesAdvisorBaseline = qRoutesAdvisorTimeline
    .map(mapToZeroFilter('BASELINE'))
    .map(({ qScore }) => qScore);

  const qRoutesAdvisorGuaranteedIncome = qRoutesAdvisorTimeline
    .map(mapToZeroFilter('GUARANTEED_INCOME_PROJECTION'))
    .map(({ qScore }) => qScore);

  const qRoutesAdvisorMaximumWithdrawal = qRoutesAdvisorTimeline
    .map(mapToZeroFilter('MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION'))
    .map(({ qScore }) => qScore);

  // PROJECTION CHART
  // *******************

  const bestBalances = map(ninetyPercentileProjection, 'endingBalance');
  const medianBalances = map(simulationReportEntries, 'endingBalance');
  const worstBalances = map(tenthPercentileProjection, 'endingBalance');

  // LEGACY VALUE CHARTs
  // *******************

  const grossBalances = medianBalances;
  const netTaxBalances = map(simulationReportEntries, 'netTaxEndingBalance');

  if (!ready) return null;

  return {
    qScore: value,
    personPrimary: primary,
    personSecondary: secondary,
    legacyValue: simulationReportEntries,
    chartQScore: {
      labels: qScoreTimelineLabels,
      datasets: [
        {
          label: 'Baseline',
          data: qScoreTimelineData,
          backgroundColor: '#2671b5'
        }
      ]
    },
    chartQScoreComparison: {
      labels: qScoreComparisonLabels,
      datasets: [
        {
          label: 'Baseline',
          data: [...qScoreComparisonData, 0],
          backgroundColor: '#2671b5'
        }
      ],
      workshopDTOs
    },
    chartQRoute: {
      labels: qRouteTimelineLabels,
      datasets: [
        {
          label: 'Baseline',
          data: qRouteBaselineTimelineData,
          backgroundColor: '#2671b5'
        },
        {
          label: 'Simulation',
          data: qRouteSimulationTimelineData,
          backgroundColor: '#79B94E'
        }
      ]
    },
    chartAdvisorQRoutes: {
      labels: qRouteTimelineLabels,
      datasets: [
        {
          label: 'Baseline',
          data: qRoutesAdvisorBaseline,
          backgroundColor: '#2671b5'
        },
        {
          label: 'Optimal Guaranteed Income',
          data: qRoutesAdvisorGuaranteedIncome,
          backgroundColor: '#007bff'
        },
        {
          label: 'Maximum Annual Withdraw',
          data: qRoutesAdvisorMaximumWithdrawal,
          backgroundColor: '#ffc107'
        }
      ]
    },
    chartProjection: {
      labels,
      datasets: [
        {
          label: '90th Percentile',
          data: bestBalances,
          backgroundColor: '#b0e6e5'
        },
        {
          label: 'Median',
          data: medianBalances,
          backgroundColor: '#24c0bf'
        },
        {
          label: '10th Percentile',
          data: worstBalances,
          backgroundColor: '#007bff'
        }
      ]
    },
    chartLegacyValue: {
      labels,
      datasets: [
        {
          label: 'Total Assets (Gross)',
          data: grossBalances,
          backgroundColor: '#2671b5'
        },
        {
          label: 'Total Assets (Net Taxes)',
          data: netTaxBalances,
          backgroundColor: '#007bff'
        }
      ]
    }
  };
};
