import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { toCurrency } from '@qwealth/qcore';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 16,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center'
  },
  SubText1: {
    fontSize: 10,
    paddingHorizontal: 12
  },
  MainText2: {
    fontSize: 28,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center'
  },
  SubText2: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center'
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 30,
    width: '100%',
    marginVertical: 20
  },
  BannerImage: {
    // height: 220,
    width: '45%'
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center'
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
    height: 35
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 35,
    color: COLORS.WHITE
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 35,
    paddingHorizontal: 20,
    fontSize: 12
  },
  BoldFont: {
    fontWeight: 'bold'
  },
  TableRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8
  },
  TableHeader: {
    fontSize: 10,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    padding: 5
  }
});

const scenario1 = [
  { percent: 1.7, amount: 967000 },
  { percent: -12.2, amount: 799026 },
  { percent: -12.8, amount: 646750.67 },
  { percent: 19.3, amount: 721573.55 },
  { percent: 13.9, amount: 771872.28 },
  { percent: 22.9, amount: 898631.03 },
  { percent: 17.4, amount: 1004992.83 },
  { percent: 7.8, amount: 1033382.27 },
  { percent: -35.3, amount: 616531.56 },
  { percent: 30.7, amount: 755806.75 },
  { percent: 14.4, amount: 814642.92 },
  { percent: -11.1, amount: 674217.56 },
  { percent: 4, amount: 651186.26 },
  { percent: 9.6, amount: 663700.14 },
  { percent: 7.4, amount: 662813.95 },
  { percent: -11.1, amount: 539241.6 },
  { percent: 17.5, amount: 583608.88 },
  { percent: 6, amount: 568625.42 },
  { percent: 11.6, amount: 584585.97 },
  { percent: 22.9, amount: 668456.15 }
];

const scenario2 = [
  { percent: 22.9, amount: 1179000 },
  { percent: 11.6, amount: 1265764 },
  { percent: 6, amount: 1291709.84 },
  { percent: 17.5, amount: 1467759.06 },
  { percent: -11.1, amount: 1254837.81 },
  { percent: 7.4, amount: 1297695.8 },
  { percent: 9.6, amount: 1372274.6 },
  { percent: 4, amount: 1377165.58 },
  { percent: -11.1, amount: 1174300.21 },
  { percent: 14.4, amount: 1293399.43 },
  { percent: 30.7, amount: 1640473.06 },
  { percent: -35.5, amount: 1008105.12 },
  { percent: 7.8, amount: 1036737.32 },
  { percent: 17.4, amount: 1167129.62 },
  { percent: 22.9, amount: 1384402.3 },
  { percent: 13.9, amount: 1526834.22 },
  { percent: 19.3, amount: 1771513.23 },
  { percent: -12.8, amount: 1494759.53 },
  { percent: -12.2, amount: 1262398.87 },
  { percent: 1.7, amount: 1233859.65 }
];

const scenario3 = [
  { percent: -11.1, amount: 839000 },
  { percent: 14.4, amount: 909816 },
  { percent: 30.7, amount: 1139129.51 },
  { percent: -35.5, amount: 684738.54 },
  { percent: 7.8, amount: 688148.14 },
  { percent: 17.4, amount: 757885.92 },
  { percent: 22.9, amount: 881441.79 },
  { percent: 13.9, amount: 953962.2 },
  { percent: 19.3, amount: 1088076.91 },
  { percent: -12.8, amount: 898803.06 },
  { percent: -12.2, amount: 739149.09 },
  { percent: 1.7, amount: 701714.62 },
  { percent: 22.9, amount: 812407.27 },
  { percent: 11.6, amount: 856646.52 },
  { percent: 6, amount: 858145.31 },
  { percent: 17.5, amount: 958203.24 },
  { percent: -11.1, amount: 802842.68 },
  { percent: 7.4, amount: 811179.03 },
  { percent: 9.6, amount: 839052.22 },
  { percent: 4, amount: 822614.31 }
];

const scenario4 = [
  { percent: 7.8, amount: 1028000 },
  { percent: 17.4, amount: 1156872 },
  { percent: 22.9, amount: 1371795.69 },
  { percent: 13.9, amount: 1512475.29 },
  { percent: 19.3, amount: 1754383.02 },
  { percent: -12.8, amount: 1479821.99 },
  { percent: -12.2, amount: 1249283.71 },
  { percent: 1.7, amount: 1220521.53 },
  { percent: 22.9, amount: 1450020.96 },
  { percent: 11.6, amount: 1568223.4 },
  { percent: 6, amount: 1612316.8 },
  { percent: 17.5, amount: 1844472.24 },
  { percent: -11.1, amount: 1589735.82 },
  { percent: 7.4, amount: 1657376.27 },
  { percent: 9.6, amount: 1766484.39 },
  { percent: 4, amount: 1787143.77 },
  { percent: -11.1, amount: 1538770.81 },
  { percent: 14.4, amount: 1710353.81 },
  { percent: 30.7, amount: 2185432.43 },
  { percent: -35.5, amount: 1359603.92 }
];

const ReportSample1 = () => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>WHAT IS MEANT BY SEQUENCE OF RETURNS?</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View style={{ paddingHorizontal: 30, paddingTop: 30 }}>
      <Text style={styles.SubText1}>
        One can clearly see how dramatically the end results can change simply by the order in which
        the returns occur. But now let’s look at the effect of changing one of your assumptions.
        Let’s reduce the amount that we are withdrawing from your account each year from
        $70,000/year to $50,000/year.
      </Text>

      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 20, paddingLeft: 20 }}>
        <View style={{ marginTop: 31, width: 30 }}>
          {scenario1.map((data, index) => (
            <View
              key={`year-${index + 1}`}
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Text
                style={{
                  backgroundColor: index % 2 === 0 ? COLORS.PRIMARY : COLORS.PRIMARY_LIGHT,
                  color: COLORS.WHITE,
                  fontSize: 8,
                  paddingHorizontal: 5,
                  paddingVertical: 3,
                  width: '100%',
                  borderBottomColor: index % 2 === 0 ? COLORS.PRIMARY : COLORS.PRIMARY_LIGHT,
                  borderBottomWidth: 1
                }}
              >
                {2000 + index}
              </Text>
            </View>
          ))}
        </View>
        <View style={{ marginRight: 10, flex: 1 }}>
          <View>
            <View style={styles.TableHeader}>
              <Text>Scenario 1 - Actual Returns TSX</Text>
              <View>
                <View style={styles.TableRow}>
                  <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                  <Text style={{ width: '10%' }}>$</Text>
                  <Text style={{ width: '30%' }}>50,000.00</Text>
                </View>
              </View>
            </View>
            <View>
              {scenario1.map((data, index) => (
                <View
                  key={`year-${index + 1}`}
                  style={[
                    styles.TableRow,
                    {
                      flex: 1,
                      fontSize: 8,
                      backgroundColor: COLORS.GRAY1,
                      paddingHorizontal: 5,
                      paddingVertical: 3,
                      borderBottomWidth: 1,
                      borderBottomColor: '#cccccc'
                    }
                  ]}
                >
                  <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                  <Text style={{ width: '10%' }}>$</Text>
                  <Text
                    style={{
                      width: '30%'
                    }}
                  >
                    {toCurrency(data.amount)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>
        <View style={{ marginRight: 10, flex: 1 }}>
          <View style={[styles.TableHeader, { backgroundColor: COLORS.PRIMARY }]}>
            <Text>Scenario 2</Text>
            <View>
              <View style={styles.TableRow}>
                <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>50,000.00</Text>
              </View>
            </View>
          </View>
          <View>
            {scenario2.map((data, index) => (
              <View
                key={`year-${index + 1}`}
                style={[
                  styles.TableRow,
                  {
                    flex: 1,
                    fontSize: 8,
                    backgroundColor: COLORS.GRAY1,
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderBottomWidth: 1,
                    borderBottomColor: '#cccccc'
                  }
                ]}
              >
                <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>{toCurrency(data.amount)}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ marginRight: 10, flex: 1 }}>
          <View style={styles.TableHeader}>
            <Text>Scenario 3</Text>
            <View>
              <View style={styles.TableRow}>
                <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>50,000.00</Text>
              </View>
            </View>
          </View>
          <View>
            {scenario3.map((data, index) => (
              <View
                key={`year-${index + 1}`}
                style={[
                  styles.TableRow,
                  {
                    flex: 1,
                    fontSize: 8,
                    backgroundColor: COLORS.GRAY1,
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderBottomWidth: 1,
                    borderBottomColor: '#cccccc'
                  }
                ]}
              >
                <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>{toCurrency(data.amount)}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ marginRight: 20, flex: 1 }}>
          <View style={[styles.TableHeader, { backgroundColor: COLORS.PRIMARY }]}>
            <Text>Scenario 4</Text>
            <View>
              <View style={styles.TableRow}>
                <Text style={{ width: '60%' }}>Annual Withdrawal</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>50,000.00</Text>
              </View>
            </View>
          </View>
          <View>
            {scenario4.map((data, index) => (
              <View
                key={`year-${index + 1}`}
                style={[
                  styles.TableRow,
                  {
                    flex: 1,
                    fontSize: 8,
                    backgroundColor: COLORS.GRAY1,
                    paddingHorizontal: 5,
                    paddingVertical: 3,
                    borderBottomWidth: 1,
                    borderBottomColor: '#cccccc'
                  }
                ]}
              >
                <Text style={{ width: '60%' }}>{`${data.percent.toFixed(2)}%`}</Text>
                <Text style={{ width: '10%' }}>$</Text>
                <Text style={{ width: '30%' }}>{toCurrency(data.amount)}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  </Page>
);

export default ReportSample1;
