import _ from 'lodash';
import { getLegacyBalance, parsePercent, toCurrency } from '@qwealth/qcore';
import {
  IDataPointWorkshopDto,
  IGoalWorkshopDto,
  IPerson,
  ISimulationWorkshopDto,
  IWorkshopItemDto,
  IWorkshopDto
} from '@qwealth/qdata';
import { OwnerWorkshopItem } from 'data/models/OwnerWorkshopItem';
import { typeComparator, diffComparator } from 'utils/DataUtil';
import { getPerson } from 'utils/HouseholdUtil';

const frequencyTypes = [
  'Pre-retirement: Spending',
  'Post-retirement: Inflow',
  'Post-retirement: Outflow',
  'Beneficiary: Other'
];

const incomeTypes = ['pension', 'otherIncome', 'rentalPropertyIncome', 'trustIncome', 'CPP', 'OAS'];

export const getWorkshopType = (workshopItem: IWorkshopItemDto) => {
  let type = '';
  if (workshopItem.hasOwnProperty('goalType')) {
    type = (workshopItem as IGoalWorkshopDto).goalType;
  } else if (workshopItem.hasOwnProperty('datapointType')) {
    type = (workshopItem as IDataPointWorkshopDto).datapointType;
  } else if (workshopItem.hasOwnProperty('simulationType')) {
    type = (workshopItem as ISimulationWorkshopDto).simulationType;
  }
  return type;
};

export const getDisplayValue = (workshopItem: OwnerWorkshopItem) => {
  const type = getWorkshopType(workshopItem);
  let currencyValue;

  if (type === 'Retirement: Age') {
    return [(workshopItem as IGoalWorkshopDto).retirementAgeValue];
  }

  if (frequencyTypes.includes(type)) {
    const { amount, frequency, startAge, endAge } = workshopItem as IGoalWorkshopDto;
    if (frequency === 'One Time Event') {
      return [`${toCurrency(amount, true)} at ${startAge}`];
    }
    return [`${toCurrency(amount, true)} from ${startAge} to ${endAge}`];
  }

  if (type === 'Guaranteed Retirement Income') {
    return incomeTypes
      .map((incomeType) => {
        const gri = (workshopItem as IDataPointWorkshopDto).guaranteedRetirementIncome?.find(
          ({ griType }) => griType === incomeType
        );
        if (!gri || !gri.amount) {
          return '';
        }
        const { amount, startAge, endAge, griType } = gri;
        return `${_.startCase(incomeType)} ${toCurrency(
          amount,
          true
        )} from ${startAge} to ${endAge}`;
      })
      .filter((value) => value !== '');
  }

  if (type === 'Current Spending') {
    currencyValue = (workshopItem as IDataPointWorkshopDto).currentSpendingValue;
  }

  if (type === 'Retirement: Spending') {
    const { currentSpendingValue = 0, retirementSpendingPercentage } = workshopItem;
    let totalSpending = parsePercent(retirementSpendingPercentage) * currentSpendingValue;
    return [
      `${retirementSpendingPercentage}% of Current Spending: ${toCurrency(totalSpending, true)}`
    ];
  }

  if (type === 'Legacy: End Value') {
    currencyValue = getLegacyBalance(workshopItem);
  }

  if (!type) {
    console.log({ workshopItem });
  }

  return [currencyValue ? toCurrency(currencyValue, true) : 'N/A'];
};

export const normalizeWorkshopDTO = (
  household: {
    initialized: boolean;
    members: Array<IPerson>;
  },
  diff: Record<number, Array<string>>,
  workshopDTOs: IWorkshopDto[]
) => {
  const diffIds = Object.keys(diff).map((id) => parseInt(id, 10));

  return workshopDTOs
    .map((dto) => {
      const { attendeeQID } = dto;
      return [
        ...dto.workshopDataPoint.map((d) => ({ ...d, attendeeQID })),
        ...dto.workshopGoal.map((g) => ({ ...g, attendeeQID })),
        ...dto.workshopSimulation.map((s) => ({ ...s, attendeeQID }))
      ];
    })
    .flat()
    .sort(typeComparator)
    .sort(diffComparator(diffIds))
    .map((workshopItem) => {
      const { attendeeQID, id } = workshopItem;
      const owner = getPerson(household, attendeeQID) as IPerson;
      const hasChanged = diffIds.includes(id);
      let diffResult = diff[id];
      if (diffResult) {
        diffResult = diffResult.filter((r) => !!r);
      }

      return {
        id,
        owner,
        workshopType: getWorkshopType(workshopItem),
        values:
          diffResult && !_.isEmpty(diffResult)
            ? diffResult
            : // @ts-ignore
              getDisplayValue(workshopItem),
        hasChanged
      };
    });
};
