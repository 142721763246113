import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Card, Box, Table } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';
import { hasColumnData } from 'utils/DataUtil';
import QScore from '../../QScore';
import buildUrl from '../helper';
import OptionalColumnSelector from '../OptionalColumnSelector';
import { selectPrimary, selectSecondary } from '../../../data/selectors/householdSelectors';

const buildColumns = (primary, secondary, data, visibleColumns, push) => {
  return [
    {
      dataField: 'uuid',
      text: 'uuid',
      hidden: true
    },
    {
      dataField: 'year',
      text: 'Year',
      classes: 'text_bold'
    },
    {
      dataField: 'age',
      text: `${primary.firstName}'s Age`,
      hidden: !visibleColumns.includes('age')
    },
    {
      dataField: 'secondaryAge',
      text: `${secondary.firstName}'s Age`,
      hidden: !visibleColumns.includes('secondaryAge')
    },
    {
      dataField: 'endingBalance',
      text: 'Total Retirement Assets',
      highlightable: true,
      headerClasses: 'linked-col',
      formatter: (cell, row) => {
        const totalAssets = cell - (row.endingInsuranceBalance || 0);
        return toCurrency(totalAssets, true);
      },
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/simGraph',
            search: buildUrl()
          });
        }
      }
    },
    {
      dataField: 'combinedNetEarnedIncome',
      text: 'Combined Earned Income*†',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'totalSpending',
      text: 'Total Spending†',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'netGuaranteedRetirementIncome',
      text: 'Guaranteed Income*†',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'incomeSpendDifference',
      text: 'Surplus/Deficit',
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'netRMD',
      text: `${primary.firstName}'s RMD*`,
      hidden: !visibleColumns.includes('netRMD'),
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'secondaryNetRMD',
      text: `${secondary.firstName}'s RMD*`,
      hidden: !visibleColumns.includes('secondaryNetRMD'),
      formatter: (cell) => toCurrency(cell, true)
    },
    {
      dataField: 'combinedNetRMD',
      text: 'Combined RMD*',
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('combinedNetRMD', data)
    },
    {
      dataField: 'registeredOutflows',
      text: `${primary.firstName}'s Registered Withdrawal`,
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('registeredOutflows', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({
              type: 'registeredAccountType',
              ownerId: primary && primary.QID
            })
          });
        }
      }
    },
    {
      dataField: 'secondaryRegisteredOutflows',
      text: `${secondary.firstName}'s Registered Withdrawal`,
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('secondaryRegisteredOutflows', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({
              type: 'registeredAccountType',
              ownerId: secondary && secondary.QID
            })
          });
        }
      }
    },
    {
      dataField: 'nonRegisteredOutflows',
      text: 'Non-Registered Withdrawal',
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('nonRegisteredOutflows', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'nonRegisteredAccountType' })
          });
        }
      }
    },
    {
      dataField: 'taxFreeOutflows',
      text: 'Tax-Free Withdrawal',
      formatter: (cell) => toCurrency(cell, true),
      hidden: !hasColumnData('taxFreeOutflows', data),
      highlightable: true,
      headerClasses: 'linked-col',
      headerEvents: {
        onClick: (e) => {
          e.preventDefault();
          push({
            pathname: '/chartOfAccounts',
            search: buildUrl({ type: 'taxFreeAccountType' })
          });
        }
      }
    }
  ];
};

const buildOptionalColumnMap = (primary, secondary) => {
  const map = {};
  if (primary) {
    map.age = `${primary.firstName}'s Age`;
    map.netRMD = `${primary.firstName}'s RMD`;
  }
  if (secondary) {
    map.secondaryAge = `${secondary.firstName}'s Age`;
    map.secondaryNetRMD = `${secondary.firstName}'s RMD`;
  }

  return map;
};

export default function DistributionReport() {
  const data = useSelector((state) => state.qscore.simulationReportEntries);
  const primary = useSelector(selectPrimary);
  const secondary = useSelector(selectSecondary);

  const [visibleColumns, setVisibleColumns] = useState([]);
  const { push } = useHistory();
  const columns = useMemo(() => buildColumns(primary, secondary, data, visibleColumns, push), [
    primary,
    secondary,
    data,
    visibleColumns,
    push
  ]);
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        phone: '1fr',
        desktop: '1fr 350px'
      }}
      rowGap="default"
    >
      <Box
        order={{
          phone: '2',
          desktop: '1'
        }}
      >
        <Card title="Master Distribution Report" shadow>
          <Form>
            <p>* = NET of federal/provincial taxes</p>
            <p>&dagger; = Adjusted for inflation by 2.1% per year</p>
            <OptionalColumnSelector
              optionalColumnMap={buildOptionalColumnMap(primary, secondary)}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
            />
          </Form>
          <Table fixedHeader tableHeight="700px" columns={columns} data={data} />
        </Card>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        order={{
          phone: '1',
          desktop: '2'
        }}
      >
        <QScore />
      </Box>
    </Box>
  );
}
