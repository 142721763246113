import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import {
  AppLayout,
  AppPage,
  getAccessAndIdToken,
  Header,
  Loader,
  Tab,
  TopBar,
  useAcquireToken,
} from '@qwealth/qcore';
import { QContext } from '@qwealth/qdata';

import Notifications from './components/Notifications';
import configureAxios, { getHouseholdQID } from './services/axiosService';
import { loadHousehold } from './data/actions/household';
import Home from './components/Home';
import BaselinePlan from './components/BaselinePlan';
import Overrides from './components/Overrides';
import ChartOfAccount from './components/Reports/ChartOfAccounts';
import DistributionReport from './components/Reports/DistributionReport';
import MasterSimReport from './components/Reports/MasterSimReport';
import ProjectionReport from './components/Reports/ProjectionReport';
import SimGraph from './components/Reports/SimGraph';
import Timeline from './components/Reports/Timeline';
import { ReportSummary } from './components/Reports/PdfReport/Summary';
import { selectPrimary } from './data/selectors/householdSelectors';

function App() {
  const { idToken, isInProgress } = useAcquireToken();
  const [isAxiosInitialized, setAxiosInitialized] = useState(false);

  const householdQID = getHouseholdQID();
  const dispatch = useDispatch();
  const isHome = useRouteMatch({ path: '/', exact: true });

  useEffect(() => {
    if (idToken) {
      QContext.setIdToken(idToken);
      configureAxios(idToken)
        .then(() => {
          setAxiosInitialized(true);
        })
        .then(() => dispatch(loadHousehold()));

      // refresh the token every 20 minutes
      setInterval(() => {
        getAccessAndIdToken({ forceRefresh: true }).then(auth => {
          if (auth) {
            QContext.setIdToken(auth.idToken);
            configureAxios(auth.idToken);
          }
        });
      }, 20 * 60 * 1000);
    }
  }, [idToken, dispatch]);

  const primary = useSelector(selectPrimary);

  const {
    params: { path }
  } = useRouteMatch('/:path') || { params: {} };
  const { search } = useLocation();
  const { push } = useHistory();
  const selectHandler = useCallback(
    (key) => {
      if (key) {
        push(`/${key}${search}`);
      }
    },
    [push, search]
  );

  if (isInProgress || !isAxiosInitialized) {
    return (
      <AppLayout alignItems="center">
        <Loader />
      </AppLayout>
    );
  }

  const qcentralUrl = process.env.REACT_APP_QWEALTH_QC;

  return (
    <>
      <AuthenticatedTemplate>
        <TopBar
          title="QScore"
          imageSrc="/assets/QScore.png"
          backToQCentralHref={`${qcentralUrl}/household?household=${householdQID}`}
        />
        <AppPage maxWidth={isHome ? '75%' : '90%'}>
          {!isHome && (
            <>
              <AppPage.Header
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                bg="blue"
              >
                <Header.Title>{`${primary && primary.lastName} Family`}</Header.Title>
              </AppPage.Header>

              <AppPage.Nav mb="large" onSelect={selectHandler} selectedTab={path}>
                <Tab id="baselinePlan">Baseline Plan</Tab>
                <Tab id="timeline">Timeline</Tab>
                <Tab id="clientQRoutes">Client QRoutes</Tab>
                <Tab id="advisorQRoutes">Advisor QRoutes</Tab>
                <Tab id="overrides">Overrides</Tab>
                <Tab id="report">Report</Tab>
              </AppPage.Nav>
            </>
          )}
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route component={Home} path="/" exact />
              <Route component={BaselinePlan} path="/baselinePlan" />
              <Route component={Overrides} path="/overrides" />
              <Route
                render={() => <Timeline type="Baseline" title="Historical QScores" />}
                path="/timeline"
              />
              <Route component={MasterSimReport} path="/masterSimReport" />
              <Route component={ProjectionReport} path="/projectionReport" />
              <Route component={SimGraph} path="/simGraph" />
              <Route component={ChartOfAccount} path="/chartOfAccounts" />
              <Route component={DistributionReport} path="/distributionReport" />
              <Route
                render={() => <Timeline type="Simulation" title="Client QRoutes" />}
                path="/clientQRoutes"
              />
              <Route
                render={() => <Timeline type="Advisor" title="Advisor QRoutes" />}
                path="/advisorQRoutes"
              />
              <Route component={ReportSummary} path="/report" />
            </Switch>
            <Notifications />
          </Suspense>
        </AppPage>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Home />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
