import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';

const green = '#79B94E';
const red = '#CC433C';
const yellow = '#F2994A';

const Meter = styled.div`
  // visibility: hidden;
  opacity: 0;

   {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    opacity: 0;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div {
        height: 0.35rem;
        border-radius: 10px;
        width: 100%;
      }
    }

    > div:nth-child(2) {
      margin: 0 0.5rem;
    }

    label {
      padding-top: 0.5rem;
      font-size: 0.75rem;
      color: #84878f;
      font-weight: 700;
    }

    .hide {
      display: none;
    }

    .danger {
      color: ${red};

      input[type='range'] {
        background: ${red};
      }

      input[type='range']::-webkit-slider-thumb {
        border: 3px solid ${red};
      }

      > div {
        background-color: ${red};
      }
    }

    .perfect {
      color: ${green};

      input[type='range'] {
        background: ${green};
      }

      input[type='range']::-webkit-slider-thumb {
        border: 3px solid ${green};
      }

      > div {
        background-color: ${green};
      }
    }

    .warning {
      color: ${yellow};

      input[type='range'] {
        background: ${yellow};
      }

      input[type='range']::-webkit-slider-thumb {
        border: 3px solid ${yellow};
      }

      > div {
        background-color: ${yellow};
      }
    }

    input[type='range']::-webkit-slider-thumb {
      cursor: cursor;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 10px;
      background: #fff;
    }

    input[type='range'] {
      -webkit-appearance: none;
      appearance: none;
      outline: none;
      height: 0.35rem;
      border-radius: 10px;
      width: 100%;
    }
  }
`;

export default function QScoreMeter(props) {
  const { value } = props;
  return (
    <div>
      <Meter className="score-meter">
        <div className="danger">
          <input
            type="range"
            min="1"
            max="74"
            value={value}
            disabled
            className={value > 0 && value < 75 ? 'show' : 'hide'}
          />
          <div className={value > 0 && value < 75 ? 'hide' : 'show'} />
          <label>&lt;75</label>
        </div>

        <div className="perfect">
          <input
            type="range"
            min="75"
            max="90"
            value={value}
            disabled
            className={value >= 75 && value <= 90 ? 'show' : 'hide'}
          />
          <div className={value >= 75 && value <= 90 ? 'hide' : 'show'} />
          <label>75-90</label>
        </div>

        <div className="warning">
          <input
            type="range"
            min="91"
            max="100"
            value={value}
            disabled
            className={value > 90 ? 'show' : 'hide'}
          />
          <div className={value > 90 ? 'hide' : 'show'} />
          <label>&gt;90</label>
        </div>
      </Meter>
    </div>
  );
}

QScoreMeter.propTypes = {
  value: PropTypes.number.isRequired
};
