import React, { useState } from 'react';
import { min, isEmpty } from 'lodash';
import { Box } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore/build/es/utils/number';
import { calculateGuaranteedRetirementIncome } from '@qwealth/qcore/build/es/utils/WorkshopUtil';
import EditButton from '../../../Common/EditButton';
import Editor from './Editor';
import { getAge } from '../../../../utils/HouseholdUtil';
import InputLabel from '../../../Common/InputLabel';
import InputValue from '../../../Common/InputValue';
import OwnerLabel from '../../../Common/OwnerLabel';
import BorderedSection from '../../../Common/BorderedSection';
import { IDataPointWorkshopDto, IPerson } from '@qwealth/qdata';
import { OwnerDetails } from '../../../../data/models/OwnerDetails';

interface Props {
  primary: IPerson;
  secondary: IPerson;
  primaryGuaranteedIncomes?: IDataPointWorkshopDto;
  secondaryGuaranteedIncomes?: IDataPointWorkshopDto;
}

const GuaranteedRetirementIncome: React.FC<Props> = ({
  primary,
  secondary,
  primaryGuaranteedIncomes,
  secondaryGuaranteedIncomes
}) => {
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [incomeDataPoint, setIncomeDataPoint] = useState<IDataPointWorkshopDto | undefined>();
  const [owner, setOwner] = useState<OwnerDetails | undefined>();

  let ages = [getAge(primary.dateOfBirth)];
  if (secondary.dateOfBirth) {
    ages = ages.concat([getAge(secondary.dateOfBirth)]);
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        phone: '1fr',
        desktop: '1fr 1fr'
      }}
      gap="default"
    >
      <BorderedSection>
        <InputLabel>
          <Box as="span" mr="small">
            Guaranteed Retirement Income -
          </Box>
          <OwnerLabel type="primary">{primary.firstName}</OwnerLabel>
        </InputLabel>
        <Box display="flex" alignItems="center">
          <InputValue>
            {toCurrency(calculateGuaranteedRetirementIncome(primaryGuaranteedIncomes), true)}
          </InputValue>
          <EditButton
            onClickHandler={() => {
              setEditorOpen(true);
              setIncomeDataPoint(primaryGuaranteedIncomes);
              setOwner({ name: primary.firstName, type: 'Primary', id: primary.QID });
            }}
          />
        </Box>
      </BorderedSection>
      {!isEmpty(secondary) && (
        <BorderedSection>
          <InputLabel>
            <Box as="span" mr="small">
              Guaranteed Retirement Income -
            </Box>
            <OwnerLabel type="secondary">{secondary.firstName}</OwnerLabel>
          </InputLabel>
          <Box display="flex" alignItems="center">
            <InputValue>
              {toCurrency(calculateGuaranteedRetirementIncome(secondaryGuaranteedIncomes), true)}
            </InputValue>
            <EditButton
              onClickHandler={() => {
                setEditorOpen(true);
                setIncomeDataPoint(secondaryGuaranteedIncomes);
                setOwner({ name: secondary.firstName, type: 'Secondary', id: secondary.QID });
              }}
            />
          </Box>
        </BorderedSection>
      )}
      <Editor
        isEditorOpen={isEditorOpen}
        setEditorOpen={setEditorOpen}
        startAge={min(ages) || 65}
        owner={owner}
        incomeDataPoint={incomeDataPoint}
      />
    </Box>
  );
};

export default GuaranteedRetirementIncome;
