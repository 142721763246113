import _ from 'lodash';
import moment from 'moment';

const typeToLabelMap = {
  BASELINE: 'Baseline',
  SIMULATION: 'Simulation',
  GUARANTEED_INCOME_PROJECTION: 'Optimal Guaranteed Income',
  MAXIMUM_ANNUAL_WITHDRAWAL_PROJECTION: 'Maximum Annual Withdraw'
};

export const buildOptions = (setVisible, chartRef, dataList) => ({
  legend: {
    position: 'bottom',
    align: 'start',
    labels: { boxWidth: 10 },
    onClick: (e, legendItem) => {
      const type = _.findKey(typeToLabelMap, (o) => o === legendItem.text);
      if (!type) {
        return;
      }

      setVisible((prevVisible) => {
        if (prevVisible.includes(type)) {
          return prevVisible.filter((t) => t !== type);
        }
        return [...prevVisible, type];
      });

      const index = legendItem.datasetIndex;
      const { chartInstance } = chartRef.current;
      const meta = chartInstance.getDatasetMeta(index);

      meta.hidden = meta.hidden === null ? !chartInstance.data.datasets[index].hidden : null;
      chartInstance.update();
    }
  },
  tooltips: {
    titleFontSize: 18,
    bodyFontSize: 18,
    callbacks: {
      label({ yLabel }) {
        return `QScore: ${yLabel}`;
      },
      afterBody([{ datasetIndex, index }]) {
        const datapoint = dataList[datasetIndex][index];

        return datapoint.name;
      },
      title([{ xLabel }]) {
        return new Date(xLabel).toLocaleDateString();
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          callback(value) {
            return new Date(value).toLocaleDateString();
          }
        }
      }
    ]
  }
});

export const getStartDate = (range) => {
  let startDate = null;
  if (range === 'last_30_days') {
    startDate = moment().subtract(30, 'days');
  }
  if (range === 'YTD') {
    startDate = moment().startOf('year');
  }
  if (range === 'last_3_years') {
    startDate = moment().subtract(3, 'years');
  }
  if (range === 'last_5_years') {
    startDate = moment().subtract(5, 'years');
  }
  return startDate;
};
